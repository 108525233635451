<template>
  <div class="page-content">
    <!-- header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link :to="{
                name: 'ActiveTestViewCandidatesNew', params: {
                  clientId: encode(this.clientId),
                  isParent: encode(isParent), //update
                },
                query: {
                  search: encode(this.routesearch),
                  offset: encode(this.routeoffset),
                  current: encode(this.routecurrent),
                },
              }" class="p-0 Group_top_three_dot_dropdown-active btn-back-arrow-kj" type="button"
                style="line-height: 2.6">
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-2 fw-bolder">Report</h4>
          <h6 class="text-muted">

            {{ testName }} <i class="fa-solid fa-angle-right mx-2"></i>
            {{ this.name }}
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="top_heading_CreatePartner_btn d-block mx-3" data-bs-toggle="modal"
          data-bs-target="#ConsolidatedSheet">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <!-- <button class="btn p-0 Group_top_three_dot_dropdown" type="button" @click="savePdf()">
                <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
              </button> -->

              <a :href="reportUrl" target="_blank">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </a>

              <!-- <router-link :to="{
                name: 'ActiveTestReportLanguagePDF',
                query: {
                  candidateId: encode(this.candidateId),
                  testId: encode(this.testId),
                  name: encode(this.name),
                  clientId: encode(this.clientId),
                  isParent: encode(this.isParent),
                  id: encode(this.routesearch),
                  offset: encode(this.routecurrent),
                  current: encode(this.routecurrent)
                },
              }">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </router-link> -->
            </div>
          </div>
        </div>
        <div class="top_heading_CreatePartner_btn d-block">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button" id="dropdownMenuButton"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa-solid fa-ellipsis-vertical fa-lg" style="color: #7c7c7c;"></i>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li>
                  <a :href="compeleteReportUrl" target="_blank">
                    <button type="button" class="btn w-100 text-start">
                      Download Consolidated Report
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row Report_card_section">
      <div class="col-md-12">
        <div class="card active-card">
          <!--Header Component-->
          <div class="card mb-3">

            <ActiveTestHeaderVue :active="this.actived" :candidateId="this.candidateId" :testId="this.testId"
              :isParent="this.isParent" :clientId="this.clientId" :id="this.routesearch" :current="this.routecurrent"
              :offset="this.routeoffset" />
          </div>
          <!--Header Component-->
          <div class="card">
            <div class="Report_Responses_second_nav">
              <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" v-for="item in this.tab" :key="item">
                  <button class="nav-link px-3 mx-1" :class="{ active: this.activeTab.reportId == item.reportId }"
                    type="button" role="tab" aria-controls="eVA" aria-selected="true"
                    :data-bs-target="'#section_' + item.reportId" @click="showTab(item)">
                    {{ item.name }}
                  </button>
                </li>
              </ul> -->
              <div class="tab-content" id="myTabContent">
                <!-- <div v-for="tab in this.tab" :key="tab"> -->
                <div class="tab-pane fade show active" :id="'section_' + this.activeTab.reportId" role="tabpanel"
                  aria-labelledby="eVA-tab">
                  <div v-if="this.activeTab.reportId == 4">
                    <!-- <div class="Report_basic_info_area my-4">
                      <div class="Report_basic_info_heading">
                        <h4>How to Interpret the Report?</h4>
                      </div>
                      <div class="Score_Rank_chart_area">
                        <p class="font-size-17">
                          When interpreting the results, it is important to
                          remember that the scores are not good or bad, only
                          more or less appropriate to certain types of work.
                          Since the results are based on one's own view of
                          behavior, the accuracy of the results depends upon
                          both honesty and self-awareness while taking the test.
                          This assessment measures work-relevant personality
                          traits that might be manifested in work behaviour and
                          therefore influence success on the job. To best use
                          this report:
                        </p>
                        <ul class="mt-3 text-muted font-size-17">
                          <li class="my-2">
                            Review the overall recommendation first. Based on
                            your need, you might want to prioritize candidates
                            who are ‘recommended', followed by those who are
                            ‘cautiously recommended’.
                          </li>
                          <li class="my-2">
                            If you’re choosing among different candidates within
                            the same band of recommendation, review the
                            competencies’ results. Focus on the competencies you
                            believe are critical for success in the role you’re
                            hiring for, and use those scores to help you
                            prioritize which candidates to select for the next
                            step.
                          </li>
                          <li class="my-2">
                            Remember: This assessment is just one piece of the
                            puzzle. While hiring, it is recommended that you
                            review other information as well –functional and job
                            knowledge, background and past behaviour (e.g. using
                            structured behavioural interviews), reference
                            checks, etc. in addition to the personality
                            assessment.
                          </li>
                        </ul>
                      </div>
                    </div> -->

                    <div class="Report_basic_info_area my-1">
                      <div class="Report_basic_info_heading">
                        <h4>
                          LANGUAGE SUMMARY
                          <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25"
                            class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Includes scores of various eva sections like written and spoken communication, comprehension and listening, and pronunciation. " />
                        </h4>
                      </div>
                      <div class="row Report_basic_info_detial_area">
                        <div class="col-xl-3 col-lg-4 col-md-6 col-12 my-3" v-for="item in this.languageSummary"
                          :key="item">
                          <label class="text-muted">{{ item.parameterName }}
                            <img src="../../public/dashboard-assets/images/M7/Actions.png" width="15"
                              class="cursor-pointer ms-1" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                              :title="item.definition" /></label>
                          <h5>
                            {{
                              item.tabsoluteScore != null ||
                                item.tabsoluteScore != ""
                                ? item.tabsoluteScore
                                : "NA"
                            }}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <div class="Overall_Cognitive_section my-4">
                      <div class="Overall_Cognitive_header">
                        eVA <sup>TM</sup> Fitment
                      </div>

                      <div class="Overall_Cognitive_detail mt-4">
                        <div class="Overall_Cognitive_detail_Definition pb-3">
                          <span class="Recommended_heading_text">
                            Definition
                          </span>
                          <p class="Overall_Cognitive_detail_Definition_content">
                            {{
                              this.LanguageReport.definition
                                ? this.LanguageReport.definition
                                : "NA"
                            }}
                          </p>
                        </div>
                        <div class="Overall_Cognitive_detail_Definition">
                          <div class="row">
                            <div class="col-xl-10 col-lg-9 col-md-12 mt-4">
                              <span class="Recommended_heading_text">
                                Score Description
                              </span>
                              <!-- <p class="Overall_Cognitive_detail_Definition_content">
                                {{
                                  this.LanguageReport.comment
                                  ? this.LanguageReport.comment
                                  : "NA"
                                }}
                              </p> -->
                              <div v-if="this.LanguageReport?.comment?.length > 0" class="mt-4">
                                <p class="Overall_Cognitive_detail_Definition_content"
                                  v-html="this.LanguageReport.comment">
                                </p>
                              </div>
                              <div v-else class="mt-4">
                                <p class="Overall_Cognitive_detail_Definition_content">
                                  {{ "NA" }}
                                </p>
                              </div>
                            </div>
                            <div class="col-xl-2 col-lg-3 col-md-12 text-center mt-4">
                              <!-- <img
                              src="../../public/dashboard-assets/images/M7/Overall_Cognitive.png"
                              width="130"
                              class="ms-4"
                              alt=""
                            /> -->
                              <CustomChartVue :score="this.score1" v-if="this.show1" :main="'score'" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="Report_basic_info_area my-4">
                      <div class="Report_basic_info_heading">
                        <h4>
                          eVA<sup class="pe-2">TM</sup> Summary
                          <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25"
                            class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Refers to the combination of all the scores obtained by the candidate on the eva parameters. " />
                        </h4>
                      </div>
                      <div class="Score_Rank_chart_area">
                        <div class="row justify-content-center mx-3">
                          <!-- class:border-bottom -->
                          <template v-for="(param, index) in this.subLanguageReport" :key="index">
                            <div class="col-lg-4 col-md-6 col-12 my-3 text-center"
                              :class="[(index < subLanguageReport.length - 1 && index % 3 != 2) ? 'border-end' : '', (subLanguageReport.length % 3 == 2 && index >= subLanguageReport.length - 2) ? 'col-xl-6' : '']">
                              <div>
                                <label>{{ param.parameterName }}
                                  <img src="../../public/dashboard-assets/images/M7/Actions.png" width="20"
                                    class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                                    :title="param.test" /> </label><br />
                                <div class="m-auto">
                                  <CustomChartVue :score="this.score[index]" v-if="this.show[index]"
                                    :main="param.parameterName" />
                                </div>
                              </div>
                            </div>
                            <hr v-if="(index + 1) % 3 === 0 && index !== subLanguageReport.length - 1" class="my-3" />
                          </template>

                        </div>




                        <!-- <div class="row justify-content-center mx-3">
    <template v-for="(demo, index) in subCoginitiveData"  :key="index">
      <div class="col-xl-4 col-lg-4 col-md-6 col-12 my-3 text-center"
        :class="[(index < subCoginitiveData.length - 1 && index % 3 !== 2) ? 'border-end' : '']">
        <label class="Score_Rank_label_color">{{ demo.competencyName }}
          <img src="../../public/dashboard-assets/images/M7/Actions.png" width="20"
            class="cursor-pointer ms-2" alt="" />
        </label><br />
        <CustomChartVue :score="this.score[index]" v-if="this.show[index]" :main="demo.competencyName" />
      </div>
      <hr v-if="(index + 1) % 3 === 0 && index !== subCoginitiveData.length - 1" class="my-3" />
    </template>
  </div> -->
                      </div>
                    </div>

                    <div class="Report_basic_info_area mt-4">
                      <div class="Report_basic_info_heading">
                        <h4>
                          <span class="text-lowercase">e</span>VA
                          <sup class="me-2">TM</sup>
                          Detailed language report
                          <img src="../../public/dashboard-assets/images/M7/Actions.png" width="25"
                            class="cursor-pointer ms-2" alt="" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Includes a summary of scores for different language skills, such as reading, writing, speaking, and listening." />
                        </h4>
                      </div>
                    </div>

                    <!--subParameter-->
                    <div v-for="(param, idx) in this.subLanguageReport" :key="idx">
                      <div class="Overall_Cognitive_section my-4">
                        <div class="Overall_Cognitive_header">
                          {{ param.parameterName }}
                        </div>

                        <div class="Overall_Cognitive_detail mt-4">
                          <div class="Overall_Cognitive_detail_Definition pb-3">
                            <span class="Recommended_heading_text">
                              Definition
                            </span>
                            <p class="Overall_Cognitive_detail_Definition_content">
                              {{ param.definition ? param.definition : "NA" }}
                            </p>
                          </div>
                          <div class="Overall_Cognitive_detail_Definition">
                            <div class="row">
                              <div class="col-xl-10 col-lg-9 col-md-12 mt-4">
                                <span class="Recommended_heading_text">
                                  Score Description
                                </span>
                                <!-- <p class="Overall_Cognitive_detail_Definition_content">
                                  {{ param.comment ? param.comment : "NA" }}
                                </p> -->
                                <div v-if="param.comment?.length > 0" class="mt-4">
                                  <p class="Overall_Cognitive_detail_Definition_content" v-html="param.comment">
                                  </p>
                                </div>
                                <div v-else class="mt-4">
                                  <p class="Overall_Cognitive_detail_Definition_content">
                                    {{ "NA" }}
                                  </p>
                                </div>
                              </div>
                              <div class="col-xl-2 col-lg-3 col-md-12 text-center mt-4">
                                <!-- <img
                                src="../../public/dashboard-assets/images/M7/Overall_Cognitive.png"
                                width="130"
                                class="ms-4"
                                alt=""
                              /> -->
                                <CustomChartVue :score="this.score[idx]" v-if="this.show[idx]" :main="param.parameterName + param.parameterId
                                  " />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="Report_basic_info_area mb-4" v-if="param.subParameters?.length > 0">
                        <div class="Score_Rank_chart_area p-0 overflow-hidden">
                          <div class="row m-0 align-items-start Self_Score_tabs_section">
                            <div class="col-xl-3 col-lg-3 col-md-12 Self_Score_tabs_left_part nav flex-column nav-pills"
                              id="v-pills-tab" role="tablist" aria-orientation="vertical">
                              <h3 class="text-muted">
                                {{ param.parameterName }}
                              </h3>
                              <button class="nav-link mt-3" :class="{
                                active: this.enabled[subParam.parameterId],
                              }" v-for="(subParam, index) in param.subParameters" :key="index" @click="scrolling(
                                subParam.parameterId,
                                param.parameterId
                              )
                                " :id="subParam.parameterName + subParam.parameterId
                                  " data-bs-toggle="pill" data-bs-target="#Collocations" type="button" role="tab" aria-controls="Collocations"
                                aria-selected="true">
                                {{ subParam.parameterName }}
                              </button>
                            </div>

                            <div class="col-xl-9 col-lg-9 col-md-12 Self_Score_tabs_right_part tab-content"
                              id="v-pills-tabContent">
                              <div class="Overall_Cognitive_section my-4" v-for="(
                                          subReportDesc, index
                                        ) in param.subParameters" :key="index" :id="subReportDesc.parameterId">
                                <h3>{{ subReportDesc.parameterName }}</h3>
                                <div class="Overall_Cognitive_detail font-size-16 mt-4">
                                  <div class="Overall_Cognitive_detail_Definition pb-3">
                                    <span class="Recommended_heading_text">
                                      Definition
                                    </span>
                                    <p class="Overall_Cognitive_detail_Definition_content">
                                      {{
                                        subReportDesc.definition
                                          ? subReportDesc.definition
                                          : "NA"
                                      }}
                                    </p>
                                  </div>
                                  <div class="Overall_Cognitive_detail_Definition">
                                    <div class="row align-items-center">
                                      <div class="col-xl-9 col-lg-9 col-md-12 mt-4">
                                        <span class="Recommended_heading_text">
                                          Score Description
                                        </span>
                                        <p class="Overall_Cognitive_detail_Definition_content">
                                          {{
                                            subReportDesc.comment
                                              ? subReportDesc.comment
                                              : "NA"
                                          }}
                                        </p>
                                      </div>
                                      <div class="col-xl-3 col-lg-3 col-md-12 text-center mt-4">
                                        <!-- <img
                                        src="../../public/dashboard-assets/images/M7/Overall_Cognitive.png"
                                        width="100"
                                        class="ms-1 mt-2"
                                        alt=""
                                      /> -->
                                        <CustomChartVue :score="subReportDesc.scoreMain" v-if="subReportDesc.show"
                                          :main="subReportDesc.parameterName" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--subParameter-->
                  </div>
                  <div v-if="this.activeTab.reportId == 5">

                    <div class="Report_Responses_second_nav">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="item in this.typingApi" :key="item">
                          <button class="nav-link px-3 mx-1" :class="{ active: item.show }" type="button" role="tab"
                            aria-controls="eVA" aria-selected="true"
                            @click="showReportTab(this.typingApi, item.sectionId)">
                            {{ item.sectionName }}
                          </button>
                        </li>
                      </ul>
                      <br />
                      <div class="tab-content" id="myTabContent">
                        <div v-for="(item, i) in this.typingApi" :key="i">
                          <div class="tab-pane fade show active" :id="item.sectionId" role="tabpanel"
                            aria-labelledby="eVA-tab" v-if="item.show">
                            <div class="accordion Report_basic_accordion my-4" id="accordionExample"
                              v-for="(section, index) in item.questions" :key="index">
                              <div class="accordion-item mt-0">
                                <h4 class="accordion-header" id="headingOne">

                                  <button class="accordion-button" :class="{ collapsed: section.show == false }"
                                    type="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="Q1"
                                    @click="showTypingQuestion(i, section.qid)">
                                    <span class="d-flex">
                                      <span class="me-2">
                                        Q{{ index + 1 }}.
                                      </span>
                                      <div v-html="section.question"></div>
                                    </span>
                                  </button>
                                </h4>

                                <div :class="{ show: section.show }" class="accordion-collapse collapse"
                                  aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                  <div class="accordion-body" v-for="sectionRes in section.responses" :key="sectionRes">
                                    <p class="my-3 border-top border-bottom py-3">
                                      <b>Candidate Inputs:</b> <br>
                                      {{ sectionRes.candidateInputs }}
                                    </p>
                                    <div class="Report_basic_info_area">
                                      <strong>Result</strong>
                                      <table class="table table-bordered" style="border-color: #c1c1c1 !important;">
                                        <tbody>
                                          <tr style="border-color: #c1c1c1 !important;">
                                            <td style="border-color: #c1c1c1 !important;">
                                              <label class="text-muted">Time Taken</label>
                                              <h5>
                                                {{
                                                  sectionRes.scoringResults
                                                    .timeSpent
                                                }}
                                              </h5>
                                            </td>
                                            <td style="border-color: #c1c1c1 !important;">
                                              <label class="text-muted">Characters & Words</label>
                                              <h5>
                                                {{
                                                  sectionRes.scoringResults
                                                    .wordsCount
                                                }}
                                              </h5>
                                            </td>
                                            <td style="border-color: #c1c1c1 !important;">
                                              <label class="text-muted">WPM</label>
                                              <h5>
                                                {{
                                                  sectionRes.scoringResults
                                                    .wordsPerMin
                                                }}
                                              </h5>
                                            </td>
                                            <td style="border-color: #c1c1c1 !important;"> <label
                                                class="text-muted">Errors</label>
                                              <h5>
                                                {{
                                                  sectionRes.scoringResults.errors
                                                }}
                                              </h5>
                                            </td>
                                            <td style="border-color: #c1c1c1 !important;"> <label
                                                class="text-muted">Accuracy (%)</label>
                                              <h5>{{ sectionRes.scoringResults.accuracy }}</h5>
                                            </td>

                                          </tr>
                                        </tbody>

                                      </table>
                                      <!-- <div class="row">
                                      <div class="col-xl-2 col-lg-3 col-md-4 col-12 my-3">
                                        
                                      </div>
                                      <div class="col-xl-2 col-lg-3 col-md-4 col-12 my-3">
                                       
                                      </div>
                                      <div class="col-xl-2 col-lg-3 col-md-4 col-12 my-3">
                                      
                                      </div>
                                      <div class="col-xl-2 col-lg-3 col-md-4 col-12 my-3">
                                       
                                      </div>
                                      <div class="col-xl-2 col-lg-3 col-md-4 col-12 my-3">
                                      
                                      </div>
                                      
                                    </div> -->
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div v-if="this.activeTab.reportId == 6">
                    <div class="Report_Responses_second_nav">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="item in this.codingTabs" :key="item">
                          <button class="nav-link px-3 mx-1" :class="{ active: item.show }" type="button" role="tab"
                            aria-controls="eVA" aria-selected="true"
                            @click="showReportTab(this.codingTabs, item.sectionId)">
                            {{ item.sectionName }}
                          </button>
                        </li>
                      </ul>
                      <br />
                      <div class="tab-content" id="myTabContent">
                        <div v-for="item in this.codingTabs" :key="item">
                          <div class="tab-pane fade show active" :id="item.sectionId" role="tabpanel"
                            aria-labelledby="eVA-tab" v-if="item.show">
                            <div v-for="(tabContent, qid) in item.questions" :key="qid">
                              <div class="d-flex">
                                <span class="me-2"> Q{{ qid + 1 }} </span>

                                <span v-html="tabContent.question"></span>
                              </div>
                              <!-- <hr>
                              <div>
                                passage - <code> {{ tabContent.passage }}</code>
                              </div>
                              <hr> -->
                              <div class="my-3 CodeEditor-kj">
                                <!-- <textarea row="10" class="form-control mb-3" v-if="tabContent?.responses != null" :value="tabContent?.responses[0]?.candidateInputs
                                "></textarea> -->

                                <CodeEditor class="w-100" :wrap_code="true" :hide_header="true" :read_only="true"
                                  :value="tabContent?.responses[0]?.candidateInputs" theme="light"
                                  v-if="tabContent?.responses != null">
                                </CodeEditor>
                              </div>

                              <div class="table-responsive-sm">
                                <table class="table table-bordered text-center">
                                  <thead>
                                    <tr>
                                      <th v-for="(
                                                  tableContent, caseId
                                                ) in tabContent?.responses" :key="caseId">
                                        case {{ caseId + 1 }}
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td v-for="(
                                                  tableContent, caseId
                                                ) in tabContent?.responses" :key="caseId">
                                        <span v-if="tableContent.scoringResults
                                          .testCaseResult == 'Fail'
                                        "><i class="fa-solid fa-xmark text-danger"></i></span>
                                        <span v-else><i class="fa-solid fa-check text-success"></i></span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>

          </div>
          <div class="Report_basic_info_area my-4">
            <div class="Report_basic_info_heading">
              <h4>DISCLAIMER</h4>
            </div>
            <div class="mt-3">
              <!-- <p> -->
              <!-- PMaps is involved in psychometric assessments and is capable of,
                on a best effort basis designing a test as per its client’s
                requirements and making a suggestion or giving an opinion on the
                suitability of a candidate for a particular job role based on the
                evaluation and interpretation of the candidate’s test results. The
                same applies to the report on the candidate’s psychometric
                profile, the report is an opinion on the candidate’s personality.
                PMaps makes no warranty or representation with respect to the
                accuracy of its opinion, suggestion or the profile report. Client
                will use PMaps opinion and suggestion as an input in its
                recruitment process, but PMaps will not be held liable for any
                decision that client takes based on the suggestions, opinions or
                the profile report given by PMaps. Client indemnifies PMaps from
                any liability that can arise on account of utilizing PMaps
                services and holds PMaps harmless without limitation, against any
                losses, claims, costs, damages or liabilities suffered by PMaps
                arising out of or in connection with providing its services to
                client. This indemnification extends to PMaps, its affiliates,
                respective directors, officers and employees. -->
              <!-- {{ disclaimer }}
              </p> -->
              <span v-html="disclaimer"></span>
            </div>
          </div>
        </div>
        <!-- end Card -->
      </div>
    </div>
  </div>
  <div v-if="showpdf">
    <ActiveTestReportLanguagePDF :testId="this.testId" :candidateId="this.candidateId" :name="this.name" />
    <!-- v-show="false" ref="DownloadComp" -->
  </div>
</template>

<script>
import ActiveTestHeaderVue from "./ActiveTestHeader.vue";
import ActiveTestReportLanguagePDF from "./ActiveTestReportLanguagePDF.vue";
import { mapActions } from "vuex";
import CustomChartVue from "./CustomChart.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import CodeEditor from 'simple-code-editor';
import jsPDF from "jspdf";
import Base64Images from "../utils/base64Image.js";

import store from "../store";
import { SetClarityData } from "../platformFeatures/featureUtils.js";
export default {
  name: "ActiveTestReportLanguage",
  components: {
    ActiveTestHeaderVue,
    CustomChartVue,
    CodeEditor,
    ActiveTestReportLanguagePDF,
  },
  data() {
    return {
      isShowing: false,
      actived: "Skill",
      languageSummary: [],
      LanguageReport: [],
      subLanguageReport: [],
      enabled: [],
      // candidateId: 10098,
      candidateId: window.atob(this.$route.params.candidateId),
      // testId: 3532,
      testId: window.atob(this.$route.params.testId),
      name: "",//name: window.atob(this.$route.query.name),
      clientId: window.atob(this.$route.query.clientId),
      isParent: window.atob(this.$route.query.isParent),
      routesearch: window.atob(this.$route.query.id),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      score1: {},
      show1: false,
      show: [],
      score: [],
      subShow: [],
      subScore: [],
      tab: [],
      typingApi: [],
      codingTabs: [],
      activeTab: '',
      codeValue: 'ihyqASRTYUIUYTRD',
      showpdf: false,
      testName: "",
      disclaimer: "",
      reportUrl: "",
      compeleteReportUrl: "",
    };
  },
  async created() {
    store.state.loader.isLoading = true;
    this.reportUrl = window.app_base_url + "/managecandidates/GetReport?CandidateId=" + this.candidateId + "&TestId=" + this.testId + "&Report=Language";
    this.compeleteReportUrl = window.app_base_url + "/managecandidates/GetReport?CandidateId=" + this.candidateId + "&TestId=" + this.testId + "&Report=consolidated";
    await this.header();
    await this.getSummary();
    await this.getReport();
    await this.getInfo();
  },
  async mounted() {

    SetClarityData();

    setTimeout(() => {
      Array.from(
        document.querySelectorAll('img[data-bs-toggle="tooltip"]')
      ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    }, 2000);

  },
  methods: {
    ...mapActions([
      "getLanguageSummary",
      "getLanguageReport",
      "getReportData",
      "getTyping",
      "getCoding",
      "getBasicInfo"
    ]),
    scrolling(item, idx) {
      document.getElementById(item).scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
      this.subLanguageReport.forEach((element) => {
        if (element.parameterId == idx) {
          element.subParameters.forEach((elm) => {
            if (elm.parameterId == item) {
              this.enabled[elm.parameterId] = true;
            } else {
              this.enabled[elm.parameterId] = false;
            }
          });
        }
      });
      /*this.$refs[item].scrollIntoView(true, { behavior: "smooth", block: "start", inline: "start" })*/
    },
    encode(item) {
      return window.btoa(item);
    },
    async getSummary() {
      var summary = await this.getLanguageSummary(this.candidateId);
      if (summary?.status) {
        this.languageSummary = summary.object.data;
      } else {
        this.languageSummary = [];
      }
    },

    async getReport() {
      var report = await this.getLanguageReport(this.candidateId);
      if (report?.status) {
        this.LanguageReport = report?.object?.data;
        this.subLanguageReport = this.LanguageReport.parameters;
        this.subLanguageReport.forEach((element, index) => {
          if (element.parameterName == "Vocabulary")
            element.test =
              "Refer to the body of words used in a particular way. ";
          if (element.parameterName == "Grammar")
            element.test =
              "The whole system and structure of a language or of languages in general, usually taken as consisting of syntax and morphology (including inflections) and sometimes also phonology and semantics.";
          if (element.parameterName == "Comprehension") element.test = "";
          if (element.parameterName == "Pronunciation")
            element.test =
              "Refers to the candidate's score as a result of their ability to say any word.i.e the way they say or pronounce a word. ";
          if (element.parameterName == "Fluency")
            element.test =
              "Refers to the candidate's score on the ability to speak a language easily and effortlessly. ";

          if (element.subParameters?.length > 0) {
            element.subParameters.forEach((elm, idx) => {
              if (idx == 0) {
                this.enabled[elm.parameterId] = true;
              } else {
                this.enabled[elm.parameterId] = false;
              }

              elm.show = false;
              var subScore = {
                marksScored: elm.score,
                outOfMarks: elm?.ranges[elm?.ranges.length - 1].maxVal,
                result: elm.fitment,
                ranges: elm.ranges,
              };
              elm.scoreMain = subScore;
              elm.show = true;
            });
          }

          /*parameter score chart */
          this.show[index] = false;
          var score = {
            marksScored: element.score,
            outOfMarks: element?.ranges[element?.ranges.length - 1].maxVal,
            result: element.fitment,
            ranges: element.ranges,
          };
          this.score[index] = score;
          this.show[index] = true;
        });

        /*main score chart*/
        var score = {
          marksScored: this.LanguageReport.score,
          outOfMarks:
            this.LanguageReport.ranges[this.LanguageReport.ranges.length - 1]
              .maxVal,
          result: this.LanguageReport.fitment,
          ranges: this.LanguageReport.ranges,
        };
        this.score1 = score;
        this.show1 = true;
      }
    },

    async header() {
      var response = await this.getReportData(this.testId);
      if (response?.status) {
        store.state.loader.isLoading = false;
        response.object.data.details.forEach((element) => {
          if (element.name == "Skill") {
            this.tab = element.elements;

            // this.tab.push(
            //   {
            //     sectionId: 1011,
            //     name: "Typing",
            //     reportId: 5,
            //   },
            //   {
            //     sectionId: 1012,
            //     name: "Coding",
            //     reportId: 6,
            //   }
            // );
            this.activeTab = this.tab[0];
          }
        });
      }
      // this.tab.forEach((elm, index) => {
      //   if (index == 0) {
      //     elm.show = true;
      //   } else {
      //     elm.show = false;
      //   }
      // });
    },

    async showTab(tabDiv) {
      // this.tab.forEach((elm) => {
      //   if (elm.sectionId == id) {
      //     elm.show = true;
      //   } else {
      //     elm.show = false;
      //   }
      // });
      this.showTypingQuestion
      this.activeTab = tabDiv;
      switch (this.activeTab.reportId) {
        case 4:
          await this.getSummary();
          await this.getReport();
          break;
        case 5: this.typingTab();
          break;
        case 6: this.codingTab();
          break;

      }

    },

    async typingTab() {
      const response = await this.getTyping(10104);
      this.typingApi = response?.object?.data?.sections;
      this.typingApi.forEach((elm, idx) => {
        elm.show = false;
        if (idx == 0) {
          elm.show = true;
          this.showReportTab(this.typingApi, elm.sectionId);
        }
      });

      // console.log(this.typingApi);
    },

    async codingTab() {
      const response = await this.getCoding(9884);
      this.codingTabs = response.object.data.sections;
      this.codingTabs.forEach((elm, index) => {
        if (index == 0) {
          elm.show = true;
        } else {
          elm.show = false;
        }
      });
    },

    showReportTab(arr, id) {
      arr.forEach((elm) => {
        if (elm.sectionId == id) {
          elm.show = true;
          elm.questions[0].show = true;
        }
        else {
          elm.show = false;
        }
      });
    },

    showTypingQuestion(idx, id) {
      this.typingApi[idx].questions.forEach((elm) => {
        if (elm.qid == id && elm.show != true) {
          elm.show = true;
        } else {
          elm.show = false;
        }
      })
    },
    async getInfo() {
      var info = await this.getBasicInfo(this.candidateId);

      if (info?.status) {
        this.basicInfo = info.object.data;
        this.disclaimer = this.basicInfo.disclaimer;
        this.testName = this.basicInfo.testName
        this.clientCompany = this.basicInfo.clientCompanyLogo;
        this.Companylogo = this.basicInfo.companyLogo;
        this.namePdf = this.basicInfo.name;
        this.testNamePDF = this.basicInfo.testName;
      } else {
        this.basicInfo = null;
      }
      // this.basicInfo.examStarted = this.momentDateFormat(
      //   this.basicInfo.examStarted
      // );
    },
    async savePdf() {
      store.state.loader.isLoading = true;
      this.showpdf = true;
      // const doc = new jsPDF("p", "mm", "a4" ); // Create a new instance of jsPDF with A4 dimensions
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        // autoPaging: "text"
      }); // Create a new instance of jsPDF with A4 dimensions
      const namePdf = this.namePdf;
      const testName = this.testNamePDF;
      const Companylogo = this.Companylogo;
      const clientCompany = this.clientCompany;
      setTimeout(() => {
        const element = document.getElementById("lang");
        const container = document.createElement("div");
        // Append the container to the element
        element.appendChild(container);

        doc.html(element, {
          margin: [25, 0, 25, 0],
          x: 0,
          y: 0,
          autoPaging: "text",
          callback: function (pdf) {
            // footer start
            const footerContainer = document.createElement("div");
            // Append the footer container to the element
            element.appendChild(footerContainer);

            const pageCount = doc.internal.getNumberOfPages(); // Get the total number of pages in the PDF document


            //Ankit code
            //  const headerMarginTop = 10; // Set the top margin for the header
            //const footerMarginBottom = 10;
            for (let i = 1; i <= pageCount; i++) {


              doc.setPage(i);
              // header start 
              if (i === 1) {
                var imageUrl = Base64Images.Background;
                // Add the background image
                doc.addImage(imageUrl, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute(
                  "src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image
                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                // console.log(img1)
                // logo-2
                const img2 = document.createElement("img");
                img2.setAttribute("src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(
                  img2.src, "JPEG", imageX2, imageY2, image2Width, image2Height
                );
              } else {

                // header 2
                doc.setFillColor(232, 247, 250); // Set the fill color to red (change the RGB values as per your desired color)
                doc.rect(0, 0, doc.internal.pageSize.getWidth(), 20, "F"); // Draw a filled rectangle as the header background

                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute(
                  "src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image

                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                doc.setFontSize(10);

                // Set the header text margin


                // Specify the content of the header text
                const headerText = `${testName}`;

                // Calculate the x-position to center the header text
                const textWidth =
                  (doc.getStringUnitWidth(headerText) *
                    doc.internal.getFontSize()) /
                  doc.internal.scaleFactor;
                const x = (doc.internal.pageSize.getWidth() - textWidth) / 2;

                // Add the header text at the centered position
                doc.text(headerText, x, 10);

                // logo-2

                const img2 = document.createElement("img");
                img2.setAttribute(
                  "src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(img2.src, "JPEG", imageX2, imageY2, image2Width, image2Height);
              }
              // footer start
              doc.setFontSize(15);
              doc.setTextColor(41, 46, 52, 1);
              const img3 = document.createElement("img");
              img3.setAttribute(
                "src",
                Base64Images.Footer
              );

              const image3Width = doc.internal.pageSize.width; // Width of the image (equal to the page width)
              const image3Height = 20; // Height of the image
              const imageX3 = 0; // X position of the image (start from the left)
              const imageY3 = doc.internal.pageSize.height - image3Height;
              doc.addImage(img3, "JPEG", imageX3, imageY3, image3Width, image3Height);

              const footerText = "";
              const pageNumber = "Skills " + " | " + i;
              const textWidth = doc.getTextWidth(footerText); // Calculate the width of the footer text
              const xPos = doc.internal.pageSize.width - textWidth - 40; // 10 is the margin from the right side
              // Add footer text aligned to the right
              doc.text(footerText, xPos, doc.internal.pageSize.height - 10);
              // Add page number aligned to the right
              doc.text(pageNumber, xPos, doc.internal.pageSize.height - 5);
            }
            // end footer
            const pdfDataUri = pdf.output("datauristring");
            const link = document.createElement("a");
            link.href = pdfDataUri;
            link.download = `Skills_${namePdf}.pdf`;

            store.state.loader.isLoading = false;
            link.click();
          },
        });
        setTimeout(() => {
          this.showpdf = false;
        }, 2 * 1000);
      }, 1 * 60 * 1000);

    },


  },
};
</script>
<style>
.CodeEditor-kj {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>