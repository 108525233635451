<template>
  <section class="container-fluid login_page_section">
    <div class="row">
      <div class="col-md-6 login_left_section">
        <img src="../assets/Images/forgot_password_top.png" alt="" class="forgot_password_top_img" />
        <div class="text-center">
          <img src="../assets/Images/set_password.png" alt="" width="55%" />
        </div>
        <img src="../assets/Images/forgot_password_bottom.png" alt="" class="forgot_password_bottom_img" />
      </div>
      <div class="col-md-6 login_right_section">
        <div class="logo_section">
          <img src="Assets/images/Logo.png" alt="" width="120" />
        </div>
        <div style="clear: both"></div>

        <div class="row justify-content-center">
          <div class="col-xl-8 col-lg-10 col-md-11">
            <div class="login_page_heading">
              <h3 class="font-weight-800">Choose the key</h3>
              <p class="text-muted">Set the password for your account</p>
            </div>
            <form action="">
              <div class="form-group mt-5">
                <label class="input-hero" for="new_Password">
                  <input v-bind:type="[toggle ? 'text' : 'password']" class="input_area" name="new_Password"
                    v-model="Newpassword" id="new_Password" required />
                  <span class="input-name"> New Password </span>
                  <span class="toggle-password" @click="toggle = !toggle">
                    <i class="far fa-eye" :class="[toggle ? 'fa-eye' : 'fa-eye-slash']" aria-hidden="true"></i>
                  </span>
                </label>
                <p class="text-muted pt-2 ps-2">
                  5-25 characters with atleast a number, letter and special
                  character
                </p>
              </div>

              <div class="form-group mt-5">
                <label class="input-hero" for="Confirm_password">
                  <input v-bind:type="[toggle2 ? 'text' : 'password']" class="input_area" name="Confirm_password"
                    v-model="Confirmpassword" id="Confirm_password" required />
                  <span class="input-name"> Confirm Password </span>
                  <span class="toggle-password" @click="toggle2 = !toggle2">
                    <i class="far fa-eye" :class="[toggle2 ? 'fa-eye' : 'fa-eye-slash']" aria-hidden="true"></i>
                  </span>
                </label>
              </div>

              <div class="my-5 login_button_area">
                <button class="btn btn-primary" @click="setPass" type="button">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { useRoute } from "vue-router";
import { encryptData } from '@/platformFeatures/security'


export default {
  name: "SetPassword",
  data() {
    return {
      toggle: false,
      toggle2: false,
      platformdata: [],
      planCode: ''
    };
  },
  setup() {
    const route = useRoute();
    const token = route.query.token;
    return {
      token
    };
  },


  mounted() {
    if (this.token != undefined) this.verifyTokenMethod();
    else if (this.user && this.user.passwordSet) {
      this.$router.push({
        name: "DashboardGroup"
      });
    } else if (this.token != undefined && !this.user.userId) {
      this.$router.push({ name: "Login" });
    } else if (this.user == null || this.user == undefined) {
      this.$router.push({
        name: "ErrorPage"
      });
    }
  },
  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticate,
      user: state => state.auth.user,
      UserId: state => state.auth.UserId
    })
  },
  methods: {
    ...mapActions([
      "resetPassword",
      "VerifyPassToken",
      "SetPassword",
      "logout",
      "ShowWarningMessage",
      "GetBillingDetails"
    ]),

    async verifyTokenMethod() {
      const model = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.user.token
        },
        body: JSON.stringify({
          token: this.token
        })
      };
      this.isLoading = true;
      var result = await this.VerifyPassToken(model);
      if (result.status) {
        this.token = result.object.data.token;
      } else {
        this.$router.push({ name: "LinkExpired" });
      }
    },

    async setPass() {
      var passwordpattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

      if (!this.Newpassword || this.Newpassword.length < 8) {
        return this.ShowWarningMessage(
          "Password must be grater than or equal to 8 characters."
        );
      }
      if (!this.Newpassword.match(passwordpattern)) {
        return this.ShowWarningMessage(
          "Password must be contain at least one Uppercase, one Lowercase and one Special Character."
        );
      }

      if (this.Newpassword !== this.Confirmpassword) {
        return this.ShowWarningMessage(
          "The confirm password did not match the first password. Please enter matching passwords in both fields."
        );
      }
      if (this.token !== undefined) {
        await this.resetPassword({
          userId: this.UserId,
          password: this.Newpassword,
          token: this.token
        }).then(async res => {
          if (res.status) {
            await this.logout({
              userId: this.user.userId
            });
          }
        });
      } else if (this.token == undefined && this.user) {
        await this.SetPassword({
          userId: this.user.userId,
          password: { Password: this.Newpassword }
        }).then(async res => {
          if (res.status) {
            //   setTimeout(() => {
            //     location.reload();
            // }, 1000);

            if (this.user.isEnterprise == false) {
              var data = await this.GetBillingDetails({
                clientId: this.user.clientId
              })
              localStorage.setItem("billingData", JSON.stringify(data));

              // var modalStatus = true;
              // localStorage.setItem("pageStatus", modalStatus);
              this.planCode = data.object.data.plan.planCode;
              localStorage.setItem("plan", this.planCode);

            }
            if (this.planCode != '') {
              const secretKey = this.user.extId;
              const data = await this.$store.dispatch('fetchFeatures', this.planCode);
              const plandata = data.data.object.data;
              if (data.status === 200) {
                const encryptedData = encryptData(plandata, secretKey);
                localStorage.setItem("featuresData", encryptedData);
              }
            }
            var modalStatus = true;
            localStorage.setItem("pageStatus", modalStatus);

            this.$router.push({
              name: "DashboardGroup"
            });
          }
        });
      } else {
        this.$router.push({ name: "Login" });
      }
    }
  }
};
</script>
