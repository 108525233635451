<template>
    <div class="page-content">
        <h4>Cooling Period Settings</h4>
        <div class="row mt-4">
            <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6">
                <div class="form-group mb-4">
                    <label class="input-hero">
                        <select class="form-select input_area select_ca" id="durationOption"
                            v-model="this.selectCoolingPeriodType" @change="ResetDataMain()">
                            <option value='1'>Client</option>
                            <option value='2'>Test</option>
                            <option value='3'>ParentChild</option>
                        </select>
                        <span class="input-name">
                            Select Cooling Period Type
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6" v-if="this.selectCoolingPeriodType == 1">
                <label class="input-hero">
                    <Multiselect v-model="selectedclient" placeholder="Choose Client" :close-on-select="true"
                        :options="formattedPartnerList" :searchable="true" :group-select="false" @change="ResetData()">
                    </Multiselect>
                    <span class="input-name">
                        Select Client
                    </span>
                </label>
            </div>
            <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6" v-if="this.selectCoolingPeriodType == 2">
                <label class="input-hero">
                    <Multiselect v-model="selectedtest" placeholder="Choose Test" :close-on-select="true"
                        :options="formattedTestList" :searchable="true" :group-select="false" @change="ResetData()">
                    </Multiselect>
                    <span class="input-name">
                        Select Test
                    </span>
                </label>
            </div>
            <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6" v-if="this.selectCoolingPeriodType == 3">
                <label class="input-hero">
                    <Multiselect v-model="selectedParent" placeholder="Choose Parent" :close-on-select="true"
                        :options="formattedParentList" :searchable="true" :group-select="false" @change="ResetData()">
                    </Multiselect>
                    <span class="input-name">
                        Select Parent
                    </span>
                </label>
            </div>

            <div class="col-12 col-lg-4 col-xl-4 col-md-4 col-sm-6">
                <label class="input-hero">
                    <select class="form-select input_area select_ca" id="durationOption"
                        v-model="this.selectValidationType" @change="fetchData()">
                        <option value='1' selected>AND</option>
                        <option value='2'>OR</option>
                    </select>
                    <span class="input-name">
                        Select Cooling Period Validation Type
                    </span>
                </label>
            </div>
        </div>
        <div class="row mt-5" v-if="this.showFields">
            <div class="col-12 col-sm-6 col-md-4 col-xl-4">
                <div class="form-group mb-4">
                    <label class="input-hero">
                        <select class="form-select input_area select_ca" id="durationOption"
                            v-model="this.selectedDuration">
                            <option v-for="item in this.newCoolingDuration" :key="item.id" :value="item.days">
                                {{ item.name }}
                            </option>
                            <option value='-1' selected>+ Custom Days</option>

                        </select>
                        <span class="input-name">
                            Select Cooling Period Duration
                        </span>
                    </label>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-xl-4" v-if="selectedDuration == -1">
                <div class="form-group mb-4">
                    <label class="input-hero">
                        <div class="form-group mb-4">
                            <label class="form-outline">
                                <input type="number" id="durationOption" class="input_area select_ca"
                                    v-model="newDurationDay" placeholder="Add Custom Days"
                                    :disabled="selectedDuration != -1" style="height: 40px;" />
                                <span class="input-name">
                                    Add Cooling Period Duration
                                </span>
                            </label>
                        </div>
                    </label>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-xl-4">
                <div class="form-group mb-4">
                    <label class="input-hero">
                        <Multiselect v-model="selectedOptions" placeholder="Choose Mode" mode="tags"
                            :close-on-select="false" :options="coolingPeriodOptions" :searchable="true"
                            :group-select="false" required>
                        </Multiselect>
                        <span class="input-name">
                            Select Cooling Period Mode
                        </span>
                    </label>
                </div>
            </div>
        </div>

        <button class="btn btn-primary mt-5" type="button" @click="UpdateCoolingPeriodData()" v-if="this.showFields">
            Save
        </button>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { SetClarityData } from "../../platformFeatures/featureUtils";
// import store from "../../store";

export default {
    name: "AccountSettings",
    components: {
        Multiselect
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            // isLoading: (state) => state.loader.isLoading ,

        }),
        formattedPartnerList() {
            return this.PartnerList.map(partner => ({
                label: `${partner.clientId} - ${partner.company}`,
                value: partner.clientId
            }));
        },
        formattedTestList() {
            return this.ActiveTestList.map(test => ({
                label: `TestId: ${test.testId} - ClientId: ${test.clientId} - ${test.testName} `,
                value: test.testId
            }));
        },
        formattedParentList() {
            return this.ParentList.map(parent => ({
                label: `${parent.clientId} - ${parent.company}`,
                value: parent.clientId
            }));
        },

    },
    data() {
        return {
            newCoolingDuration: [],
            coolingPeriodSettings: [],
            selectedDuration: 0,
            coolingPeriodOptions: ["EmailAddress", "PAN", "Aadhaar", "MobileNumber"],
            selectedOptions: [],
            selectValidationType: 1,
            selectCoolingPeriodType: 2,
            newDurationDay: 0,
            ActiveTestList: [],
            PartnerList: [],
            ParentList: [],
            selectedclient: null,
            selectedtest: null,
            selectedParent: null,
            currentClientId: 0,
            currentTestId: 0,
            showFields: false
        }
    },
    async created() {
        // store.state.loader.isLoading = true;
        var parent = localStorage.getItem("Userpermission");
        this.clientRole = JSON.parse(parent);
        if (!(this.clientRole.roleName == "PMaps SuperAdmin")) {
            this.$router.push({
                name: "DashboardGroup"
            });
            return;
        }

        var data = await this.AllActiveTest({
            clientId: this.user.clientId,
            limit: -1,
            sortCol: "testid",
            offset: -1,
            sortdir: "desc",
        });
        this.ActiveTestList = data.object.data.testSummary;

        var partner = await this.AllPartnerList({
            clientId: this.user.clientId,
            limit: 9999,
            offset: -1,
        });

        this.PartnerList = partner.object.data.partnerData;


        var parentchild = await this.GetClientPartners({
            clientId: this.user.clientId
        });
        this.ParentList = parentchild.object.data;

        // store.state.loader.isLoading = false;

    },
    mounted() {
        SetClarityData();
    },
    methods: {
        ...mapActions([
            "GetCoolingPeriodDurationNew",
            "GetCoolingPeriodSetting",
            "CoolingPeriodDurationAddUpdate",
            "CoolingPeriodSettingsAddUpdate",
            "AllActiveTest",
            "AllPartnerList",
            "GetClientPartners"
        ]),
        ...mapMutations(["setLoading"]),

        async fetchData() {

            var clientId = 0;
            var testId = 0;

            if (this.selectCoolingPeriodType == 1) {
                clientId = this.selectedclient;
            }
            else if (this.selectCoolingPeriodType == 2) {
                testId = this.selectedtest;

                var x = this.ActiveTestList.find(item => item.testId === testId);
                clientId = x.clientId;
            }
            else if (this.selectCoolingPeriodType == 3) {
                clientId = this.selectedParent;
            }


            this.currentClientId = clientId;
            this.currentTestId = testId;

            if (clientId != 0) {
                this.showFields = true;


                var duration = await this.GetCoolingPeriodDurationNew({
                    clientId: clientId
                });
                this.newCoolingDuration = duration.object.data;

                const settings = await this.GetCoolingPeriodSetting({
                    testId: testId,
                    clientId: clientId,
                });

                if (settings.status) {
                    this.coolingPeriodSettings = settings.object.data;


                    this.selectedDuration = this.coolingPeriodSettings.duration.days;
                    const selectedNames = this.coolingPeriodSettings.elements.map(el => el.elementName);
                    this.selectedOptions = this.coolingPeriodOptions.filter(option => selectedNames.includes(option));
                }
            }


        },
        async UpdateCoolingPeriodData() {
            var name;
            var days;
            var id = 0;
            // selectValidationType:1,
            // selectCoolingPeriodType:2,
            if (this.selectedDuration == -1) {
                name = `${this.newDurationDay} Days`;
                days = this.newDurationDay;
            }
            else {
                const selectedItem = this.newCoolingDuration.find(item => item.days === this.selectedDuration);
                if (selectedItem) {
                    name = selectedItem.name;
                    days = selectedItem.days;
                    id = selectedItem.id;
                }
            }

            if (this.selectedDuration == -1) {
                await this.CoolingPeriodDuration(id, name, days);
            }
            else {
                await this.CoolingPeriodSettings(id);
            }

        },

        async CoolingPeriodDuration(id, name, days) {
            var newDurationId;
            var data = await this.CoolingPeriodDurationAddUpdate({
                clientId: this.currentClientId,
                testId: this.currentTestId,
                days: days,
                name: name,
                durationId: id
            });

            if (data.status) {
                newDurationId = data.object.data.id;

                await this.CoolingPeriodSettingsAddUpdate({
                    clientId: this.currentClientId,
                    testId: this.currentTestId,
                    durationId: newDurationId,
                    validationType: this.selectValidationType,
                    coolingPeriodType: this.selectCoolingPeriodType,
                    elements: this.selectedOptions
                });
            }
        },
        // selectValidationType:1,
        // selectCoolingPeriodType:2,
        async CoolingPeriodSettings(id) {
            await this.CoolingPeriodSettingsAddUpdate({
                clientId: this.currentClientId,
                testId: this.currentTestId,
                durationId: id,
                validationType: this.selectValidationType,
                coolingPeriodType: this.selectCoolingPeriodType,
                elements: this.selectedOptions
            });
        },
        ResetDataMain() {
            this.showFields = false;
            this.selectedDuration = 0;
            this.newDurationDay = 0;
            this.selectedOptions = [];
        },
        ResetData() {
            this.showFields = false;
            this.selectedDuration = 0;
            this.newDurationDay = 0;
            this.selectedOptions = [];
            this.selectedclient = [];
            this.selectedtest = [];
            this.selectedParent = [];

        }

    }
};
</script>