<template>
  <section class="container-fuild">
    <div class="container">
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "VerifyUser",

  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {
    var token = Object.keys(this.$route.query)[0];
    await this.verifyUser({ token: token }).then(async (ApiResponse) => {
      if (!ApiResponse.status) {
        if (ApiResponse.statusCode == 2006) {
          this.$router.push({ name: "AlreadyVerify" });
          //this.ShowWarningMessage(ApiResponse.msg.message);
          // waiting for client response 
        } else
          this.$router.push({ name: "LinkExpired" });
      } else {
        if (ApiResponse.object) {
          if (!ApiResponse.object.data.newAccount) {
            this.$router.push({
              name: "Invitation",
            });
          } else {
            await this.login({
              email: ApiResponse.object.data.email,
            }).then(async () => {
              if (this.user) {
                await this.GetAccessToken({
                  userId: this.user.userId,
                }).then(async () => {
                  this.$router.push({
                    name: "TermsPop",
                  });
                })
              } else {
                this.$router.push({ name: "Login" });
              }
            });
          }
        } else {
          this.$router.push({ name: "Login" });
        }
      }
    })
  },

  methods: {
    ...mapActions(["verifyUser", "login", "GetAccessToken", "ShowWarningMessage",]),
  },
};
</script>
<style lang="css">
@import url("/assets/css/style.css");
@import url("/assets/css/responsive.css");
</style>
