<template>
  <div class="page-content">

    <!-- mobile view Searching -->
    <div class="Searching_input_box_mobile_view mb-3 position-relative">
      <div class="w-100" v-if="this.show">
        <input type="text" v-model="searchInput" placeholder="Search..."
          @keyup.enter="searchTestDetails(searchInput)" />
        <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closemobilesearch" alt=""
          class="close-icon" />
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2">
        <h3 class="mb-2 mb-md-0">
          <router-link :to="{ name: 'TestLibrary' }" class="p-0" type="button">
            <img src="../../public/dashboard-assets/images/M2/BackButton.png" />
          </router-link>
          Test Library (Bookmarked)
        </h3>
      </div>
      <!-- Header Icons Start -->
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <!-- Search  web view-->
          <div class="top_heading_CreatePartner_btn position-relative z-index-999 mx-2">
            <div class="Group_top_three_dot">
              <div class="Searching_input-box">
                <input type="text" v-model="searchInput" placeholder="Search..."
                  @keyup.enter="searchTestDetails(searchInput)" />
                <span class="icon Group_top_three_dot_dropdown" @click="opensearch">
                  <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt=""
                    class="Searching_search_icon" />
                </span>
                <img src="../../public/dashboard-assets/images/M2/Close_icon.png" @click="closesearch" alt=""
                  class="close-icon" />
              </div>
            </div>
          </div>

          <!-- Mobile View Searching Button -->
          <div class="
            top_heading_CreatePartner_btn
            position-relative
            Searching_button_mobileView">
            <div class="Group_top_three_dot">
              <span class="icon Group_top_three_dot_dropdown" @click="openmobilesearch">
                <img src="../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
              </span>
            </div>
          </div>
        </div>

        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button" id="filterDropdown"
                data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" aria-haspopup="true"
                style="line-height: 0.3">
                <img src="../../public/dashboard-assets/images/M2/Filter_icon.png" />

                <span class="Noti-alert-kj translate-middle" v-if="this.applyfilter > 0">
                  {{ this.applyfilter }} <span class="visually-hidden"></span>
                </span>
              </button>

              <ul class="dropdown-menu dropdown-menu-kj" aria-labelledby="filterDropdown">
                <div class="modal-header p-3">
                  <h4 class="modal-title text-centerm-auto" id="CreateGroupModal1">
                    Filter
                  </h4>
                  <button type="button" class="btn-close" @click="filterclose"></button>
                </div>

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item w-50" role="presentation">
                    <button class="nav-link text-muted text-center active" id="pills-home-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                      aria-selected="true" @click="
                        this.filterTab = 1;
                      this.searchfilter = '';
                      this.Filterresult();
                      ">
                      Job Level
                      {{
                        this.filters.jobLevel.length > 0
                          ? "(" + this.filters.jobLevel.length + ")"
                          : ""
                      }}
                    </button>
                  </li>
                  <li class="nav-item w-50" role="presentation">
                    <button class="nav-link text-muted text-center" id="pills-profile-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                      aria-selected="false" @click="
                        this.filterTab = 2;
                      this.searchfilter = '';
                      this.Filterresult();
                      ">
                      Job Family
                      {{
                        this.filters.jobFamily.length > 0
                          ? "(" + this.filters.jobFamily.length + ")"
                          : ""
                      }}
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active p-0" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div class="search-bar">
                      <form class="search-form">
                        <input type="text" placeholder="Search" name="" v-model="searchfilter" @keyup="Filterresult"
                          class="form-control bg-white" /><button class="btn">
                          <i class="fas fa-search"></i>
                        </button>
                      </form>
                      <div class="ul-li-checkbox-kj">
                        <ul v-if="this.filterTab == 1">
                          <li v-for="item in JobLevelResponse" :key="item">
                            <input type="checkbox" :value="item.id" @change="addFilter(item.id, $event)" :checked="this.filters.jobLevel.includes(
                              item.id
                            )" />
                            <span> {{ item.name }} </span>
                          </li>
                        </ul>

                        <ul v-else>
                          <li v-for="item in JobFamilyResponse" :key="item">
                            <input type="checkbox" :value="item.id" @change="addFilter(item.id, $event)" :checked="this.filters.jobFamily.includes(
                              item.id
                            )" />
                            <span> {{ item.name }} </span>
                          </li>
                        </ul>
                        <div class="align-items-center">
                          <button type="button" class="btn-Apply-kj-color" v-if="this.applyfilter > 0"
                            @click="clearFilters(true)">
                            Clear Filter
                          </button>
                          <button type="button" class="modail_submit_button text-end" :class="{
                            active_form_button: this.applyfilter > 0,
                          }" :disabled="this.applyfilter == 0" @click="applyFilters()">
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2">
          <div class="Group_top_three_dot">
            <div class="icon Group_top_three_dot_dropdown"
              style="background-color:#a8a8a8; display: flex !important; justify-content: center !important; align-items: center !important;">
              <router-link :to="{ name: 'TestLibrary' }" class="p-0 my-2 mx-1" type="button">
                <i class="fa-solid fa-bookmark " style="color: #ffffff; margin: auto !important;"></i>
              </router-link>
            </div>
          </div>
        </div>


      </div>
    </div>
    <!-- Header Icons End -->

    <!-- Card Details Starts -->

    <div class="my-4">
      <section class="Partner_empty_main_section_kj mt-3">
        <div class="row">
          <div class="col-md-6 col-lg-6 col-xl-3 mb-3 stretch-card" v-for="(item, index) in bookmarkedItems"
            :key="item.id">
            <div class="card p-0" :class="screenRatioClass" v-on:mouseover="hoverIndex = index"
              v-on:mouseout="hoverIndex = null" :style="{ backgroundColor: hoverIndex === index ? '#E4DFFF' : '' }"
              id="screen-ratio">
              <div class="card-body p-0" id="card-tour">
                <!-- data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling"
                  aria-controls="offcanvasScrolling" -->
                <!-- <div class="test-library-card-banner" :style="{ backgroundColor: item.cardColor }"></div> -->
                <div class="float-end mx-3 my-2">
                  <span @click="cardUrl(item.websiteUrl)" v-if="
                    getUserPermissionData({
                      parent: 'Test Library Management',
                      child: 'Test Library',
                      permission: 'Can_Share_Test_Library',
                    })
                  ">
                    <i class="fa-solid fa-share-nodes mx-1" data-bs-toggle="modal" data-bs-target="#SharelinkModel"
                      style="cursor: pointer;"></i>
                  </span>
                  <span v-if="item.bookmarkId !== null" @click="UpdateBookmark(item)">
                    <i class="fa-solid fa-bookmark mx-1" style="cursor: pointer;"></i>
                  </span>
                  <span v-else @click="UpdateBookmark(item)">
                    <i class="fa-regular fa-bookmark mx-1" style="cursor: pointer;"></i>
                  </span>
                  <span class="Group_top_three_dot" v-if="superadmin() || this.user.isEnterprise == true">
                    <span class="dropdown">
                      <button class="btn p-0" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fa-solid fa-ellipsis mx-1" style="cursor: pointer;"></i>
                      </button>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li>
                          <button type="button" class="btn w-100 text-start" @click="notifyRm(item.testId)">
                            Request Access
                          </button>
                        </li>
                      </ul>
                    </span>
                  </span>

                </div>
                <div class="mt-5 mx-3">
                  <div class="test-name">
                    {{ item.testName }}
                  </div>

                  <div class="row mt-4">
                    <div class="col-6 test-time-questions">
                      <i class="fa-solid fa-stopwatch fa-lg" style="color: #B4B4B4;"></i>
                      <span class="px-2">{{
                        dateConvert(item.testTime) }}</span>
                    </div>
                    <div class="col-6 test-time-questions">
                      <i class="fa-solid fa-chart-simple fa-lg" style="color: #B4B4B4;"></i>
                      <span class="px-2">{{
                        item.jobLevel === null ? 'NA' : item.jobLevel }}</span>
                    </div>
                  </div>
                  <div class="my-3 mt-4">
                    <div class="d-flex flex-wrap">
                      <div v-for="(tags, index) in item.tagDetails" :key="tags.testLibraryCategoryId">
                        <div class="demo-preview" style="cursor: default;">
                          <div class="badge badge-outlined mb-2">
                            {{ activeTags(tags, index) }}
                          </div>
                        </div>
                        <div data-bs-toggle="tooltip" v-if="index == this.hideTagsCount" data-bs-placement="top"
                          :title="tooltipTags(item.tagDetails)" class="test-tags-counter">
                          +{{ item.tagDetails.length - this.hideTagsCount }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="mt-4" style="font-weight: 500; font-size: 13px;">
                        {{ item.shortDescription }}
                      </div> -->
                    <div class="mt-4 test-description">
                      {{ item.shortDescription.length > this.descriptionCharCount ? item.shortDescription.slice(0, 120)
                        + '...' :
                        item.shortDescription }}
                    </div>
                  </div>
                  <div style="text-align: center;" class="row mb-4">
                    <div class="col-6">
                      <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling"
                        aria-controls="offcanvasScrolling" @click="GetOffcanvasData(item.testId)"
                        class="test-primary-button">
                        Details
                      </button>
                    </div>
                    <div class="col-6">
                      <button v-if="superadmin() || this.user.isEnterprise == true" @click="notifyRm(item.testId)"
                        class="test-secondary-button">
                        Request Access
                      </button>
                      <button v-if="!superadmin() && this.user.isEnterprise == false"
                        @click="deployTestCard(item.testId)" class="test-secondary-button">
                        Add Test
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
    <!-- Card Details Ends -->

    <!-- OffCanvas Details Starts -->
    <!--   OffcanvasDetails-->
    <div class="offcanvas offcanvas-end role_item_view_detail_sidebar" data-bs-scroll="false" data-bs-backdrop="static"
      tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
      <div class="offcanvas-header">
        <h5 id="offcanvasScrolling" style="color: white; font-size:larger; width: 90% !important;">
          {{ OffcanvasDetails.testName }}
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
          @click="closeOffcanvas"></button>
      </div>
      <div class="offcanvas-body">
        <div class="my-2" style="font-weight: 600; font-size: 15px;">
          {{ OffcanvasDetails.jobLevel === null ? 'NA' : OffcanvasDetails.jobLevel }}
        </div>
        <!-- style="display: flex; justify-content: center;" -->
        <div style="font-weight: 600;  font-size: larger; display: inline-block; color: #4b2eee;"
          v-for="family in OffcanvasDetails.jobFamily" :key="family">
          {{ family }} &nbsp; &nbsp;
        </div>
        <div class="my-4" style="font-weight: 600; font-size: 15px;">
          Test Description
          <div class="my-1" style="width: 95%; text-align: justify; font-weight: 500;">
            {{ OffcanvasDetails.longDescription }}

          </div>
          <div class="my-4">
            <div class="details_card">
              <div class="row align-items-center justify-content-center my-1">
                <div class="col-12 d-flex flex-column align-items-center col-lg-4 col-md-3 mt-3 mb-3">



                  <!-- <img src="../../public/assets/Images/TestLibrary/Time.svg" alt="" class="my-1" width=30
                       :class="colorClassMapping[OffcanvasDetails.headerColor]" /> -->
                  <img src="../../public/assets/Images/TestLibrary/Time.svg" alt="" class="my-1" width=30 />
                  <label class="my-1" style="font-size: large; color: #4b2eee;">Time</label>
                  <label class="my-1">{{ dateConvert(OffcanvasDetails.time) ? dateConvert(OffcanvasDetails.time) :
                    'NA' }}</label>

                </div>
                <div class="col-12 d-flex flex-column align-items-center col-lg-4 col-md-3 mt-3 mb-3">
                  <img src="../../public/assets/Images/TestLibrary/Question.svg" alt="" class="my-1" />
                  <label class="my-1" style=" font-size: large; color: #4b2eee;">Questions</label>
                  <label class="my-1">{{ OffcanvasDetails.questions ? OffcanvasDetails.questions : 'NA' }}</label>

                </div>
                <div class="col-12 d-flex flex-column align-items-center col-lg-4 col-md-3 mt-3 mb-3">
                  <img src="../../public/assets/Images/TestLibrary/Reliability.svg" alt="" class="my-1" />
                  <label class="my-1" style="font-size: large; color: #4b2eee;">Reliability</label>

                  <label class="my-1">{{ OffcanvasDetails.reliability ? OffcanvasDetails.reliability : 'NA' }}</label>
                </div>
              </div>
            </div>

            <div class="my-4">
              <div class="row gap-10 justify-content-center">
                <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6" v-if="user.plan?.planCode == 'FP-001' && superadmin()">
                    <button class="btn btn-primary" type="button"
                      style="width:100% !important ; border-radius: 20px !important; "
                      :style="{ backgroundColor: OffcanvasDetails.headerColor }"> 
                      <a @click="confirmTrialTestOption()" style="color: white !important;"> -->
                <!-- <a :href="'https://poc-app.pmapstest.in/Test/RegisterForTest?Token='+this.testToken+'~'+this.regionToken' " target="_blank" style="color: white !important;"> -->

                <!-- Take a Trial Test</a></button>
                  </div> -->

                <!--    <a :href="'https://twitter.com/intent/tweet?url=' + this.SharedUrl
                    " target="_blank"
                  -->

                <div class="col-12 col-sm-12 col-md-6 col-lg-6" v-if="superadmin() || this.user.isEnterprise == true">
                  <button class="btn btn-primary" type="button"
                    style="width:100% !important ; border-radius: 10px !important; background-color: #4b2eee;"
                    @click="notifyRm(OffcanvasDetails.testId)">Request Access</button>
                </div>

                <div class="col-12 col-sm-12 col-md-6 col-lg-6" v-if="!superadmin() && this.user.isEnterprise == false">
                  <button class="btn btn-primary" type="button"
                    style="width:100% !important ; border-radius: 10px !important; background-color: #4b2eee;"
                    @click="deployTest" data-bs-dismiss="offcanvas">Add Test</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="OffcanvasDetails.competencies">
          <p class="my-4" style="font-weight: 600; font-size: 15px;">Competencies</p>
          <div v-for="item in OffcanvasDetails.competencies" :key="item.id">
            <div class="mt-4">
              <!-- : '#F4F2FF' -->
              <div class="details_card">
                <div class="row gap-10 align-items-center justify-content-center">
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column align-items-center">
                    <img src="../../public/assets/Images/TestLibrary/Behavioural.svg" class="pink" alt=""
                      v-if="item.id == 2">
                    <img src="../../public/assets/Images/TestLibrary/Cognitive.svg" alt="" v-if="item.id == 1">
                    <img src="../../public/assets/Images/TestLibrary/Skills.svg" alt="" v-if="item.id == 3">
                    <label class="my-3 competency-color" style="font-weight: 600;">{{ item.name }}</label>
                    <!-- 
  statusFlag: $('#statusFlag').val() == 'true' ? true : $('#statusFlag').val() == '' ? 'empty' : false
 -->
                  </div>
                  <div class="col-12 col-sm-12 col-md-6 col-lg-6 d-flex flex-column align-items-center">

                    <div class="row">
                      <div class="col-12 d-inline-flex pb-3" v-for="detail in item.subCompetencies" :key="detail.id">

                        <img src="../../public/assets/Images/TestLibrary/Behavioural_option.svg" alt=""
                          style="width:20px" v-if="item.id == 2" />
                        <label class="mx-2 competency-color" v-if="item.id == 2">{{ detail.name }}</label>

                        <img src="../../public/assets/Images/TestLibrary/Cognitive_option.svg" alt="" style="width:20px"
                          v-if="item.id == 1" />
                        <label class="mx-2 competency-color" v-if="item.id == 1">{{ detail.name }}</label>

                        <img src="../../public/assets/Images/TestLibrary/Skills_option.svg" alt="" style="width:20px"
                          v-if="item.id == 3" />
                        <label class="mx-2 competency-color" v-if="item.id == 3">{{ detail.name }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <p style="font-weight: 600; font-size: 15px; margin-top: 10%;">Features</p>
        <div class="col-12 d-inline-flex pb-2 " style=" margin-top: 5%;" v-for="item in OffcanvasDetails.featureList"
          :key="item">
          <img src="../../public/assets/Images/TestLibrary/Tick.svg" alt="" v-if="item.isEnabled != false" />
          <label style="font-weight: 600; font-size: 18px;" class="mx-5" v-if="item.isEnabled != false">{{ item.name
            }}</label>
        </div>
        <hr style="margin-top: 10%;">
        <p style="font-weight: 600; font-size: 15px;">Rate this content</p>


        <div class="row">
          <i v-for="rate in rates" :key="rate.id" class="ratingIcons"
            :class="{ [rate.class]: true, selected: rate.isSelected }" @click="selectRate(rate.id)"></i>
        </div>


        <div class="row" style="margin-top: 5%; margin-bottom: 5%">
          <div class="col-12 col-lg-7 col-md-6 col-sm-12">
            <input type="text" v-model="testFeedback" placeholder="Enter review here"
              style=" background-color: #DFDFDF; border-color: #DFDFDF; width: 100%; height: 35px;" />
          </div>
          <div class="col-12 col-lg-5 col-md-6 col-sm-12">
            <button class="btn btn-primary" type="button" style="background-color: #4b2eee;" @click="submitFeedback">
              Send Feedback</button>
          </div>
        </div>

      </div>

    </div>
    <!-- OffCanvas Details Ends -->



    <div class="modal fade" id="SharelinkModel" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="IncreaseTokenModalLabel">
              <span class="font-size-20-kj"> Share Link</span>
              <p class="Active-model-p-kj">
                Please choose a platform you would like to share the test.
              </p>
            </h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetShareLink"
              aria-label="btn-close"></button>
          </div>
          <div class="modal-body">
            <!-- <div class="form-group my-4">
                <label class="input-hero" for="Group">
                  <input type="text" class="input_area select_ca" :value="this.SharedUrl" />
                  <span class="input-name">Link <span class="text-danger">*</span></span></label>
              </div> -->
            <!--  v-if="SharedUrl != ''" -->
            <div class="Share-via-kj">
              <h6>Share via</h6>
              <div class="social-media-kj">
                <a :href="'https://www.facebook.com/sharer/sharer.php?u=' +
                  + encodeURIComponent('Check out PMaps Website: ' + SharedUrl)
                  " target="_blank" class="social-icon-kj">
                  <img src="../../public/dashboard-assets/images/M2/facebook.png" alt="" class="w-60" />
                  <!-- <i class="fab fa-facebook-f"></i> -->
                </a>
                <a :href="'https://twitter.com/intent/tweet?url=' + + encodeURIComponent('Check out PMaps Website: ' + SharedUrl)
                  " target="_blank" class="social-icon-kj">
                  <!-- <i class="fab fa-twitter"></i> -->
                  <img src="../../public/dashboard-assets/images/M2/twitter.png" alt="" class="60" />
                </a>
                <!-- <a :href="'https://api.whatsapp.com/send?text=' + this.SharedUrl
                    " target="_blank" class="social-icon-kj">
                    <img src="../../public/dashboard-assets/images/M2/whtasup.png" alt="" class="60" />
                  </a> -->
                <a :href="'https://api.whatsapp.com/send?text=' + encodeURIComponent('Check out PMaps Website: ' + SharedUrl)"
                  target="_blank" class="social-icon-kj">
                  <img src="../../public/dashboard-assets/images/M2/whtasup.png" alt="" class="60" />
                </a>

                <!-- <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' +
                     + encodeURIComponent('Check out PMaps Website: ' + SharedUrl) " target="_blank" class="social-icon-kj">
                    <img src="../../public/dashboard-assets/images/M2/link.png" alt="" class="60" />
                  </a> -->
                <button @click="copytoClipboard(this.SharedUrl)" data-bs-dismiss="modal"
                  class="social-icon-kj social-icomn-active">
                  <img src="../../public/dashboard-assets/images/M2/linkshare.png" alt="" class="60" />
                  <!-- <i class="fa-solid fa-paperclip"></i> -->
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="ConfirmTrialTest" tabindex="-1" aria-labelledby="IncreaseTokenModalLabel"
      aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm">
        <div class="modal-content border-radius-kj-20">
          <div class="modal-header token-model-header-color">
            <h5 class="modal-title text-centerm-auto" id="TrialTest">
              <span class="font-size-20-kj"> Trial Test</span>
            </h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" @click="resetShareLink"
              aria-label="btn-close"></button>
          </div>
          <div class="modal-body">
            <div class="Share-via-kj mt-2">
              <h5>Are you sure you want to take the trial test ?</h5>
              <div class="social-media-kj mt-4">
                <button type="button" style="margin-left: 8%" class="btn-primary btn-Apply-kj"
                  @click="trialTest()">Submit</button>
                <button type="button" style="margin-left: 12%" class="btn Assign_RM_buttons"
                  @click="closeTrialTestModal">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 
      <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
        :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'" :container-class="'pagination'"
        :page-class="'page-item'"></paginate> -->

    <div class="text-center" v-if="bookmarkedItems == false">
      <img src="../../public/assets/Images/NotBookmarked.png" style="width: 20%" class="my-5" />
      <h3 class="ms-5 mt-2">Use Bookmark button to track your Test</h3>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { superadmin } from "../utils/helper";
import { getUserPermissionData } from "../utils/helper";
// import { AccessPlanFeatures } from '../platformFeatures/featureUtils';
import { calculateRemainingDays } from '../platformFeatures/dateUtils';
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import { onUnmounted } from 'vue';


// import Paginate from "vuejs-paginate-next";

// import { Offcanvas } from 'bootstrap';
import moment from "moment";
import { SetClarityData } from "../platformFeatures/featureUtils";

export default {
  name: "Active-Tests",
  data() {
    return {
      CardDetails: [],
      TestTags: [],
      JobLevelResponse: [],
      JobFamilyResponse: [],
      CategoryList: [],
      OffcanvasDetails: [],
      hover: false,
      hoverIndex: null,
      iconClass: 'fa-regular fa-bookmark mx-2',
      isBookmark: true,
      isActive: false,
      bookmarkSelect: 'fa-solid fa-bookmark  mx-2',
      bookmarkDeselect: 'fa-regular fa-bookmark mx-2',
      SharedUrl: '',
      applyfilter: 0,
      searchInput: '',
      searchfilter: '',
      jobLevelFilter: [],
      jobFamilyFilter: [],
      competencyList: [],
      filterTab: 1,
      testFeedback: '',
      filters: {
        jobLevel: [],
        jobFamily: []
      },
      rates: [
        { id: 1, class: 'Rate1', isSelected: false },
        { id: 2, class: 'Rate2', isSelected: false },
        { id: 3, class: 'Rate3', isSelected: false },
        { id: 4, class: 'Rate4', isSelected: false },
        { id: 5, class: 'Rate5', isSelected: false },
      ],
      SelectGroup: '',
      Page: 1,
      Limit: 8,
      enterKeyPress: false,
      filterEnabled: false,
      show: false,
      filterCleared: false,
      // colorClassMapping: {
      //   '#FF90C0': 'primary-Pink',
      //   '#7C68EE': 'primary-Purple',
      //   '#FDCA11': 'primary-Yellow',
      //   '#26C7FF': 'primary-Blue',
      //   '': 'primary-Purple',

      // },
      // secondaryColorClassMapping: {
      //   '#FF90C0': 'secondary-Pink',
      //   '#7C68EE': 'secondary-Purple',
      //   '#FDCA11': 'secondary-Yellow',
      //   '#26C7FF': 'secondary-Blue',
      //   '': 'secondary-Purple',

      // },
      billing: JSON.parse(localStorage.getItem('billingData')),
      Remainingdays: 0,
      screenRatioClass: ''
      // selectedBookmarkId:null
      // Page_count: 1,
      // Page: 1,
      // Limit: 10,
      // currentPage: 1,
      // filterCount: 1
    };
  },


  async created() {
    var scale = window.devicePixelRatio;

    if (scale == 1.5) {
      this.screenRatioClass = 'small-screen-ratio'
      this.descriptionCharCount = 100;
    }
    else {
      this.screenRatioClass = 'regular-screen-ratio';
      this.descriptionCharCount = 120;
    }
    // await AccessPlanFeatures(this.user.extId,48,'Test Library Access');
    if (this.billing != null) {
      this.Remainingdays = calculateRemainingDays(this.billing.object.data.subscription.expiryDate);
    }
    var jobLevelCategory = await this.fetchAllJobLevels();
    this.JobLevelResponse = jobLevelCategory.object.data;

    this.jobLevelFilter = jobLevelCategory.object.data;
    var jobFamilyCategory = await this.GetTestLibraryCardCategories(2);

    this.JobFamilyResponse = jobFamilyCategory.object.data;
    this.jobFamilyFilter = jobFamilyCategory.object.data;
    await this.GetCardData({
      Page: 1,
      Limit: this.Limit,
      isBookmarked: this.isBookmark
    });
  },


  async mounted() {
    SetClarityData();
    await this.getNextTestLibraries();
    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
    }),
    bookmarkIconClass() {
      return {
        'fa-solid fa-bookmark mx-2': this.bookmarkSelect,
        'fa-regular fa-bookmark  mx-2': !this.bookmarkDeselect,
      };
    },
    bookmarkedItems() {
      return this.CardDetails.filter(item => item.isBookmarked);
    }
  },

  methods: {
    getUserPermissionData,
    ...mapActions(["GetTestLibraryCardDetails", "GetTestLibraryCardCategories", "CreateTestLibraryBookmark", "DeleteTestLibraryBookmark", "GetTestLibraryOffcanvasDetails", "fetchAllJobLevels", "rateTest", "submitTestReview", "ShowSucessMessage", "sendTestLibraryEmailToRm", "testLibraryTrailTest", "postDeployTest"]),
    ...mapMutations(["setLoading"]),
    superadmin,
    dateConvert: function (value) {
      return moment(value, "HH:mm:ss").format((moment(value, "HH:mm:ss").hour() === 0 ? "m [min]" : "h [hr] m [min]"));
    },
    async copytoClipboard(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        window.$("#SharelinkModel").modal("hide");
        return this.ShowSucessMessage("Link copied");
      } catch ($e) {
        // console.log($e);
      }
    },
    confirmTrialTestOption() {
      window.$("#ConfirmTrialTest").modal("show");
    },
    closeTrialTestModal() {
      window.$("#ConfirmTrialTest").modal("hide");
    },
    cardUrl(url) {
      this.SharedUrl = url;
    },
    async searchTestDetails(inputName) {
      this.CardDetails = [];
      this.enterKeyPress = true;
      await this.GetCardData({
        Page: 1,
        Limit: this.Limit,
        isBookmarked: this.isBookmark,
        Search: inputName,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null
      });
    },
    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.searchInput = '';
      this.CardDetails = [];
      this.Page = 1;
      await this.GetCardData({
        Page: this.Page,
        Limit: this.Limit,
        isBookmarked: this.isBookmark,
        Search: '',
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null
      });
    },
    openmobilesearch() {
      this.show = true;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
    },
    async closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");

      this.searchInput = '';
      this.CardDetails = [];
      this.Page = 1;

      await this.GetCardData({
        Page: this.Page,
        Limit: this.Limit,
        isBookmarked: this.isBookmark,
        Search: '',
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null
      });
    },

    async GetOffcanvasData(id) {
      var offcanvasData = await this.GetTestLibraryOffcanvasDetails({
        testId: id
      });
      // this.CategoryList = category.object.data;
      this.OffcanvasDetails = offcanvasData.object.data;

    },

    async GetCardData(payload) {
      var cardDetails = await this.GetTestLibraryCardDetails(payload);
      if (cardDetails.object.data.testLibraryDetails.items.length > 0) {
        if (this.CardDetails.length === 0) {
          this.CardDetails = cardDetails.object.data.
            testLibraryDetails.items;
        } else {
          cardDetails.object.data.
            testLibraryDetails.items.forEach((obj) => {
              this.CardDetails.push(obj);
            });
        }
        this.count = 0;
      }
      this.TestTags = this.CardDetails.tagDetails;
      // this.Page_count = cardDetails
      //     ? Math.ceil(cardDetails.object.data.filterCount / this.Limit)
      //     : 0;
      //   this.currentPage = pageNum;
    },
    async UpdateBookmark(item) {
      if (item.isBookmarked == true) {
        var removeBookmarkData = await this.DeleteTestLibraryBookmark({
          bookmarkId: item.bookmarkId
        });

        if (removeBookmarkData.status) {
          item.isBookmarked = false;
          item.bookmarkId = null;

          // document.getElementById("bookmark-" + item.testId).classList.remove("fa-solid");
          // document.getElementById("bookmark-" + item.testId).classList.add("fa-regular");
        }
      }
      else {
        var bookmarkedData = await this.CreateTestLibraryBookmark({
          testId: item.testId,
          testLibraryId: item.id,
          userId: this.user.userId
        });

        if (bookmarkedData.status) {
          item.bookmarkId = bookmarkedData.object.data.id;
          item.isBookmarked = true;
          // document.getElementById("bookmark-" + item.testId).classList.remove("fa-regular");
          // document.getElementById("bookmark-" + item.testId).classList.add("fa-solid");
          // document.getElementById("bookmark-" + item.testId).classList.remove("bookmarkDeselect");
          // document.getElementById("bookmark-" + item.testId).classList.add("bookmarkSelect");
        }
      }

    },
    // clickCallback: function (pageNum) {
    //   this.Page = (pageNum - 1) * this.Limit + 1;
    //   this.GetCardData(pageNum);
    // },
    Filterresult() {
      if (this.searchfilter != "") {
        if (this.filterTab == 1) {
          this.JobLevelResponse = this.jobLevelFilter.filter(
            (element) =>
              element.name
                .toLowerCase()
                .includes(this.searchfilter.toLowerCase())
          );
        } else {
          this.JobFamilyResponse = this.jobFamilyFilter.filter((element) =>
            element.name
              .toLowerCase()
              .includes(this.searchfilter.toLowerCase())
          );
        }
      } else {
        this.JobLevelResponse = this.jobLevelFilter;
        this.JobFamilyResponse = this.jobFamilyFilter;
      }
    },

    async addFilter(value, e) {

      if (e.target.checked) {
        if (this.filterTab == 1) {
          this.filters.jobLevel.push(value);
        } else {
          this.filters.jobFamily.push(value);
        }

        this.applyfilter =
          this.filters.jobLevel.length + this.filters.jobFamily.length;
      } else {
        if (this.filterTab == 1) {
          this.filters.jobLevel.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.jobLevel))
            ).indexOf(value),
            1
          );
        } else {
          this.filters.jobFamily.splice(
            Object.freeze(
              JSON.parse(JSON.stringify(this.filters.jobFamily))
            ).indexOf(value),
            1
          );
        }
        this.applyfilter =
          this.filters.jobLevel.length + this.filters.jobFamily.length;
        if (this.applyfilter == 0) {
          this.CardDetails = [];
          await this.GetCardData({
            Page: 1,
            Limit: this.Limit,
            isBookmarked: this.isBookmark
          });
        }
      }
    },

    async applyFilters() {
      this.offset = 1;
      this.currentPage = 1;
      this.CardDetails = [];
      this.filterEnabled = true;

      await this.GetCardData({
        Page: 1,
        Limit: this.Limit,
        isBookmarked: this.isBookmark,
        Search: this.searchInput,
        JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
        JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null
      });
      localStorage.setItem("TestBookmarkFilters", JSON.stringify(this.filters));
      // var Bookmarkfilters = localStorage.getItem("TestBookmarkFilters");
      document.getElementById("filterDropdown").click();
    },
    //   activeTags(tags, index) {
    //   if (index === 0 || index === 1) return tags.testLibraryCategoryName; 
    // },
    activeTags(tags, index) {
      if (index == 0) {
        var tag1 = tags.testLibraryCategoryName.length;
        this.remainingChar = 27 - tag1;
        this.hideTagsCount = 1;
        return tags.testLibraryCategoryName;
      }
      if (index == 1) {
        var tag2 = tags.testLibraryCategoryName.length;
        var remaining = this.remainingChar - tag2;
        this.remainingChar = 0;
        if (remaining > 0) {
          this.hideTagsCount = 2;
          return tags.testLibraryCategoryName;
        }
      }

    },

    async clearFilters(value) {
      this.CardDetails = [];
      this.filters.jobLevel = [];
      this.filters.jobFamily = [];
      this.applyfilter = 0;
      this.searchfilter = "";
      this.filterCleared = true;
      this.Filterresult();
      if (value) document.getElementById("filterDropdown").click();

      await this.GetCardData({
        Page: 1,
        Limit: this.Limit,
        isBookmarked: this.isBookmark
      });
      localStorage.removeItem('TestBookmarkFilters')
    },

    filterclose() {
      document.getElementById("filterDropdown").click();
    },
    tooltipTags(tagDetails) {
      const tagsFromIndex2 = tagDetails.slice(this.hideTagsCount).map(tag => tag.testLibraryCategoryName).join(', ');
      return tagsFromIndex2;
    },
    async getNextTestLibraries() {
      this.count = 0;

      const scrollHandler = () => {
        if ((document.documentElement.scrollTop + window.innerHeight) >= (document.documentElement.offsetHeight - 40)) {
          if (this.enterKeyPress) {
            this.enterKeyPress = false;
            this.Page = 1;
          }

          if (this.filterEnabled) {
            this.filterEnabled = false;
            this.Page = 1;
          }

          if (this.competencyFilterEnabled) {
            this.competencyFilterEnabled = false;
            this.Page = 1;
          }

          if (this.filterCleared) {
            this.filterCleared = false;
            this.Page = 1;
          }

          this.count++;
          if (!(this.count > 1)) {
            this.Page += 1;
            this.GetCardData({
              Page: this.Page,
              Limit: this.Limit,
              Search: this.searchInput,
              CompetencyTags: this.competencyList.length > 0 ? this.competencyList.toString() : null,
              JobLevel: this.filters.jobLevel.length > 0 ? this.filters.jobLevel.toString() : null,
              JobFamily: this.filters.jobFamily.length > 0 ? this.filters.jobFamily.toString() : null
            });
          }
        }
      };

      window.addEventListener('scroll', scrollHandler);

      onUnmounted(() => {
        window.removeEventListener('scroll', scrollHandler);
      });
    },

    async selectRate(rateId) {
      await this.rateTest({
        UserId: this.user.userId,
        TestId: this.OffcanvasDetails.testId,
        TestLibraryId: this.OffcanvasDetails.testLibraryId,
        Rating: rateId
      })
    },

    async submitFeedback() {
      await this.submitTestReview({
        UserId: this.user.userId,
        TestId: this.OffcanvasDetails.testId,
        TestLibraryId: this.OffcanvasDetails.testLibraryId,
        Review: this.testFeedback
      });
      this.testFeedback = '';
    },

    async notifyRm(testId) {
      await this.sendTestLibraryEmailToRm({
        testId: testId
      });
    },

    async trialTest() {
      window.$("#ConfirmTrialTest").modal("hide");

      var data = await this.testLibraryTrailTest({
        testId: this.OffcanvasDetails.testId,
        userClientId: this.user.clientId,
        amount: 1
      });
      var testUrl = data.object.data;

      var a = document.createElement('a');
      a.href = testUrl;
      a.target = '_blank';
      a.rel = 'noopener';
      a.dispatchEvent(new MouseEvent('click'));
    },
    closeOffcanvas() {
      this.$nextTick(() => {
        const offcanvasBody = document.querySelector('.offcanvas-body');
        if (offcanvasBody) {
          offcanvasBody.scrollTop = 0;
        }
      });
    },
    async deployTest() {
      if (this.Remainingdays >= 0) {
        var data = await this.postDeployTest({
          testid: this.OffcanvasDetails.testId
        });
        var testStatus = data.status;
        if (testStatus) {
          this.$router.push({
            name: 'ActiveTests',
            // params: {
            //   id: this.encode(data.object.data.testId),
            //   clientId: this.encode(data.object.data.clientId)
            // },
            // query: {
            //   search: this.encode(''),
            //   offset: this.encode(''),
            //   current: this.encode(''),
            // },
          });
        }


      }
      else {
        this.ShowErrorMessage('Your plan has been expired');
      }
    },

    encode(item) {
      return window.btoa(item);
    },
    async deployTestCard(Id) {
      if (this.Remainingdays >= 0) {
        var data = await this.postDeployTest({
          testid: Id
        });
        var testStatus = data.status;
        if (testStatus) {
          this.$router.push({
            name: 'ActiveTests',
            // params: {
            //   id: this.encode(data.object.data.testId),
            //   clientId: this.encode(data.object.data.clientId)
            // },
            // query: {
            //   search: this.encode(''),
            //   offset: this.encode(''),
            //   current: this.encode(''),
            // },
          });
        }
      }
      else {
        this.ShowErrorMessage('Your plan has been expired');
      }
    },
  },
};

</script>

<style scoped>
.test-name {
  font-size: 16px;
  font-weight: 600;
  height: 35px;
}

.small-screen-ratio .test-name {
  font-size: small;
  font-weight: 600;
  height: 35px;
}

.test-time-questions {
  font-size: 13px;
  font-weight: 500;
}

.small-screen-ratio .test-time-questions {
  font-size: 11px;
  font-weight: 500;
}

.test-primary-button {
  background-color: #4b2eee;
  font-size: small;
  border-radius: 4px;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
  width: 100%;
}

.small-screen-ratio .test-primary-button {
  font-size: 9.5px;
}

.test-secondary-button {
  background-color: #FFFFFF;
  font-size: small;
  font-weight: 600;
  border-radius: 4px;
  color: #4b2eee;
  border: 1px solid;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.small-screen-ratio .test-secondary-button {
  font-size: 9.5px;
}

.Searching_input-box input {
  border: none;
  outline: none;
  border: none;
  height: 100%;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  color: #333;
  background: none;
  border-radius: 108px;
  width: 84% !important;
}

.close-icon {
  right: 6px !important;
  margin-bottom: 7% !important;
  position: absolute;
  font-size: 30px;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #000;
  border-radius: 10px;
  background: #fff;
  z-index: 9;
  padding: 5px 10px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-50%);
}

.offcanvas-header {
  box-shadow: 0 0 15px 0;
}

.competency-color {
  color: #4F4F4F;
}

.col-sm-12 {
  margin-top: 3% !important;
}



img {
  position: relative;
}

.offcanvas-header {
  height: 10% !important;
}

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 1.5rem !important;
}

.stretch-card {
  display: block;
  align-items: stretch;
  justify-content: stretch;
  min-width: 25% !important;
  max-width: 100% !important;

  /* width: 25% !important; */
  height: 2% !important;
}

.stretch-tags {
  display: block;
  align-items: stretch;
  justify-content: stretch;
  width: 418px !important;
  height: 5% !important;
}

.card :hover {
  border-radius: 20px;
}

.stretch-card>.card {
  width: 100% !important;
  height: 100%;
  min-width: 100%;
  border-radius: 20px;
  border: none !important;
  /* box-shadow: 0px 8px 5px 1px #ababab4d; */
  box-shadow: 0px 1px 5px 1px #0909094d;

}

.stretch-card .card {
  margin-right: 35px !important;
}


.details_card {
  width: 100%;
  height: 100%;
  background-color: #F4F2FF;
  border-radius: 20px;
}

/* .competency-options {
  margin-left: -3.5%;
  display: block;
  align-items: stretch;
  justify-content: stretch;
  width: 107.5% !important;
  height: 5% !important;
} */

/* 
.competency-selected :hover {
  background-color: #eeebff !important;
  border-left: 12px solid #4b2eee !important;
} */

/* .test-competencies-card {
  top: 0;
  left: 0;
  background-color: #FDCA11;
}

.test-competencies-card .test-competencies-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 100%;
  background-color: #0dff00;
} */

.offcanvas-testname .test-library-card-label {
  left: 240px;
  top: 40px;
  transform: translateY(-50%);
}

.offcanvas-header {
  background-color: #4b2eee !important;
}

.test-library-card-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 7%;
  height: 100%;
  background-color: #000000;
  border-radius: 20px 0 0 20px !important;
}

.card-body {
  min-height: 340px !important;
}

.small-screen-ratio .card-body {
  min-height: 320px !important;

}

.test-library-card-line {
  position: absolute;
  top: 105px;
  left: 40px;
  width: 310px;
  height: 1px;
  background-color: grey;
}

.test-library-card-label {
  position: relative;
  background: whitesmoke;
  width: 45px;
  height: 45px;
  border-radius: 40px;
  text-align: center;
  padding: 25%;
  box-shadow: 0px 0px 25px 5px #ababab4d;
}

.offcanvas-button {
  position: absolute;
  border-radius: 40px;
  background: linear-gradient(103.01deg, #669BF4 0%, #7870F1 100%);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  font-family: DM SANS;
}




.demo-preview .badge {
  margin-right: 10px;
}

/* .col-6 {
  width: 50%; 

}

@media (max-width: 576px) {
  .col-6 {
    width: 100%; 
  }
} */



.badge {
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 8px;
  border: 1px solid transparent;
  min-width: 10px;
  line-height: 1;
  color: #2d2d2d;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #f1f4f9;
  /* border-radius: 99999px */
}

.small-screen-ratio .badge {
  font-size: 10px;
}

.small-screen-ratio .test-tags-counter {
  font-size: 14px;
  font-weight: 600;
}

.test-description {
  font-weight: 500;
  font-size: 12px;
}

.small-screen-ratio .test-description {
  font-weight: 500;
  font-size: 10px;
}

.ratingIcons {
  width: 40px;
  height: 40px;
  display: block;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5%;
  margin-left: 6%
}

.Rate1 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate1.svg);
}

.Rate2 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate2.svg);
}

.Rate3 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate3.svg);
}

.Rate4 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate4.svg);
}

.Rate5 {
  background-image: url(../../public/assets/Images/TestLibrary/Rate5.svg);
}

.Rate1:hover,
.Rate1.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate1.1.svg);
}

.Rate2:hover,
.Rate2.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate2.1.svg);
}

.Rate3:hover,
.Rate3.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate3.1.svg);
}

.Rate4:hover,
.Rate4.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate4.1.svg);
}

.Rate5:hover,
.Rate5.selected {
  width: 45px;
  height: 45px;
  background-image: url(../../public/assets/Images/TestLibrary/Rate5.1.svg);
}

.primary-Pink {
  filter: invert(74%) sepia(70%) saturate(739%) hue-rotate(289deg) brightness(102%) contrast(103%);
}

.primary-Purple {
  filter: invert(38%) sepia(95%) saturate(1769%) hue-rotate(228deg) brightness(98%) contrast(91%);
}

.primary-Yellow {
  filter: invert(92%) sepia(11%) saturate(6934%) hue-rotate(347deg) brightness(100%) contrast(99%);
}

.primary-Blue {
  filter: invert(55%) sepia(90%) saturate(568%) hue-rotate(167deg) brightness(102%) contrast(108%);
}

.secondary-Pink {
  filter: invert(97%) sepia(29%) saturate(5510%) hue-rotate(282deg) brightness(102%) contrast(101%);
}

.secondary-Purple {
  filter: invert(81%) sepia(24%) saturate(7484%) hue-rotate(202deg) brightness(106%) contrast(107%);
}

.secondary-Yellow {
  filter: invert(98%) sepia(94%) saturate(1079%) hue-rotate(312deg) brightness(106%) contrast(108%);
}

.secondary-Blue {
  filter: invert(74%) sepia(75%) saturate(282%) hue-rotate(172deg) brightness(104%) contrast(103%);
}

::placeholder {
  font-size: 15px;
  padding-left: 10px;
}
</style>