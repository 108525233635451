<template>
    <!-- 
     Section Name
     Section Type
     Passing Score 
     Show Instructions toggl                                            e ++
     Instruction ++
     Section Time
     IsMandatory toggle ++
     CompetencyType
     CompetencyId
     RangeLevel
    -->

    <!--  
    No of Questions
    Marks for Each Question
    Fixed Question Ids


    Question Type == Paragraph then populate Question Every Closet
    -->
    <div class="page-content">
        <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
            <div class="my-2">
                <h3 class="mb-2 mb-md-0">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">

                            <img src="../../../public/dashboard-assets/images/M2/BackButton.png"
                                @click="UserBackTracking()" style="cursor: pointer;" />
                            <span class="mx-2">Test Section Creator</span>
                        </div>

                    </div>
                </h3>
            </div>
        </div>



        <!-- Stepper Starts -->
        <div class="my-4" style="width: 100%; " v-if="!viewDetailsShow">
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between">
                <div class="step" :class="basicDetailsStatus ? 'completed' : ''">
                    <div class="step-icon-wrap">
                        <div class="step-icon">1</div>
                    </div>
                    <h4 class="step-title">Add Section</h4>
                </div>
                <div class="step">
                    <div class="step-icon-wrap">
                        <div class="step-icon">2</div>
                    </div>
                    <h4 class="step-title">Add Questions</h4>
                </div>
            </div>
        </div>
        <!-- Stepper Ends -->

        <div class="card p-4" style="border-radius: 24px;" v-if="basicDetailsShow">
            <div class="row">
                <h4 class="mb-2 mt-2">Basic Detail</h4>
                <hr>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="form-group my-3" style="width: 100%;">
                        <label class="input-hero">
                            <input type="text" class="input_area" v-model="sectionName" required>
                            <span class="input-name input-group-kj">Section Name
                            </span>
                        </label>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="form-group my-3" style="width: 100%; ">
                        <label class="input-hero">
                            <!-- <input type="number" class="input_area" v-model="sectionType"> -->
                            <select class="form-select input_area select_ca" id="durationOption"
                                v-model="this.sectionType" required>
                                <option v-for="item in sectionTypeList" :key="item" :value="item.sectionTypeId"
                                    required>{{ item.sectionTypeName }}
                                </option>
                            </select>
                            <span class="input-name input-group-kj">Section Type
                            </span>
                        </label>
                    </div>
                </div>

                <!-- <hr class="mt-3"> -->
                <h4 class="mb-2 mt-5">Section Details</h4>
                <hr class="">

                <div class="col-12 col-sm-12 col-md-6 mt-3 mb-4" style="display: flex;">
                    Is Section Mandatory<button @click="toggleCollapse('showIsMandatoryStatus')"
                        :class="{ 'toggle-button': true, 'active': showIsMandatoryStatus }" class="ms-4"></button>
                </div>

                <div class="col-12 col-sm-12 col-md-6 mt-3 mb-4" style="display: flex;">
                    Show Instruction <button @click="toggleCollapse('showInstructionStatus')"
                        :class="{ 'toggle-button': true, 'active': showInstructionStatus }" class="ms-4"></button>
                </div>


                <div class="col-12 col-sm-12 col-md-6">
                    <div class="form-group my-3" style="width: 100%;">
                        <label class="input-hero">
                            <!-- <input type="text" @input="formatTime" maxlength="8" placeholder="00:00:00"
                                class="input_area" v-model="sectionTime" required /> -->

                            <div class="mt-3" style="width: 100%;">
                                <span class="mx-2">HH</span>
                                <span><input type="number" v-model.number="sectionTimeHours" min="0" placeholder="HH"
                                        style="border-radius: 7px; width: 100%; max-width: 50px;" /></span>

                                <span class="mx-2">MM</span>
                                <span><input type="number" v-model.number="sectionTimeMinutes" min="0" max="59"
                                        placeholder="MM"
                                        style="border-radius: 7px; width: 100%; max-width: 50px;" /></span>

                                <span class="mx-2">SS</span>
                                <span><input type="number" v-model.number="sectionTimeSeconds" min="0" max="59"
                                        placeholder="SS"
                                        style="border-radius: 7px; width: 100%; max-width: 50px;" /></span>
                            </div>


                            <span class="input-name input-group-kj">Section Time
                            </span>
                        </label>
                    </div>
                </div>

                <div class="col-12 col-sm-12 col-md-6">
                    <div class="form-group my-3" style="width: 100%; ">
                        <label class="input-hero">
                            <input type="number" class="input_area" v-model="passingScore" required
                                @input="validateDecimal">
                            <span class="input-name input-group-kj">Passing Score
                            </span>
                        </label>
                    </div>
                </div>

                <!-- <div class="col-12 col-sm-12 col-md-12 mt-3" style="display: flex;">
                    Show Instructions<button @click="toggleCollapse('showInstructionStatus')"
                        :class="{ 'toggle-button': true, 'active': showInstructionStatus }" class="mb-4"></button>
                </div> -->

                <div class="col-12 col-sm-12 col-md-12" v-if="this.showInstructionStatus">
                    <label class="mt-2 mb-1">
                        Section Instruction
                    </label>
                    <div class="QuillEditor-kj">
                        <QuillEditor theme="snow" v-model:content="instructionEditor" contentType="html"
                            placeholder='Instruction will come here' :readonly='true' :toolbar="toolbarOptions"
                            @blur="saveemailtemplate" @textChange="invitedisable" />
                    </div>

                </div>

                <!-- <div class="col-12 col-sm-12 col-md-6">
                    <div class="form-group my-3" style="width: 100%;">
                        <label class="input-hero">
                            <input type="number" class="input_area" v-model="marks" required>
                            <span class="input-name input-group-kj">Marks
                            </span>
                        </label>
                    </div>
                </div> -->

                <h4 class="mb-2 mt-5">New Report
                    <button @click="toggleCollapse('newReportEnabled')"
                        :class="{ 'toggle-button': true, 'active': newReportEnabled }" class="ms-4 float-end"></button>
                </h4>
                <div v-if="newReportEnabled">
                    <hr>
                    <div class="col-12 col-sm-12 col-md-12">
                        <div class="col-4 col-sm-4 col-md-4">
                            <div class="form-group my-3" style="width: 100%;">
                                <label class="input-hero">
                                    <select class="form-select input_area select_ca" id="durationOption"
                                        v-model="this.competencyType" @change="getCompetencyIds()">
                                        <option v-for="item in competencyTypeList" :key="item" :value="item.value"
                                            required>
                                            {{ item.label }}
                                        </option>
                                    </select>
                                    <span class="input-name input-group-kj">Competency Type
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group my-3" style="width: 100%;">
                                <label class="input-hero">
                                    <Multiselect v-model="this.competencyId" :close-on-select="true"
                                        :options="formattedCompetencyIdList" :searchable="true" :group-select="false">
                                    </Multiselect>
                                    <span class="input-name input-group-kj">Competency Name
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="form-group my-3" style="width: 100%;">
                                <label class="input-hero">
                                    <select class="form-select input_area select_ca" v-model="this.selectedRangeLevel">
                                        <option v-for="item in rangeLevelList" :key="item" :value="item.rangeLevel"
                                            required>
                                            {{ item.displayName }}
                                        </option>
                                    </select>
                                    <span class="input-name input-group-kj">Range Level
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- competencyIdList -->
                <!-- <div class="col-12 col-sm-12 col-md-6">
                    <div class="form-group my-3" style="width: 100%;">
                        <label class="input-hero">

                            <Multiselect v-model="this.competencyId" :close-on-select="true"
                                :options="formattedCompetencyIdList" :searchable="true" :group-select="false">
                            </Multiselect>

                            <span class="input-name input-group-kj">Competency Name
                            </span>
                        </label>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6">
                    <div class="form-group my-3" style="width: 100%;">
                        <label class="input-hero">
                            <select class="form-select input_area select_ca" v-model="this.selectedRangeLevel">
                                <option v-for="item in rangeLevelList" :key="item" :value="item.rangeLevel" required>
                                    {{ item.displayName }}
                                </option>
                            </select>
                            <span class="input-name input-group-kj">Range Level
                            </span>
                        </label>
                    </div>
                </div> -->

            </div>

            <div class="mt-5" style="margin-left: auto;">
                <button class="btn btn-primary" @click="createSection()">Next Step</button>
            </div>
        </div>

        <div v-if="questionPageShow">
            <div class="card p-4 mb-3" style="border-radius: 24px;">
                <div> Section Name: {{ sectionName }}
                </div>
                <div> Section Time: {{ sectionTime }}
                </div>
                <div> Passing Score: {{ formatNumber(passingScore) }}
                </div>

            </div>
            <div class="card p-4" style="border-radius: 24px;">
                <h5>Question Rules
                    <span class="float-end mx-3" style="color: #4b2eee; cursor: pointer;" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasEditSection" aria-controls="offcanvasEditSection">
                        Add Question Rule
                    </span>
                </h5>

                <!-- {{ questionViewList }} -->
                <!-- <div class="d-flex my-3">
                    <span class="card p-3 mx-2" v-for="(item, index) in questionViewList" :key="item"
                        style="width: max-content; border-radius: 12px; border: 2px solid #7B68EE; color: #7B68EE; font-weight: 600;">
                        <div @click="OpenItem(item.categoryId)">
                            {{ item.categoryName }}
                            <span @click="RemoveItem(index)" style="cursor: pointer;"><i
                                    class="mx-2 fa-solid fa-trash"></i></span>
                        </div>
                    </span>
                </div> -->
                <div class="table-responsive my-4">
                    <table class="table">
                        <thead>
                            <tr>
                                <th></th>
                                <th style="width: 17.5%;">Question Categories</th>
                                <th style="width: 17.5%;">Question Bank</th>
                                <th style="width: 17.5%;">Question Type</th>
                                <th style="width: 12.5%;">Question Difficulty</th>
                                <th style="width: 12.5%;">No of Questions</th>
                                <th style="width: 12.5%;">Marks</th>
                                <th style="width: 10%;">Action</th>
                            </tr>
                        </thead>
                        <!-- <tbody ref="tableBody">
                            <tr v-for="(item, index) in questViewTable" :key="index" style="cursor: move;">
                                <td>hi</td>
                                <td>{{ item.category }}</td>
                                <td>{{ item.bank }}</td>
                                <td>{{ item.type }}</td>
                                <td>{{ item.difficulty }}</td>
                                <td>{{ item.count }}</td>
                                <td>{{ item.marks }}</td>
                                <td>
                                    <span @click="GetOffcanvasEditSectionData(item)" class="p-2 remove-button">
                                        <i class="fa-regular fa-pen-to-square" data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasEditSection"
                                            aria-controls="offcanvasEditSection"></i>
                                    </span>
                                    <span @click="DeleteQuestionRule(item.sqId)" class="p-2 remove-button">
                                        <i class="fa-solid fa-trash"></i>
                                    </span>
                                </td>
                            </tr>
                        </tbody> -->

                        <draggable v-model="questViewTable" tag="tbody" :item-key="(item) => item.categoryId"
                            handle=".drag-handle">
                            <template #item="{ element: item }">
                                <tr>
                                    <td class="drag-handle" style="cursor: move; color: lightgray;">
                                        <i class="fas fa-grip-vertical fa-sm"></i>
                                    </td>
                                    <td>{{ item.category }}</td>
                                    <td>{{ item.bank }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.difficulty }}</td>
                                    <td>{{ item.count }}</td>
                                    <td>{{ item.marks }}</td>
                                    <td>
                                        <span @click="GetOffcanvasEditSectionData(item)" class="p-2 remove-button">
                                            <i class="fa-regular fa-pen-to-square" data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasEditSection"
                                                aria-controls="offcanvasEditSection">
                                            </i>
                                        </span>
                                        <span @click="DeleteQuestionRule(item.sqId)" class="p-2 remove-button">
                                            <i class="fa-solid fa-trash"></i>
                                        </span>
                                    </td>
                                </tr>
                            </template>
                        </draggable>

                        <!-- <draggable v-model="questViewTable" tag="tbody">
                            <tr v-for="item in questViewTable" :key="item.categoryId">
                                <td>{{ item.category }}</td>
                                <td>{{ item.bank }}</td>
                                <td>{{ item.type }}</td>
                                <td>{{ item.difficulty }}</td>
                                <td>{{ item.count }}</td>
                                <td>{{ item.marks }}</td>
                                <td>
                                    <span @click="GetOffcanvasEditSectionData(item)" class="p-2 remove-button">
                                        <i class="fa-regular fa-pen-to-square" data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasEditSection" aria-controls="offcanvasEditSection">
                                        </i>
                                    </span>
                                    <span @click="DeleteQuestionRule(item.sqId)" class="p-2 remove-button">
                                        <i class="fa-solid fa-trash"></i>
                                    </span>
                                </td>
                            </tr>
                        </draggable> -->


                    </table>
                </div>

            </div>
            <div class="d-flex justify-content-between mt-4">
                <div class="">
                    <button class="btn secondary-button1" @click="previousStep">Previous Step</button>
                </div>
                <div class="">
                    <button class="btn btn-primary" @click="validateMapQuestions()">Approve</button>
                </div>
            </div>

        </div>

        <div v-if="viewDetailsShow">
            <div class="card p-4 mb-2" style="border-radius: 24px; height: 180px; ">
                <h5> Section Details
                    <span class="float-end mx-3" @click="this.EditSectionDetails()"
                        style="color: #4b2eee; cursor: pointer;">
                        Edit
                    </span>
                </h5>
                <div class="table-responsive mt-4 my-4">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 17.5%;">Section Name</th>
                                <th style="width: 17.5%;">Section Type</th>
                                <th style="width: 17.5%;">Section Time</th>
                                <th style="width: 12.5%;">Passing Score</th>
                                <th style="width: 12.5%;">Is Mandatory</th>
                                <th style="width: 12.5%;" v-if="newReportEnabled">Competency Type</th>
                                <th style="width: 12.5%;" v-if="newReportEnabled">Competency Name</th>
                                <th style="width: 12.5%;" v-if="newReportEnabled">Range Level</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ sectionName }}</td>
                                <td>{{ getSectionTypeLabel }}</td>
                                <td>{{ ConvertMinutes(sectionTime) }}</td>
                                <td>{{ formatNumber(passingScore) }}</td>
                                <td>{{ showIsMandatoryStatus }}</td>
                                <td>{{ getCompetencyTypeLabel }}</td>
                                <td>{{ getCompetencyNameLabel }}</td>
                                <td>{{ getRangeLevelLabel }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card p-4" style="border-radius: 24px;">
                <h5> Question Rules </h5>
                <div class="table-responsive my-4">
                    <table class="table">
                        <thead>
                            <tr>
                                <th style="width: 17.5%;">Question Categories</th>
                                <th style="width: 17.5%;">Question Bank</th>
                                <th style="width: 17.5%;">Question Type</th>
                                <th style="width: 12.5%;">Question Difficulty</th>
                                <th style="width: 12.5%;">No of Questions</th>
                                <th style="width: 12.5%;">Marks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in questViewTable" :key="item">
                                <td>{{ item.category }}</td>
                                <td>{{ item.bank }}</td>
                                <td>{{ item.type }}</td>
                                <td>{{ item.difficulty }}</td>
                                <td>{{ item.count }}</td>
                                <td>{{ item.marks }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- OffCanvas Details Edit Section Starts -->
        <div class="offcanvas offcanvas-end role_item_view_detail_sidebar" data-bs-scroll="false"
            data-bs-backdrop="static" tabindex="-1" id="offcanvasEditSection"
            aria-labelledby="offcanvasEditSectionLabel">
            <div>
                <h5 style="color: white; font-size:larger; width: 90% !important;">
                </h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
                    @click="resetQuestionRule"></button>
            </div>
            <div class="offcanvas-body">
                <h4 class="mt-2">
                    {{ this.actionType }} Section
                </h4>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <!-- <Multiselect v-model="selectedQuestionCategories" placeholder="Add Category"
                                    :close-on-select="true" :options="formattedQuestionCategoryList" :searchable="true"
                                    :group-select="false" @change="QuestionBankList()" ref="selectedQuestionCategoriesContainer" @scroll="selectedQuestionCategoriesScroll"> 
                                </Multiselect> -->
                                <!-- 
                                    <v-select v-model="selectedQuestionCategories" :options="formattedQuestionCategoryList"
                                        :reduce="option => option.value" :searchable="true" label="label"
                                        placeholder="Select an option">
                                        <template #list="{ options }">
                                            <RecycleScroller :items="options" :item-size="40" key-field="value"
                                                class="virtual-scroll">
                                                <template #default="{ item }">
                                                    <div class="option">
                                                        {{ item.label }}
                                                    </div>
                                                </template>
</RecycleScroller>
</template>
</v-select> -->
                                <!-- mayuresh -->
                                <vue3-select style="width: 100%;" v-model="selectedQuestionCategories"
                                    :options="formattedQuestionCategoryList" label="label"
                                    placeholder="Select an option" @update:modelValue="QuestionBankList()" />
                                <span class="input-name">
                                    Question Categories
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <!-- <Multiselect v-model="selectedQuestionBank" placeholder="Add From Bank"
                                    :close-on-select="true" :options="formattedQuestionBankList" :searchable="true"
                                    :group-select="false" @change="QuestionTypeList()">


                                </Multiselect> -->

                                <vue3-select style="width: 100%;" v-model="selectedQuestionBank"
                                    :options="formattedQuestionBankList" label="label" placeholder="Select an option"
                                    @update:modelValue="QuestionTypeList()" />

                                <span class="input-name">
                                    Question Bank
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <!-- <Multiselect v-model="selectedQuestionType" placeholder="Add Type"
                                    :close-on-select="true" :options="formattedQuestionTypeList" :searchable="true"
                                    :group-select="false" @change="QuestionDifficultyList()">
                                </Multiselect> -->

                                <vue3-select style="width: 100%;" v-model="selectedQuestionType"
                                    :options="formattedQuestionTypeList" label="label" placeholder="Select an option"
                                    @update:modelValue="QuestionDifficultyList()" />
                                <span class="input-name">
                                    Question Type
                                </span>
                            </label>
                        </div>
                    </div>


                    <div class="col-12">

                        <div class="form-group my-3">
                            <label class="input-hero">
                                <!-- <Multiselect v-model="selectedDifficultyType" placeholder="Add Difficulty"
                                    :close-on-select="true" :options="formattedQuestionDifficultyList"
                                    :searchable="true" :group-select="false" @change="GetQuestionsCount()">
                                </Multiselect> -->

                                <vue3-select style="width: 100%;" v-model="selectedDifficultyType"
                                    :options="formattedQuestionDifficultyList" label="label"
                                    placeholder="Select an option" @update:modelValue="GetQuestionsCount()" />
                                <span class="input-name">
                                    Question Difficulty
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <input type="number" class="input_area" v-model="noOfQuestion" required>
                                <span class="input-name">
                                    No of Questions
                                </span>
                            </label>
                            <p v-if="closetCount" style="color: red;">
                                Out of {{ questionsCount.totalCount }} Question and {{ questionsCount.closetCount }}
                                Closets
                                and Minimum of {{ questionsCount.minCount }} Dependent Questions in one of Closet
                            </p>
                            <p v-else-if="totalCount" style="color: red;">
                                Available Questions {{ questionsCount.totalCount }}
                            </p>
                        </div>
                    </div>

                    <div class="col-12" v-if="closetCount">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <input type="number" class="input_area" v-model="questionEveryCloset" required>
                                <span class="input-name">
                                    Question Every Closet
                                </span>
                            </label>

                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="marksForEachQuestion" required>
                                <span class="input-name">
                                    Marks
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="mt-3" style="margin-left: auto;">
                        <button class="secondary-button1 mx-3" @click="previewData">
                            <a :href="baseUrl + urlBody" target="_blank">
                                Preview
                            </a>
                        </button>

                        <button class=" btn btn-primary" @click="AddQuestions()">
                            <span v-if="this.actionType == 'Add'">Save Rule</span>
                            <span v-else-if="this.actionType == 'Edit'">Edit Rule</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <!-- OffCanvas Details Edit Section Ends -->

        <div class="modal fade" id="DeleteQuestionRuleModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
                <div class="modal-content border-radius-kj-20">
                    <div class="modal-body">
                        Do you want to delete this Question Rule?
                        <div class="text-end test_instance_button mt-4">
                            <button class="secondary-button2 mx-3" type="button" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary" @click="ProceedDeleteQuestionRule()"> Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="ValidateSectionModal" tabindex="-1" aria-labelledby="DeactivatePartnerModalLabel"
            aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
                <div class="modal-content border-radius-kj-20">
                    <div class="modal-body">
                        Would you like to Approve this Section ?
                        <div class="text-end test_instance_button mt-4">
                            <button class="secondary-button2 mx-3" type="button" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary" @click="MapQuestions()"
                                data-bs-dismiss="modal"> Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade" id="ValidateUserBackTracking" tabindex="-1"
            aria-labelledby="DeactivatePartnerModalLabel" aria-hidden="true" data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable border-radius-kj-20 model-sm">
                <div class="modal-content border-radius-kj-20">
                    <div class="modal-body">
                        Skipping the Section Creation Process ???

                        <div class="text-muted mt-3" style="font-size: medium;">
                            <i class="fa-solid fa-circle-info mx-2"></i>If Step
                            1 is completed you can still come back through test creator
                            overview page
                        </div>
                        <div class="text-end test_instance_button mt-4">
                            <button class="secondary-button2 mx-3" type="button" data-bs-dismiss="modal">
                                No
                            </button>

                            <router-link :to="{
                                name: routePage == 'test' ? 'TestCreator' : 'TestCreatorOverview',
                            }" class="p-0" type="button">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"> Yes
                                </button>
                            </router-link>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";
import { nextTick } from 'vue';
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { QuillEditor } from "@vueup/vue-quill";
import moment from "moment";
import { SetClarityData } from "../../platformFeatures/featureUtils";
import Vue3Select from "vue3-select";
import "vue3-select/dist/vue3-select.css";
// import Sortable from "sortablejs";
import draggable from 'vuedraggable';
// import { RecycleScroller } from "vue-virtual-scroller";
// import "vue-virtual-scroller/dist/vue-virtual-scroller.css";


export default {
    data() {
        return {
            sectionName: '',
            urlBody:'',
            sectionType: 0,
            passingScore: null,
            sectionTime: '',
            marks: null,
            questionCategoryList: [],
            selectedQuestionCategories: null,
            questionBankList: [],
            selectedQuestionBank: null,
            selectedQuestionType: null,
            questionTypeList: [],
            selectedDifficultyType: null,
            questionDifficultyType: [],
            sectionTypeList: [],
            basicDetailsStatus: false,
            questionPageShow: false,
            basicDetailsShow: true,
            questionList: [],
            sectionId: null,
            questionViewList: [],
            questViewTable: [],
            competencyTypeList: [
                { value: 1, label: "Cognitive" },
                { value: 2, label: "Behavioural" },
                { value: 3, label: "Skills" }
            ],
            competencyType: null,
            competencyIdList: [],
            competencyId: null,
            rangeLevelList: [],
            showInstructionStatus: false,
            selectedRangeLevel: '',
            instructionEditor: "",
            toolbarOptions: [
                ["bold", "italic", "underline", "strike"],
                [
                    {
                        list: "ordered",
                    },
                    {
                        list: "bullet",
                    },
                ],
                [
                    {
                        script: "sub",
                    },
                    {
                        script: "super",
                    },
                ],
                [
                    {
                        indent: "-1",
                    },
                    {
                        indent: "+1",
                    },
                ],

                [
                    {
                        color: [],
                    },
                    {
                        background: [],
                    },
                ],
                ["image", "link"],
            ],
            showIsMandatoryStatus: false,
            noOfQuestion: null,
            marksForEachQuestion: null,
            fixedQuestionIds: null,
            questionsCount: [],
            closetCount: false,
            totalCount: false,
            // minCount: false,
            routePage: '',
            routeData: [],

            labelQuestionCategory: "",
            labelQuestionBank: "",
            labelQuestionType: "",
            labelQuestionDifficulty: "",
            actionType: "Add",
            sqId: 0,
            newReportEnabled: false,
            viewDetailsShow: false,
            searchQuery: '',
            baseUrl: '',
            sectionTimeHours:0,
            sectionTimeMinutes:0,
            sectionTimeSeconds:0,
            // questionCategoriesBatchSize: 5,       
            // questionCategoriesStartIndex: 0, 
            // virtualQuestionCategoryList:[],
        }
    },
    // selectedQuestionCategoriesContainer
    components: {
        Multiselect,
        QuillEditor,
        Vue3Select,
        draggable
        
        // RecycleScroller
    },
    async created() {
        this.baseUrl = window.app_base_url;

     
        if (this.$route.query.page != undefined) {
            this.routePage = window.atob(this.$route.query.page);
            if (this.$route.query.id != undefined) {
                var routeId = window.atob(this.$route.query.id);
                var routeStatus = window.atob(this.$route.query.status);

                if (routeStatus == 1) {
                    this.viewDetailsShow = true;
                    this.questionPageShow = false;
                    this.basicDetailsShow = false;
                }

                this.sectionId = routeId;

                this.fetchData(routeId);
            }
            //await this.QuestionCategoryList();
        }
        else if (localStorage.getItem('CreatorId') != null) {
            var localId = localStorage.getItem('CreatorId');
            this.sectionId = localId;
            this.fetchData(localId);
        }

        //                         localStorage.setItem('CreatorId', this.sectionId);

       

        

    },


    async mounted() {
        SetClarityData();
        await this.SectionTypeList();

        // localStorage.setItem("SectionId",this.);

        // const tableBody = this.$refs.tableBody;
        // Sortable.create(tableBody, {
        //     animation: 150,
        //     onEnd: this.onDragEnd,
        // });

    },
    computed: {
        getSectionTypeLabel() {
            let type = "";
            if (this.sectionTypeList.length > 0) {
                var data = this.sectionTypeList.find(item => item.sectionTypeId == this.sectionType);
                type = data != undefined ? data.sectionTypeName : "";
            }
            return type;
        },

        getCompetencyTypeLabel() {
            let type = "";
            if (this.competencyTypeList.length > 0) {
                var data = this.competencyTypeList.find(item => item.value == this.competencyType);
                type = data != undefined ? data.label : "";
            }
            return type;
        },

        getCompetencyNameLabel() {
            let type = "";
            if (this.competencyIdList.length > 0) {
                var data = this.competencyIdList.find(item => item.id == this.competencyId);
                type = data != undefined ? data.aliasName : "";
            }
            return type;
        },

        getRangeLevelLabel() {
            let type = "";
            if (this.rangeLevelList.length > 0) {
                var data = this.rangeLevelList.find(item => item.rangeLevel == this.selectedRangeLevel);
                type = data != undefined ? data.displayName : "";
            }
            return type;
        },

        formattedCompetencyIdList() {
            return this.competencyIdList.map(list => ({
                label: list.aliasName,
                value: list.id
            }));
        },

        formattedQuestionCategoryList() {
            return this.questionCategoryList.map(list => ({
                label: list.categoryName,
                value: list.id
            }));
        },

        formattedQuestionBankList() {
            return this.questionBankList.map(list => ({
                label: list.bankName,
                value: list.qbid
            }));
        },
        formattedQuestionTypeList() {
            return this.questionTypeList.map(list => ({
                label: list.name,
                value: list.id
            }));
        },
        formattedQuestionDifficultyList() {
            return this.questionDifficultyType.map(list => ({
                label: list.value,
                value: list.id
            }))
        },

        //mayuresh
        filteredOptions() {
            return this.formattedQuestionCategoryList
                .filter(option =>
                    option.label.toLowerCase().includes(this.searchQuery.toLowerCase())
                )
                .slice(0, 100);
        },
    },
    methods: {
        ...mapActions([
            "postTestCreationSectionNew",
            "GetTestCreationQuestionBankFetchCategory",
            "GetTestCreationQuestionCategoryFetch",
            "GetTestCreationQuestionTypeFetchCategoryBank",
            "GetTestCreationQuestionDifficultyFetchCategoryBankType",
            "GetTestCreationSectionTypes",
            "ShowWarningMessage",
            "PostTestCreationSectionQuestionMap",
            "GetTestSectionCompetencyType",
            "GetTestSectionRangeLevel",
            "GetTestCreationQuestionMasterCategoryBankTypeDifficulty",
            "PostSingleQuestionRuleMap",
            "ApproveSectionQuestionRule",
            "GetTestCreationSectionsQuestions",
            "PutSingleQuestionRuleMap",
            "DeleteSingleQuestionRuleMap",
            "GetSectionMasterById",
            "PutSectionMasterDetails"
        ]),

        // onDragEnd(event) {
        //     const movedItem = this.questViewTable.splice(event.oldIndex, 1)[0];
        //     this.questViewTable.splice(event.newIndex, 0, movedItem);
        // },
        
        validateDecimal(event) {
            const value = event.target.value;
            const regex = /^\d*\.?\d{0,2}$/;
            if (!regex.test(value)) {
                event.target.value = value.slice(0, -1);
            }
        },
        formatNumber(value) {
            return Number(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
        },
        formatTime() {
            let cleaned = this.sectionTime.replace(/[^0-9]/g, "");

            if (cleaned.length > 2) {
                cleaned = `${cleaned.slice(0, 2)}:${cleaned.slice(2)}`;
            }
            if (cleaned.length > 5) {
                cleaned = `${cleaned.slice(0, 5)}:${cleaned.slice(5)}`;
            }

            this.sectionTime = cleaned.slice(0, 8);
        },

        // getSelectedCategoryLabels() {

        //     return this.questionCategoryList.map(item => {
        //         if (item.id === this.selectedQuestionCategories) {
        //             return item.categoryName;
        //         }
        //     });
        // },

        async fetchData(id){
            var sectionMaster = await this.GetSectionMasterById({ sectionMasterId: id });
            var sectionMasterData = sectionMaster.object.data;


            this.sectionName = sectionMasterData.sectionName;
            this.sectionType = sectionMasterData.sectionType;
            this.showIsMandatoryStatus = sectionMasterData.isMandatory;
            this.showInstructionStatus = sectionMasterData.showInstruction;
            this.instructionEditor = sectionMasterData.instruction;
            // if (sectionMasterData.showInstruction && !this.viewDetailsShow) {
            //     this.$nextTick(() => {
            //         var p = document.querySelector(".ql-editor");
            //         p.innerHTML = sectionMasterData.instruction == null ? "" : sectionMasterData.instruction;
            //     });
            // }

            const timeParts = moment(sectionMasterData.sectionTime, "HH:mm:ss");
            if (timeParts.isValid()) {
                this.sectionTimeHours = timeParts.hours();
                this.sectionTimeMinutes = timeParts.minutes();
                this.sectionTimeSeconds = timeParts.seconds();
            }

            this.sectionTime = sectionMasterData.sectionTime;
            this.passingScore = sectionMasterData.passingScore;
            if (sectionMasterData.competencyType) {
                this.newReportEnabled = true;
                this.competencyType = sectionMasterData.competencyType;
                await this.getCompetencyIds();
                this.competencyId = sectionMasterData.competencyId;
                this.selectedRangeLevel = sectionMasterData.rangeLevel;
            }
            // this.passingScore = this.routeData.passingScore;
            // this.sectionName = this.routeData.sectionName;
            // this.sectionTime = this.routeData.sectionTime;
            
            //this.basicDetailsStatus = true;
            //this.basicDetailsShow = true;
            //this.questionPageShow = true;

            var list = await this.GetTestCreationSectionsQuestions({ sectionMasterId: this.sectionId });
            var listData = list.object.data;
            // console.log(listData);

            listData.forEach((ele) => {
                let viewTable = {
                    categoryId: ele.category,
                    category: ele.categoryName,
                    bank: ele.qbName,
                    bankId: ele.qbid,
                    type: ele.questTypeName,
                    typeId: ele.questType,
                    difficulty: ele.difficultName,
                    difficultId: ele.difficult,
                    count: ele.noOfQuest,
                    marks: ele.questionRuleMarks,
                    sqId: ele.sqId,
                    closedQuestionsCount: ele.closedQuestionsCount
                };
                this.questViewTable.push(viewTable);
            })
        },
        async GetOffcanvasEditSectionData(item) {
            this.actionType = "Edit";
            this.sqId = item.sqId;
            this.selectedQuestionCategories = item.categoryId;
            this.noOfQuestion = item.count;
            this.marksForEachQuestion = item.marks;
            this.questionEveryCloset = item.closedQuestionsCount;

            await this.QuestionCategoryList();
            if (item.categoryId) {
                var qcl = this.formattedQuestionCategoryList.find(
                    option => option.value === item.categoryId
                );
                this.selectedQuestionCategories = qcl.label;
            }
            var data = await this.GetTestCreationQuestionBankFetchCategory({
                questionCategoryId: item.categoryId
            });

            if (data.status) {
                this.questionBankList = data.object.data;
                this.selectedQuestionBank = item.bankId;

                if (item.bankId) {
                    var qbl = this.formattedQuestionBankList.find(
                        option => option.value === item.bankId
                    );
                    this.selectedQuestionBank = qbl.label;
                }

                var bankData = await this.GetTestCreationQuestionTypeFetchCategoryBank({
                    questionCategoryId: item.categoryId,
                    questionBankId: item.bankId
                });

                if (bankData.status) {
                    this.questionTypeList = bankData.object.data;
                    this.selectedQuestionType = item.typeId;

                    if (item.typeId) {
                        var qtl = this.formattedQuestionTypeList.find(
                            option => option.value === item.typeId
                        );
                        this.selectedQuestionType = qtl.label;
                    }


                    var typeData = await this.GetTestCreationQuestionDifficultyFetchCategoryBankType({
                        questionCategoryId: item.categoryId,
                        questionBankId: item.bankId,
                        questionTypeId: item.typeId
                    })

                    if (typeData.status) {
                        this.questionDifficultyType = typeData.object.data;
                        this.selectedDifficultyType = item.difficultId;

                        if (item.difficultId) {
                            var qdl = this.formattedQuestionDifficultyList.find(
                                option => option.value === item.difficultId
                            );
                            this.selectedDifficultyType = qdl.label;
                        }

                        var difficultyData = await this.GetTestCreationQuestionMasterCategoryBankTypeDifficulty({
                            questionCategoryId: item.categoryId,
                            questionBankId: item.bankId,
                            questionTypeId: item.typeId,
                            questionDifficultyId: item.difficultId,
                        });

                        if (difficultyData.status) {
                            this.questionsCount = difficultyData.object.data;
                            this.closetCount = this.questionsCount.closetCount > 0;
                            this.totalCount = this.questionsCount.totalCount > 0;
                        }
                    }
                }
            }
        },

        async SectionTypeList() {
            var data = await this.GetTestCreationSectionTypes();
            this.sectionTypeList = data.object.data;
        },

        async GetQuestionsCount() {
            if (this.selectedQuestionCategories != null && this.selectedQuestionBank != null &&
                this.selectedQuestionType != null) {
                this.questionsCount = [];
                this.closetCount = false;
                this.totalCount = false;
                // this.minCount = false;

                var categoryId = this.checkQuestionCategoriesType();
                var bankId = this.checkQuestionBankType();
                var typeId = this.checkQuestionTypeType();
                var diffId = this.checkQuestionDifficultyType();

                nextTick(async () => {
                    var data = await this.GetTestCreationQuestionMasterCategoryBankTypeDifficulty({
                        questionCategoryId: categoryId,
                        questionBankId: bankId,
                        questionTypeId: typeId,
                        questionDifficultyId: diffId,
                    });

                    if (data.status) {
                        this.questionsCount = data.object.data;
                        this.closetCount = this.questionsCount.closetCount > 0;
                        this.totalCount = this.questionsCount.totalCount > 0;

                        this.labelQuestionDifficulty = this.LabelFinder(this.formattedQuestionDifficultyList, diffId);


                    }

                    // this.minCount = this.questionsCount.minCount > 0;

                });
            }


        },

        toggleCollapse(item) {
            if (item == 'showInstructionStatus') {
                this.showInstructionStatus = !this.showInstructionStatus;
            }
            else if (item == 'showIsMandatoryStatus') {
                this.showIsMandatoryStatus = !this.showIsMandatoryStatus;
            }
            else if (item == 'newReportEnabled') {
                this.newReportEnabled = !this.newReportEnabled;
                if (!this.newReportEnabled) {
                    this.competencyType = null;
                    this.competencyId = null;
                    this.selectedRangeLevel = '';
                }
            }
        },
        async getCompetencyIds() {
            var compId = await this.GetTestSectionCompetencyType(
                {
                    typeId: this.competencyType
                }
            );

            if (compId.status) {
                this.competencyId = null;
                this.competencyIdList = compId.object.data;
            }
            var data = await this.GetTestSectionRangeLevel();
            this.rangeLevelList = data.object.data;
        },
        async createSection() {
          

            const duration = moment
                .duration({
                    hours: this.sectionTimeHours,
                    minutes: this.sectionTimeMinutes,
                    seconds: this.sectionTimeSeconds,
                })
                .asMilliseconds();

            var updatedTime = moment.utc(duration).format("HH:mm:ss");

            if (this.sectionName && updatedTime != '00:00:00' && this.passingScore) {
                if (this.passingScore <= 0) {
                    return this.ShowWarningMessage(`Passing score cannot be less than 1.`);
                }
                if (this.passingScore > 100) {
                    return this.ShowWarningMessage(`Passing score cannot be more than 100.`);
                }
                else if (this.showInstructionStatus == true && this.instructionEditor === '') {
                    return this.ShowWarningMessage(`Instruction cannot be empty.`);
                }
                else if (this.newReportEnabled == true && (this.competencyType === null || this.competencyId === null || this.selectedRangeLevel === '')) {
                    return this.ShowWarningMessage(`Please provide Competency Type, Competency Name, Range Level.`);
                }
                else {                    
               


                    var payload = {
                        sectionName: this.sectionName,
                        sectionType: this.sectionType,
                        passingScore: this.passingScore,
                        showInstruction: this.showInstructionStatus,
                        instruction: this.instructionEditor,
                        isMandatory: this.showIsMandatoryStatus,
                        sectionTime: updatedTime,
                        competencyType: this.competencyType,
                        competencyId: this.competencyId,
                        rangeLevel: this.selectedRangeLevel,
                        sectionMasterId: this.sectionId
                    }

                    let data;
                    if (this.sectionId == null || this.sectionId == 0) {
                        data = await this.postTestCreationSectionNew(payload);
                    }
                    else {
                        data = await this.PutSectionMasterDetails(payload);
                    }
                    this.sectionId = data.object.data.sectionMasterId;
                    if (data.status) {
                        this.basicDetailsStatus = true;
                        this.basicDetailsShow = false;
                        this.questionPageShow = true;
                        await this.QuestionCategoryList();
                        localStorage.setItem('CreatorId', this.sectionId);

                    }
                }
            }
            else {
                return this.ShowWarningMessage(`Please provide Section Name, Section Time, Passing Score`);
            }
        },
        async AddQuestions() {
            var categoryId = this.checkQuestionCategoriesType();
            var bankId = this.checkQuestionBankType();
            var typeId = this.checkQuestionTypeType();
            var diffId = this.checkQuestionDifficultyType();


            if (categoryId != null && bankId != null && typeId != null && diffId != null && this.noOfQuestion) {
                var data;
                if (this.closetCount) {
                    data = this.questionsCount.totalCount;
                }
                else {
                    data = this.questionsCount.totalCount;
                }

                if (this.noOfQuestion <= 0){
                    return this.ShowWarningMessage(`No of Question can't be less than 0`);
                }
                if (this.marksForEachQuestion <= 0 || this.marksForEachQuestion > 100) {
                    return this.ShowWarningMessage(`Marks can't be grator than 100 or negative`);
                }

                if (this.noOfQuestion <= data) {
                    var model = {
                        sqId: this.sqId,
                        sectionMasterId: this.sectionId,
                        qbid: bankId,
                        category: categoryId,
                        questType: typeId,
                        difficult: diffId,
                        noOfQuest: this.noOfQuestion,
                        questionRuleMarks: this.marksForEachQuestion,
                        ClosedQuestionsCount: this.questionEveryCloset
                        // "fixedQuestionIds": this.fixedQuestionIds
                    };

                    // if (this.minCount) {
                    //     if (this.questionEveryCloset <= this.questionsCount.minCount) {
                    //         model.ClosedQuestionsCount = this.questionEveryCloset;
                    //     }
                    //     else {
                    //         return this.ShowWarningMessage(`You are  having ${this.questionsCount.minCount} , and you are adding ${this.questionEveryCloset}`);

                    //     }
                    // }

                    let result = this.questionCategoryList.find(item => item?.id === categoryId);
                    var viewList = {
                        "categoryName": result.categoryName,
                        "categoryId": result.id
                    };

                    var viewTable = {
                        categoryId: this.sectionMasterId,
                        category: this.labelQuestionCategory,
                        bank: this.labelQuestionBank,
                        type: this.labelQuestionType,
                        difficulty: this.labelQuestionDifficulty,
                        count: this.noOfQuestion,
                        marks: this.marksForEachQuestion
                    };

                    let addeditResp;
                    if (this.actionType == "Add") {
                        addeditResp = await this.PostSingleQuestionRuleMap(model);
                    }
                    else if (this.actionType == "Edit") {
                        addeditResp = await this.PutSingleQuestionRuleMap(model);
                    }

                    if (addeditResp.status) {
                        this.questViewTable.push(viewTable);
                        this.questionViewList.push(viewList);
                        this.questionList.push(model);

                        this.questViewTable = [];
                        var list = await this.GetTestCreationSectionsQuestions({ sectionMasterId: this.sectionId });
                        var listData = list.object.data;
                        listData.forEach((ele) => {
                            let viewTable = {
                                categoryId: ele.category,
                                category: ele.categoryName,
                                bank: ele.qbName,
                                bankId: ele.qbid,
                                type: ele.questTypeName,
                                typeId: ele.questType,
                                difficulty: ele.difficultName,
                                difficultId: ele.difficult,
                                count: ele.noOfQuest,
                                marks: ele.questionRuleMarks,
                                sqId: ele.sqId,
                                closedQuestionsCount: ele.closedQuestionsCount
                            };
                            this.questViewTable.push(viewTable);
                        });

                        this.resetQuestionRule();
                    }


                }
                else {
                    return this.ShowWarningMessage(`You are only having ${data} in selected parameters and you are adding ${this.noOfQuestion}`);
                }
            }
            else {
                return this.ShowWarningMessage(`Select Question Categories , Question Bank , Question Type, Difficulty Type , noOfQuestion `);

            }

        },
        resetQuestionRule() {
            this.actionType = "Add";
            this.sqId = 0;
            this.selectedQuestionCategories = null;
            this.selectedQuestionBank = null;
            this.selectedQuestionType = null;
            this.selectedDifficultyType = null;
            this.noOfQuestion = null;
            this.marksForEachQuestion = null;
            this.fixedQuestionIds = null;
            this.questionsCount = [];
            this.questionEveryCloset = null;

        },

        RemoveItem(index) {
            this.questionViewList.splice(index, 1);
            this.questionList.splice(index, 1);
        },

        UserBackTracking(){
            window.$("#ValidateUserBackTracking").modal("show");
        },
        validateMapQuestions(){
            window.$("#ValidateSectionModal").modal("show");
        },

        async MapQuestions() {
            if(this.questViewTable.length>0){
                var data = await this.ApproveSectionQuestionRule({ "sectionId": this.sectionId });
                //var a = await this.PostTestCreationSectionQuestionMap(this.questionList);

                if (data.status) {
                    this.$router.push({ name: this.routePage == 'test' ? 'TestCreator' : 'TestCreatorOverview' });
                }
            }
            else{
                return this.ShowWarningMessage("Please Add Question Rule Before Proceeding");
            }
            
           


        },
        async QuestionCategoryList() {
            var data = await this.GetTestCreationQuestionCategoryFetch();
            this.questionCategoryList = data.object.data;

        },

        ConvertMinutes(time) {

            // const duration = moment.duration(time);
            // const hours = Math.round(duration.asHours());
            // const minutes = Math.round(duration.minutes());
            // const seconds = Math.round(duration.seconds());

            // let result = '';
            // if (hours > 0) {
            //     result += `${hours} hr `;
            // }
            // if (minutes > 0) {
            //     result += `${minutes} min `;
            // }
            // if (seconds > 0) {
            //     result += `${seconds} sec`;
            // }
            // return result.trim();


            const timeParts = moment(time, "HH:mm:ss");

            if (timeParts.isValid()) {
                const hours = timeParts.hours();
                const minutes = timeParts.minutes();
                const seconds = timeParts.seconds();

                let timeStrings = [];
                if (hours > 0) timeStrings.push(`${hours} hr`);
                if (minutes > 0) timeStrings.push(`${minutes} min`);
                if (seconds > 0) timeStrings.push(`${seconds} sec`);

                return timeStrings.join(" ");
            }
        },

        LabelFinder(list, value) {
            var result = list.find((item) => item.value === value);
            return result ? result.label : "Not Found";
        },

        checkQuestionCategoriesType(){
            var categoryId;
            if (typeof this.selectedQuestionCategories === 'string' && this.selectedQuestionCategories != null) {
                var qcl = this.formattedQuestionCategoryList.find(
                    option => option.label === this.selectedQuestionCategories
                );
                categoryId = qcl.value;
            }
            else if (typeof this.selectedQuestionCategories === 'object' && this.selectedQuestionCategories != null){
                categoryId = this.selectedQuestionCategories.value;
            }
            else{
                categoryId = null;
            }
            return categoryId;
        },

        checkQuestionBankType(){
            var bankId;
            if (typeof this.selectedQuestionBank === 'string' && this.selectedQuestionBank !=null) {
                var qbl = this.formattedQuestionBankList.find(
                    option => option.label === this.selectedQuestionBank
                );
                bankId = qbl.value;
            }
            else if (typeof this.selectedQuestionBank === 'object' && this.selectedQuestionBank != null){
                bankId = this.selectedQuestionBank.value;
            }
            else{
                bankId = null;
            }
            return bankId;
        },

        checkQuestionTypeType() {
            var typeId;
            if (typeof this.selectedQuestionType === 'string' && this.selectedQuestionType != null) {
                var qtl = this.formattedQuestionTypeList.find(
                    option => option.label === this.selectedQuestionType
                );
                typeId = qtl.value;
            }
            else if (typeof this.selectedQuestionType === 'object' && this.selectedQuestionType != null) {
                typeId = this.selectedQuestionType.value;
            }    
            else{
                typeId = null;
            }
            
            return typeId;
        },

        checkQuestionDifficultyType(){
            var diffId;
            if (typeof this.selectedDifficultyType === 'string' && this.selectedDifficultyType != null) {
                var qdl = this.formattedQuestionDifficultyList.find(
                    option => option.label === this.selectedDifficultyType
                );
                diffId = qdl.value;
            }
            else if (typeof this.selectedDifficultyType === 'object' && this.selectedDifficultyType != null) {
                diffId = this.selectedDifficultyType.value;
            }
            else{
                diffId = null;  
            }

            return diffId;
        },

        previewData() {
            this.urlBody = '';
            
            var categoryId = this.checkQuestionCategoriesType();
            var bankId = this.checkQuestionBankType();
            var typeId = this.checkQuestionTypeType();
            var diffId = this.checkQuestionDifficultyType();
            

            this.urlBody = `/ManageQuestions/ViewQuestions?QBID=${bankId}&QCID=${categoryId}&QTID=${typeId}&QDID=${diffId}`;

        },

        previousStep() {
            this.basicDetailsStatus = true;
            this.basicDetailsShow = true;
            this.questionPageShow = false;
        },

        async QuestionBankList() {
            this.questionBankList = [];
            this.selectedQuestionBank = null;
            this.questionTypeList = [];
            this.selectedQuestionType = null;
            this.questionDifficultyType = [];
            this.selectedDifficultyType = null;
            this.questionsCount = [];
            this.closetCount = false;
            this.totalCount = false;
            // this.minCount = false;


            var categoryId = this.checkQuestionCategoriesType();
            

            nextTick(async () => {
                if (categoryId) {
                    var data = await this.GetTestCreationQuestionBankFetchCategory({
                        questionCategoryId: categoryId
                    });

                    if (data.status) {
                        this.questionBankList = data.object.data;
                        this.labelQuestionCategory = this.LabelFinder(this.formattedQuestionCategoryList, categoryId);
                    }
                    else {
                        this.labelQuestionCategory = "";
                    }
                }
            })
        },

        async QuestionTypeList() {
            this.questionTypeList = [];
            this.selectedQuestionType = null;
            this.questionDifficultyType = [];
            this.selectedDifficultyType = null;

            var categoryId = this.checkQuestionCategoriesType();
            var bankId = this.checkQuestionBankType();
         
            nextTick(async () => {
                if (bankId) {
                    var data = await this.GetTestCreationQuestionTypeFetchCategoryBank({
                        questionCategoryId: categoryId,
                        questionBankId: bankId
                    });

                    if (data.status) {
                        this.questionTypeList = data.object.data;
                        this.labelQuestionBank = this.LabelFinder(this.formattedQuestionBankList, bankId);
                    }
                    else {
                        this.labelQuestionBank = "";
                    }
                }
            });
        },

        async QuestionDifficultyList() {
            this.questionDifficultyType = [];
            this.selectedDifficultyType = null;
            this.questionsCount = [];
            this.closetCount = false;
            this.totalCount = false;
            // this.minCount = false;

            var categoryId = this.checkQuestionCategoriesType();
            var bankId = this.checkQuestionBankType();
            var typeId = this.checkQuestionTypeType();
            
            nextTick(async () => {
                if (typeId) {
                    var data = await this.GetTestCreationQuestionDifficultyFetchCategoryBankType({
                        questionCategoryId: categoryId,
                        questionBankId: bankId,
                        questionTypeId: typeId
                    })

                    if (data.status) {
                        this.questionDifficultyType = data.object.data;
                        this.labelQuestionType = this.LabelFinder(this.formattedQuestionTypeList, typeId);
                    }
                    else {
                        this.labelQuestionType = "";
                    }

                }
            })
        },

        DeleteQuestionRule(sqId) {
            this.sqId = sqId;
            window.$("#DeleteQuestionRuleModal").modal("show");
        },



        EditSectionDetails() {
            this.viewDetailsShow = false;
            this.questionPageShow = false;
            this.basicDetailsShow = true;
            localStorage.setItem('CreatorId', this.sectionId);
            this.$router.push({
                name: 'TestSectionCreator',
                query: {
                    id: window.btoa(this.sectionId),
                    status: window.btoa(0),
                },
            });
        },

        async ProceedDeleteQuestionRule() {
            await this.DeleteSingleQuestionRuleMap({ sqId: this.sqId });
            this.sqId = 0;

            this.questViewTable = [];
            var list = await this.GetTestCreationSectionsQuestions({ sectionMasterId: this.sectionId });
            var listData = list.object.data;
            listData.forEach((ele) => {
                let viewTable = {
                    categoryId: ele.category,
                    category: ele.categoryName,
                    bank: ele.qbName,
                    bankId: ele.qbid,
                    type: ele.questTypeName,
                    typeId: ele.questType,
                    difficulty: ele.difficultName,
                    difficultId: ele.difficult,
                    count: ele.noOfQuest,
                    marks: ele.questionRuleMarks,
                    sqId: ele.sqId,
                    closedQuestionsCount: ele.closedQuestionsCount
                };
                this.questViewTable.push(viewTable);
            });

            window.$("#DeleteQuestionRuleModal").modal("hide");
        }
    },

    async displayQuestionRule() {
        this.questViewTable = [];
        var list = await this.GetTestCreationSectionsQuestions({ sectionMasterId: this.sectionId });
        var listData = list.object.data;
        listData.forEach((ele) => {
            let viewTable = {
                categoryId: ele.category,
                category: ele.categoryName,
                bank: ele.qbName,
                bankId: ele.qbid,
                type: ele.questTypeName,
                typeId: ele.questType,
                difficulty: ele.difficultName,
                difficultId: ele.difficult,
                count: ele.noOfQuest,
                marks: ele.questionRuleMarks,
                sqId: ele.sqId,
                closedQuestionsCount: ele.closedQuestionsCount
            };
            this.questViewTable.push(viewTable);
        });
    }
}
</script>
<style scoped>
.QuillEditor-kj {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column-reverse;
    /* margin-top: 45px; */
    border: 2px solid #d1d5db;
    border-radius: 10px;
}

.QuillEditor-inside-dot-relative {
    position: relative;
    right: 0;
}

.QuillEditor-inside-dot-fixed {
    position: absolute;
    right: 20px;
    top: 10px;
}

.toggle-button {
    width: 52px;
    min-width: 52px;
    height: 28px;
    background-color: #ccc;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s;
}

.toggle-button::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggle-button.active {
    background-color: #7b68ee;
}

.toggle-button.active::before {
    transform: translateX(100%);
}

.steps .step {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
}

.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 55px;
    text-align: center
}

.steps .step .step-icon-wrap-extreme-end {
    display: block;
    position: relative;
    height: 55px;
    text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -9px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}

.steps .step .step-icon-wrap-extreme-end::before,
.steps .step .step-icon-wrap-extreme-end::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -9px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}

.steps .step .step-icon-wrap::before {
    left: 0
}

.steps .step .step-icon-wrap::after {
    right: 0
}

.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 20px;
    line-height: 40px;
    z-index: 5;
}

.steps .step .step-title {
    /* margin-top: 16px; */
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
    display: none
}

.steps .step:last-child .step-icon-wrap::after {
    display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    background-color: #7b68ee
}

.steps .step.completed .step-icon {
    border-color: #7b68ee;
    background-color: #7b68ee;
    color: #fff
}

@media (max-width: 576px) {

    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 768px) {

    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 991px) {

    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 1200px) {

    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

.bg-faded,
.bg-secondary {
    background-color: #f5f5f5 !important;
}

.secondary-button1 {
    border: 2px solid #7c68ee !important;
    background: #fff !important;
    color: #7c68ee !important;
    font-weight: 600;
    border-radius: 3px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
}

.secondary-button2 {
    border: 2px solid #7c68ee !important;
    background: #fff !important;
    color: #7c68ee !important;
    font-weight: 600;
    border-radius: 24px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
}

.table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
}

.remove-button {
    background-color: #F1F0FF;
    /* margin-left: 5%; */
    color: #4b2eee;
    font-weight: 600;
    margin: 5%;
    border-radius: 15px;
    cursor: pointer;
}

.remove-button:hover {
    background-color: #ffffff;
    color: #4b2eee;
}
</style>