<template>
  <div class="Overview_dashboard_map_section my-4">

    <div class="card p-4" style="max-height: 320px; position: relative;">
      <div class="card-body p-0">
        <div>
          <h3 style="font-weight: 600;">Assessment Token Usage Summary
            <!-- <img src="../../../../public/dashboard-assets/images/M7/Actions.png" width="25" class="cursor-pointer ms-2"
              alt="" data-bs-toggle="tooltip" data-bs-placement="top"
              title="Monitor the utilization of assessments from inception to current status, evaluate usage within selected timeframes, and oversee the remaining token a location for efficient resource management." /> -->
          </h3>
          <div class="row d-flex justify-content-center mt-5" :class="PlanMapping ? '' : 'disable-text-selection'"
            :style="{ filter: PlanMapping ? '' : 'blur(8px)' }">
            <div class="col-12">
              <!-- <h4 style="text-align: center; font-size: 18px; font-weight: 600;">Assessment Usage</h4> -->
              <div class="table-responsive">
                <table class="table text-center assessment-usage-table table-hover">
                  <thead>
                    <tr>
                      <th>Assessment Information</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in tableData" :key="index">
                      <td>{{ item.name }}</td>
                      <td>{{ item.count }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <div class="col-12 col-lg-6 col-xl-6 col-md-6 col-sm-4 d-flex justify-content-center">
              <div v-if="this.pieStatus">
                <div ref="pieChartContainer" class="chart-container"></div>
              </div>
              <div v-else>
                <h3>No data found for selected parameters</h3>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div v-if="!PlanMapping"
        style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 4;padding: 20px; text-align: center;">
        <div class="mb-5">
          <i class="fa-solid fa-lock fa-3x" style="color: #5e45eb;"></i>
        </div>
        <div class="mb-3" style="font-weight:600;">Upgrade your plan to Grow or higher.</div>
        <button class="btn btn-primary" style="background-color: #5e45eb;" @click="upgradePlan()">Upgrade Plan</button>
      </div>

    </div>


  </div>


</template>

<script>
// import Highcharts from 'highcharts';
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import moment from "moment";
export default {
  name: 'PieChart',
  props: {
    table: Array,
    // pie: Array,
    from: String,
    to: String
  },
  data() {
    return {
      allocationData: [],
      tableData: [],
      // pieData: [],
      durationTokens: 0,
      pieDataTable: [],
      pieStatus: false,
      billing: JSON.parse(localStorage.getItem('billingData')),
      PlanMapping: true,
    };
  },
  computed: {
    displayDate() {
      return moment(String(this.from)).format("DD MMM YYYY") + " - " + moment(String(this.to)).format("DD MMM YYYY");
    }
  },
  watch: {
    from() {
      this.updateTableData();
    },
    to() {
      this.updateTableData();
    },
    table() {
      this.updateTableData();
    },
    // pie() {
    //   this.updatePieData();
    // }
  },
  created() {
    if (this.billing != null) {
      if (this.billing.object.data.plan.planName == 'Free Trial' || this.billing.object.data.plan.planName == 'Startup') {
        this.PlanMapping = false;
      }
    }


  },
  methods: {
    updateTableData() {

      this.tableData = [];
      this.allocationData = this.table;
      this.allocationData.forEach((element) => {
        this.tableData.push({ name: 'Tokens used between ' + this.displayDate, count: element.data.consumedBetweenDate });
        this.tableData.push({ name: 'Tokens used between since last year', count: element.data.used });
        this.tableData.push({ name: 'Balance Tokens', count: element.data.remaining });
        this.durationTokens = element.data.consumedBetweenDate;
        this.pieStatus = this.durationTokens > 0

      });
    },

    upgradePlan() {
      window.$("#UpgradePlanModal").modal("show");

    },
    // updatePieData() {
    //   this.pieDataTable = this.pie.map(item => ({
    //     name: item.name,
    //     y: item.assessmentCount
    //   }));
    //   const piechartConfig = {
    //     chart: {
    //       type: 'pie'
    //     },
    //     title: {
    //       text: 'Tokens Usage Till Date (%)',
    //       style: {
    //         fontWeight: '600'
    //       }
    //     },
    //     series: [{
    //       name: 'Candidates(N)',
    //       data: this.pieDataTable
    //     }],
    //     tooltip: {
    //       pointFormat: '<span style="font-family: DM Sans, sans-serif">{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b></span>'
    //     },
    //     plotOptions: {
    //       pie: {
    //         colors: ['#7C68EE', '#F8B200', '#4B2EEE', '#FFC801', '#5E45EB', '#F5C101', '#A293F4', '#FEDE69', '#CEC6F8', '#FFECA6', '#EDEAFF', '#FF9900', '#6A8BF3', '#669BFA', '#9FCDFF', '#CDE5FF', '#1E3D58'],
    //         // showInLegend: true,
    //       }
    //     },
    //     legend: {
    //       align: 'center',
    //       verticalAlign: 'bottom',
    //       layout: 'horizontal',
    //       x: 0,
    //       y: 0,
    //     },
    //     credits: {
    //       enabled: false
    //     }
    //   };

    //   const pieChartContainer = this.$refs.pieChartContainer;
    //   Highcharts.chart(pieChartContainer, piechartConfig);
    // },
  },
  async mounted() {

    await this.updateTableData();
    // await this.updatePieData();


    Array.from(
      document.querySelectorAll('img[data-bs-toggle="tooltip"]')
    ).forEach((tooltipNode) => new Tooltip(tooltipNode));

  }
};
</script>

<style scoped>
.assessment-usage-table {
  border-collapse: collapse;
  width: 100%;
}

.assessment-usage-table th,
.assessment-usage-table td {
  border: 3px solid #F2F0FF;
  padding: 8px;
}

.assessment-usage-table th {
  background-color: #F2F0FF;
}

.chart-container {
  width: 400px;
}

#pieChartContainer {
  margin: 0 auto;
}

.disable-text-selection {
  user-select: none;
}
</style>
