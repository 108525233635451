<template>
    <div class="page-content">
        <h3>
            <span v-if="this.currentCompany != ''">
                <router-link :to="{
                    name: 'ManagementPartners',
                    query: {
                        offset: this.$route.query.offset,
                        current: this.$route.query.current,
                        search: this.$route.query.search
                    },
                }" class="p-0" type="button">
                    <img src="../../../public/dashboard-assets/images/M2/BackButton.png" />
                </router-link>
                <!-- <img src="../../../public/dashboard-assets/images/M2/BackButton.png" /> -->
            </span>
            {{ this.currentCompany != '' ? this.currentCompany + '`s Billing' : 'Billing' }}
        </h3>
        <div class="row mt-4">
            <div class="col-12 col-lg-6 col-xl-6">
                <div class="card top-cards">
                    <span class="mx-3 mt-3" style="font-weight: 600;">
                        Current Plan Summary
                        <span class="float-end">
                            <router-link :to="{ name: 'BillingPlan', }" v-if="this.user.isEnterprise != true">
                                <button class="btn btn-primary"
                                    style="min-width: 120px; width: 100%; font-size: 15px;">Upgrade</button>
                            </router-link>
                            <!-- <span class="View-Candidates-clock" style="color:#ff9900; font-size: 13px;" v-else>Coming
                                Soon</span> -->

                        </span>
                    </span>
                    <!-- <div :class="this.user.isEnterprise == true ? 'disable-text-selection' : ''" class="mx-3 mt-4" :style="this.user.isEnterprise == true ? 'filter: blur(4px)' : ''" > -->
                    <div class="mx-3 mt-4">
                        <span class="mx-3 mt-4">
                            <div class="row">
                                <div class="col-lg-5 col-xl-5 col-md-4 col-sm-3 col-xs-3">
                                    <div style="font-size: 14px; color: #808080;">Plan Name</div>
                                    <div style="font-size: large;">{{ planName != null ? planName : 'N.A' }}</div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-md-3 col-sm-3 col-xs-3">
                                    <div style="font-size: 14px; color: #808080;">Billing Cycle</div>
                                    <div style="font-size: large;">{{ type != null ? type : 'N.A' }}</div>
                                </div>
                                <div class=" col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-3">
                                    <div style="font-size: 14px; color: #808080; ">Plan Cost</div>
                                    <div style="font-size: large;" v-if="this.price != 0">{{ this.CurrenyCode }}{{
                                        this.price }}</div>
                                    <div style="font-size: large;" v-else>N.A</div>

                                </div>
                            </div>
                        </span>
                        <span class="mx-3 mt-4">
                            <div style="font-size: 14px; color: #808080;">Usage</div>
                            <div style="font-size: large;"> <b>{{ this.usedTokens }}</b> out of <b>{{ this.totalTokens
                                    }}</b> tokens used</div>
                            <div class="progress custom-progress my-3 mb-3">
                                <div class="progress-bar" role="progressbar" :style="{ width: progressBarWidth }">
                                    {{ this.percentageData }}%</div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6">
                <div class="card top-cards">
                    <span class="mx-3 mt-3" style="font-weight: 600;">
                        Active Addons
                        <!-- <span class="float-end">
                            <router-link :to="{ name: 'BillingPlan', }" v-if="this.user.isEnterprise != true">
                                <button class="btn btn-primary"
                                    style="min-width: 120px; width: 100%; font-size: 15px;">Purchase History</button>
                            </router-link>
                        </span> -->
                    </span>
                    <div class="mx-3 mt-4" v-if="this.addons.length > 0">
                        <span class="mx-3 mt-4">
                            <div class="row" v-for="(data, index) in this.addons" :key="index">
                                <div class="col-lg-5 col-xl-5 col-md-4 col-sm-3 col-xs-3">
                                    <div style="font-size: 14px; color: #808080;">Addon Name</div>
                                    <div style="font-size: large;">{{ data.name }}</div>
                                </div>
                                <div class=" col-lg-3 col-xl-3 col-md-3 col-sm-3 col-xs-3">
                                    <div style="font-size: 14px; color: #808080; ">Active</div>
                                    <div style="font-size: large;">{{ data.consumed }} Out of {{ data.maxLimit }} </div>
                                </div>
                                <div class="col-lg-4 col-xl-4 col-md-3 col-sm-3 col-xs-3">
                                    <div style="font-size: 14px; color: #808080;">&nbsp;</div>
                                    <div style="font-size: large;">
                                        <button class="btn btn-link" @click="modifyAddons(data)">Modify</button>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div v-else class="Partner_empty_content_hp m-auto text-center">
                        <div class="mb-4">
                            <h4> {{ !FreePlan ? (user.isEnterprise ? 'No associated addon found for Enterprise plans.'
                                : 'No associated addon found.') :
                                'Upgrade your plan to startup or higher for activating Add-ons' }} </h4>
                            <br />
                            <router-link v-if="PlanMapping"
                                :to="{ name: 'AddonPurchase', query: { page: encode('billing-details') } }"
                                class="btn active btn-outline-primary btn-icon-text mx-2">
                                <i class="fa-solid fa-cart-shopping me-1"></i>
                                Get Addons
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6" style="display: none;">
                <div class="card top-cards">
                    <span class="mx-3 mt-3" style="font-weight: 600;">
                        Payment Method
                        <span class="float-end">
                            <span class="View-Candidates-clock" style="color:#ff9900; font-size: 13px;">Coming
                                Soon</span>
                        </span>
                    </span>

                    <div class="mt-5 mx-4 disable-text-selection" style="font-size: small; filter: blur(4px);">
                        Partner and Group name
                    </div>
                    <div class="card mt-2 mx-4 disable-text-selection"
                        style="min-height: 80px; border-style: solid; border-color: #d2d4d2; filter: blur(8px);">
                        <div class="row mt-2 mx-2">
                            <div class="col-12 col-lg-2 col-md-2 col-sm-2 col-xl-2 col-xs-2 mb-3">
                                <img src="../../../public/dashboard-assets/images/M2/mastercard.png" alt=""
                                    style="width: 60px;" />
                            </div>
                            <div class="col-12 col-lg-8 col-md-8 col-sm-8 col-xl-8 col-xs-8 mb-3">
                                <div class="mt-2"> **** **** **** 1234</div>
                                <div class="text-muted"> Expiry on 20/2024</div>
                            </div>
                            <div class="col-12 col-lg-2 col-md-2 col-sm-2 col-xl-2 col-xs-2 mb-3">
                                <button class="btn btn-primary mt-2" style="font-size:15px;">Change</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="Partner_empty_img_hp m-auto text-center">
                        <img src="../../../public/dashboard-assets/images/M2/New.png" alt="" style="width: 200px;" />
                    </div>
                    <div class="Partner_empty_content_hp m-auto text-center">
                        <div class="mb-4 mt-4">
                            <h4>Payment Details has not been updated yet</h4>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- <div class="card plan-cost-distribution-card mt-4">
            <span class="mx-3 mt-3">
                <b>Plan Cost Distribution</b>
                <span class="float-end" style="color: #7B68EE;">
                    <i class="fa-solid fa-phone fa-sm mx-1"> </i>
                    Having Queries
                </span>
            </span>
            <span class="mx-3 mt-4">
                <div class="card" style="border-radius: 15px;">
                    <div class="table-responsive" style="border-radius: 15px; height:20px!important;">
                        <table class="table text-center table-hover">
                            <thead style="background-color: #F1F0FF !important;">
                                <tr>
                                    <th style="width: 50%;">Item</th>
                                    <th style="width: 25%;">Number of Tokens</th>
                                    <th style="width: 25%;">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> Scale Plan</td>
                                    <td> 30</td>
                                    <td> $20</td>
                                </tr>
                                <tr>
                                    <td> User Addon</td>
                                    <td> 25</td>
                                    <td> $18</td>
                                </tr>
                                <tr>
                                    <td> </td>
                                    <td> Net Total</td>
                                    <td> $38</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </span>
        </div> -->
        <div class="card billing-info-card mt-4" v-for="data in billingPreferenceData.slice(0, 1)" :key="data">
            <span class="mx-3 mt-3">
                <b>Billing Info</b>
                <span class="float-end">
                    <button class="btn btn-primary" style="min-width: 100px; width: 100%; font-size: 15px;"
                        @click="openEditBillingInfoModal(data)">
                        <i class="fa-solid fa-pen-to-square mx-1" style="color: #FFFFFF;"></i>
                        Edit</button>
                </span>
            </span>
            <span class="mx-3 mt-4">
                <div class="row">
                    <div class="col-12 col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xs-3 mb-3">
                        <div style="font-size: 12px; color: #808080;">Registered Company Name</div>
                        <div style="font-size: 16px; ">{{ data.companyName != null ? data.companyName : 'N.A' }}</div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xs-3 mb-3">
                        <div style="font-size: 12px; color: #808080;">Email Address</div>
                        <div style="font-size: 16px; ">{{ data.email != null ? data.email : 'N.A' }}</div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xs-3 mb-3">
                        <div style="font-size: 12px; color: #808080;">Concerned Person Name</div>
                        <div style="font-size: 16px;">{{ data.attentionName != null ? data.attentionName : 'N.A' }}
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xs-3 mb-3"
                        v-if="this.user.countryCode == 'IN'">
                        <div style="font-size: 12px; color: #808080;">GST Number</div>
                        <div style="font-size: 16px;"> {{ data.gstNo != null ? data.gstNo : 'N.A' }}</div>
                    </div>

                    <!-- <div class="col-12 col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xs-3 mb-3">
                        <div style="font-size: 12px; color: #808080;">Customer Id</div>
                        <div style="font-size: 16px;"> 1085154357</div>
                    </div> -->
                </div>
            </span>
            <span class="mx-3 mt-4">
                <div class="row">
                    <!-- <div class="col-12 col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xs-3 mb-3">
                        <div style="font-size: 12px; color: #808080;">Concerned Person Name</div>
                        <div style="font-size: 16px;">{{ PreferAttentionName != null ? PreferAttentionName : 'N.A' }}
                        </div>
                    </div> -->
                    <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 col-xs-12 mb-3">
                        <div style="font-size: 12px; color: #808080;">CC Emails</div>
                        <div style="font-size: 12px;">
                            <div class="demo-preview" style="cursor: default;" v-if="data.ccEmails.length > 0">
                                <div class="badge mb-2 mt-1" v-for="item in data.ccEmails" :key="item">
                                    {{ item }}
                                </div>
                            </div>
                            <div v-else>
                                N.A
                            </div>
                        </div>
                    </div>
                </div>
            </span>
            <span class="mx-3 mt-4 mb-4">
                <div class="row">
                    <div class="col-12 col-lg-3 col-md-3 col-sm-3 col-xl-3 col-xs-3 mb-3">
                        <div style="font-size: 12px; color: #808080;">ZIP</div>
                        <div style="font-size: 16px;">{{ data.zip != null ? data.zip : 'N.A' }} </div>
                    </div>
                    <div class="col-12 col-lg-9 col-md-9 col-sm-9 col-xl-9 col-xs-9 mb-3">
                        <div style="font-size: 12px; color: #808080;">Address</div>
                        <div style="font-size: 16px;">
                            {{ data.street }}, {{ data.city }} , {{ data.state }} ,
                            {{ data.country }}
                        </div>
                    </div>
                </div>
            </span>
        </div>

        <div class="card plan-cost-distribution-card mt-4">
            <span class="mx-3 mt-3">
                <b>Invoices</b>
                <!-- <span class="float-end" style="color: #7B68EE;">
                    <i class="fa-solid fa-phone fa-sm mx-1"> </i>
                    Having Queries
                </span> -->
            </span>
            <span class="mx-3 my-4">
                <div class="card p-0" style="border-radius: 15px;" v-if="invoiceShow == true">
                    <div class="table-responsive" style="border-radius: 15px;">
                        <table class="table text-center">
                            <thead style="background-color: #F1F0FF !important;">
                                <tr>
                                    <th>Invoice Id</th>
                                    <th>Issued Date</th>
                                    <th>Due Date</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                    <th>Balance Due</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <!-- <tbody v-for="item in invoiceData.filter(invoice => invoice.status.toLowerCase() !== 'draft')" :key="item.invoice_id">
    <tr>
        <td>{{ item.invoice_number }}</td>
        <td>{{ dateConvert(item.invoice_date) }}</td>
        <td>{{ dateConvert(item.due_date) }}</td>
        <td>
            <span class="View-Candidates-xmark" style="color: red;"
                v-if="this.invoiceStatusLowerCase(item.status) == 'overdue'">
                <i class="fa-solid fa-clock-rotate-left me-2"></i>Overdue by
                {{ CheckStatus(item.due_date) }} days</span>
            <span class="View-Candidates-clock" style="color:#ff9900"
                v-else-if="this.invoiceStatusLowerCase(item.status) == 'pending'">
                <i class="fa-solid fa-clock-rotate-left me-2"></i>Pending</span>
            <span class="View-Candidates-pass" style="color:#048848"
                v-else-if="this.invoiceStatusLowerCase(item.status) == 'paid'">
                <i class="fa-solid fa-check me-2"></i>Paid</span>
            <span class="View-Candidates-pass" style="color:#ff9900"
                v-else-if="this.invoiceStatusLowerCase(item.status) == 'partiallypaid'">
                <i class="fa-solid fa-check me-2"></i>Partially Paid</span>
            <span class="View-Candidates-xmark" style="color:#ff9900"
                v-else-if="this.invoiceStatusLowerCase(item.status) == 'unpaid'">
                <i class="fa-solid fa-clock-rotate-left me-2"></i>Unpaid</span>
            <span class="View-Candidates-pass" style="color:#048848"
                v-else-if="this.invoiceStatusLowerCase(item.status) == 'sent'">
                <i class="fa-solid fa-check me-2"></i>Sent</span> 
            <span class="View-Candidates-xmark" style="color:#ff9900"
                v-else-if="this.invoiceStatusLowerCase(item.status) == 'void'">
                <i class="fa-solid fa-check me-2"></i>Void</span>      
        </td>
        <td>{{ item.total }}</td>
        <td>{{ item.balance }}</td>
        <td style=" cursor: pointer;"
            @click="downloadInvoice(item.invoice_id, item.invoice_number)"  v-if="checkDownloadStatus(item.status)">
            <i class="fa-solid fa-download "></i>
        </td>
    </tr>
</tbody> -->

                            <tbody v-for="item in invoiceData" :key="item">
                                <tr>
                                    <td>{{ item.invoice_number }}</td>
                                    <td>{{ dateConvert(item.invoice_date) }}</td>
                                    <td>{{ dateConvert(item.due_date) }}</td>
                                    <td>
                                        <span class="View-Candidates-xmark" style="color: red;"
                                            v-if="this.invoiceStatusLowerCase(item.status) == 'overdue'">
                                            <i class="fa-solid fa-clock-rotate-left me-2"></i>Overdue by
                                            {{ CheckStatus(item.due_date) }} days</span>
                                        <span class="View-Candidates-clock" style="color:#ff9900"
                                            v-else-if="this.invoiceStatusLowerCase(item.status) == 'pending'">
                                            <i class="fa-solid fa-clock-rotate-left me-2"></i>Pending</span>
                                        <span class="View-Candidates-clock" style="color:#ff9900"
                                            v-else-if="this.invoiceStatusLowerCase(item.status) == 'draft'">
                                            <i class="fa-regular fa-note-sticky me-2"></i>Draft</span>
                                        <span class="View-Candidates-pass" style="color:#048848"
                                            v-else-if="this.invoiceStatusLowerCase(item.status) == 'paid'">
                                            <i class="fa-solid fa-check me-2"></i>Paid</span>
                                        <span class="View-Candidates-pass" style="color:#ff9900"
                                            v-else-if="this.invoiceStatusLowerCase(item.status) == 'partiallypaid'">
                                            <i class="fa-solid fa-check me-2"></i>Partially Paid</span>
                                        <span class="View-Candidates-xmark" style="color:#ff9900"
                                            v-else-if="this.invoiceStatusLowerCase(item.status) == 'unpaid'">
                                            <i class="fa-solid fa-clock-rotate-left me-2"></i>Unpaid</span>
                                        <span class="View-Candidates-pass" style="color:#048848"
                                            v-else-if="this.invoiceStatusLowerCase(item.status) == 'sent'">
                                            <i class="fa-solid fa-check me-2"></i>Sent</span>
                                        <span class="View-Candidates-xmark" style="color:#ff9900"
                                            v-else-if="this.invoiceStatusLowerCase(item.status) == 'void'">
                                            <i class="fa-solid fa-check me-2"></i>Void</span>

                                    </td>
                                    <td>{{ item.total }}</td>
                                    <td>{{ item.balance }}</td>
                                    <td style=" cursor: pointer;"
                                        @click="downloadInvoice(item.invoice_id, item.invoice_number)"
                                        v-if="checkDownloadStatus(item.status)">
                                        <i class="fa-solid fa-download "></i>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                </div>

                <div class="Partner_empty_main_section_hp mt-3" v-else>
                    <div class="row">
                        <div class="Partner_empty_img_hp m-auto text-center">
                            <img src="../../../public/dashboard-assets/images/M2/Partner_blank.png" alt="" />
                        </div>
                        <div class="Partner_empty_content_hp m-auto text-center">
                            <div class="mb-4">
                                <h4>For your account invoice has not been generated yet</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </span>
        </div>
        <div class="modal fade CreatePartnerModal_hp" id="EditBillingInfoModal" tabindex="-1" data-bs-backdrop="static"
            data-bs-keyboard="false" aria-labelledby="EditBillingInfoModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title text-centerm-auto" id="EditBillingInfoModalLabel">
                            <!-- UserActive -->
                            Billing Info
                        </h4>
                        <button type="button" class="btn-close" id="close" data-bs-dismiss="modal"
                            aria-label="btn-close" @click="ResetForm"></button>
                    </div>
                    <div class="modal-body mt-4">
                        <div class="row" id="CreateForm">
                            <div class="form-group mb-4">
                                <label class="input-hero" for="FirstName">
                                    <input class="input_area" v-model="this.PreferCompanyName" type="text" required=""
                                        @keydown="filterCompanyName" /><span class="input-name">Registered Company
                                        Name</span>
                                </label>
                            </div>
                            <!--   <option
                      v-for="item in GetLocationData.object.data"
                      :key="item"
                      :value="item.iso2"
                    >
                      {{ item.name }}
                    </option> -->
                            <div class="form-group mb-4">
                                <label class="input-hero" for="Category">
                                    <select class="form-select input_area select_ca" v-model="this.PreferEmail"
                                        @change="this.findConcernedPerson()">
                                        <option disabled selected>Select Preferred Email Address</option>
                                        <option v-for="users in allUsers" :key="users">
                                            {{ users.email }}
                                        </option>
                                    </select>
                                    <span class="input-name">Email Address</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="FirstName">
                                    <input class="input_area" v-model="this.PreferAttentionName" type="text" required=""
                                        disabled /><span class="input-name"> Concerned Person Name</span>
                                </label>
                            </div>
                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <div class="tag-input">
                                        <div v-for="(tag, index) in ccEmails" :key="index" class="tag"
                                            @click="removeTag(index)">
                                            {{ tag }}
                                            <span>x</span>
                                        </div>
                                        <input v-model="tagInput" @keydown.enter.prevent="addTag"
                                            placeholder="Add email">
                                    </div>
                                    <span class="input-name">CC Emails</span>
                                </label>
                                <span style="color: #7b68ee;"><i class="fa-solid fa-circle-info fa-sm "></i><span
                                        class="mx-2">Click Enter after adding CC Email</span></span>
                            </div>

                            <span class="mx-1 mb-3" style="font-weight: 600;">Address</span>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferStreet" type="text" required=""
                                        @keydown="filterSpecialChars" /><span class="input-name">Street</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferCity" type="text" required=""
                                        @keydown="filterAlphabetOnly" /><span class="input-name">City</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferState" type="text" required=""
                                        @keydown="filterAlphabetOnly" /><span class="input-name">State</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferCountry" type="text" required=""
                                        @keydown="filterAlphabetOnly" /><span class="input-name">Country</span>
                                </label>
                            </div>

                            <div class="form-group mb-4">
                                <label class="input-hero" for="LastName">
                                    <input class="input_area" v-model="this.PreferZip" type="text" required=""
                                        @keydown="filterNumbersOnly" /><span class="input-name">ZIP</span>
                                </label>
                            </div>

                            <div v-if="this.user.countryCode == 'IN'">
                                <span class="mx-1 mb-3" style="font-weight: 600;">Additional Details</span>

                                <div class="form-group mb-4 mt-3">
                                    <label class="input-hero" for="LastName">
                                        <input class="input_area" v-model="this.PreferGstNo" type="text" required=""
                                            @input="validateGstNo" maxlength="15" @keydown="filterSpecialChars" /><span
                                            class="input-name">GST Number</span>
                                    </label>
                                    <div v-if="gstNoError" style="font-size: 14px; color: red;">{{ gstNoError }}</div>
                                </div>
                                <div class="form-group mb-4">
                                    <label class="input-hero" for="Category">
                                        <select class="form-select input_area select_ca" v-model="this.placeOfSupply">
                                            <option disabled selected>Select Preferred State</option>
                                            <option v-for="states in this.GetStateData.object.data" :key="states"
                                                :value="states.iso2">
                                                {{ states.name }}
                                            </option>
                                        </select>
                                        <span class="input-name">Place of Supply</span>
                                        <!-- <span class="text-danger">*</span> -->
                                    </label>
                                </div>
                            </div>
                            <div class="">
                                <button class="btn btn-primary btn-icon-text float-end"
                                    @click="UpdateBillingPreference()" data-bs-dismiss="modal"
                                    :disabled="!isGstNoValid && PreferGstNo !== ''">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="UpdateAddon" tabindex="-1" aria-labelledby="UpdateAddonModalLabel" aria-hidden="true"
        data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm" data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div class="modal-content border-radius-kj-20">
                <div class="modal-header token-model-header-color">
                    <h5 class="modal-title text-centerm-auto"> Modify {{ this.addonName }} Addon </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                        @click="clearbtn()"></button>
                </div>
                <div class="modal-body">
                    <h5 class="my-4 request-color-token">
                        Your active addon items are {{ this.addonActiveCount }}. How do you want proceed?
                        <!-- <strong class="text-dark"> {{ this.addonActiveCount }}. </strong> -->
                    </h5>
                    <h5 class="my-4 request-color-token" v-if="this.addonModifyQty == 0"> Setting the quantity to 0 will
                        remove the addon pack. </h5>
                    <form class="row my-3" method="">
                        <div class="tokenPrice token-model-kj d-flex">
                            <div class="value-button-min" id="decrease" value="Decrease Value"
                                :disabled="decDisableModifyButton()"
                                :class="{ active_inc_dec_button: decDisableModifyButton() }"
                                @click="decreaseAddonQty()">
                                <i class="fa-solid fa-minus"></i>
                            </div>
                            <input type="number" class="w-75 input-number-token-kj" id="number"
                                v-model='this.addonModifyQty' oninput="validity.valid||(value='');" min="0"
                                :max="this.addonMaxLimit" />
                            <div class="value-button-max" id="increase" value="Increase Value"
                                :disabled="incDisableModifyButton()"
                                :class="{ active_inc_dec_button: incDisableModifyButton() }"
                                @click="increaseAddonQty()">
                                <i class="fa-solid fa-plus"></i>
                            </div>
                        </div>
                        <h5 class="my-3 request-color-token">
                            Addon Available: {{ this.addonMaxLimit }}
                        </h5>
                        <div class="text-end mt-2">
                            <button type="button" class="btn btn-token-color-kj"
                                :class="{ actived_form_button: disableAddonQty() }" :disabled="!disableAddonQty()"
                                @click="modifyAddonQty()">
                                Modify
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// import { calculateRemainingDays } from '../../platformFeatures/dateUtils';
// import { getEncryptedData } from '../../platformFeatures/featureUtils';
import moment from "moment";
import { SetClarityData } from "../../platformFeatures/featureUtils";

export default {
    name: "Details",
    components() {

    },
    data() {
        return {
            PlanFeatures: [],
            PlanStatus: false,
            FreePlan: false,
            totalTokens: 0,
            usedTokens: 0,
            CurrenyCode: '',
            planName: '',
            price: 0,
            type: '',
            percentageData: 0,
            invoiceData: [],
            PreferCompanyName: '',
            PreferEmail: '',
            PreferGstNo: '',
            PreferAttentionName: '',
            PreferZip: '',
            PreferStreet: '',
            PreferCity: '',
            PreferState: '',
            PreferCountry: '',
            PreferenceId: 0,
            placeOfSupply: '',
            ccEmails: [],
            allUsers: [],
            invoiceShow: false,
            tagInput: '',
            uniqueEmails: new Set(),
            billingPreferenceData: [],
            GetStateData: {
                object: {
                    data: [],
                },
            },
            gstNoError: '',
            isGstNoValid: true,
            currentclientId: 0,
            currentCompany: '',
            addons: [],
            addonName: '',
            addonCode: '',
            addonType: '',
            addonDescription: '',
            addonPrice: 0,
            addonActiveCount: 0,
            addonMaxLimit: 0,
            addonModifyQty: 0,
            billing: JSON.parse(localStorage.getItem('billingData')),
            PlanMapping: true,


        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
        progressBarWidth() {
            return `${this.percentageData}%`;
        },
    },
    async created() {

        if (this.billing != null) {
            if (this.billing.object.data.plan.planName == 'Free Trial') {
                this.PlanMapping = false;
                this.FreePlan = true;
            }
        }
        else if (this.user.isEnterprise == true) {
            this.PlanMapping = false;
        }

        if (this.$route.query.id != undefined) {
            this.currentclientId = window.atob(this.$route.query.id);
            this.currentCompany = window.atob(this.$route.query.name);
        }
        else {
            this.currentclientId = this.user.clientId;
        }

        var data = await this.GetBillingPagePlanDetails({
            // subscriptionId: this.billing.object.data.subscription.subScriptionId\
            clientId: this.currentclientId
        });
        this.totalTokens = data.object.data.allocatedTokens;
        this.usedTokens = data.object.data.consumedTokens;
        this.planName = data.object.data.planName;
        this.price = data.object.data.price;
        this.type = data.object.data.type;
        var code = data.object.data.currencyCode;
        this.percentageData = this.CalculatePercentage(this.usedTokens, this.totalTokens);
        this.CurrenyCode = this.fetchCurrency(code);
        // await this.GetBillingPageCardDetails(
        //     {
        //         clientId: this.currentclientId
        //     }
        // );
        this.GetBillingPreferenceData();
        this.getAllUserDetails();
        this.GetInvoice();
        let addonDetails = await this.GetClientAddons();
        let addons = addonDetails.object.data.length > 0 ? addonDetails.object.data : [];
        if (addons.length > 0) {
            this.addons = addons.filter(p => p.consumed > 0);
        }
    },
    mounted() {
        SetClarityData();
    },
    methods: {
        ...mapMutations(["setLoading"]),
        ...mapActions([
            'GetBillingPagePlanDetails',
            'GetBillingPageCardDetails',
            'GetEnterpriseInvoiceData',
            'GetInvoiceData',
            'GetBillingPreference',
            'GetAllUsers',
            'putBillingPreferenceData',
            'DownloadInvoice',
            'ShowWarningMessage',
            'getstatelocation',
            'GetClientAddons',
            'ModifyClientAddons',
            'postPurchaseAddon'
        ]),
        dateConvert: function (value) {
            return moment(String(value)).format("DD/MM/YYYY");
        },
        async ValidationModal() {
            window.$('#ValidationModal').modal('show');

        },
        findConcernedPerson() {
            const selectedUser = this.allUsers.find(user => user.email === this.PreferEmail);
            this.PreferAttentionName = selectedUser.name;
        },
        addTag() {
            const emails = this.tagInput
                .split(/[\s,]+/)
                .filter(email => email.trim() !== '');

            for (const email of emails) {
                if (this.validateEmail(email)) {
                    if (!this.uniqueEmails.has(email)) {
                        this.ccEmails.push(email);
                        this.uniqueEmails.add(email);
                    }
                }
            }

            this.tagInput = '';
        },
        removeTag(index) {
            const emailToRemove = this.ccEmails[index];
            this.ccEmails.splice(index, 1);
            this.uniqueEmails.delete(emailToRemove);
        },
        validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
        },
        validateGstNo() {
            const regex = /^[a-zA-Z0-9]{15}$/;
            if (this.PreferGstNo.length === 15 && regex.test(this.PreferGstNo)) {
                this.gstNoError = '';
                this.isGstNoValid = true;
            } else {
                this.gstNoError = this.PreferGstNo ? 'GST Number must be exactly 15 characters long and contain no special characters.' : '';
                this.isGstNoValid = false;
            }
        },
        async GetInvoice() {
            var date = new Date();
            var endDate = moment(String(date)).add(1, 'days').format("MM/DD/YYYY");
            var startDate = moment(endDate, "MM/DD/YYYY").subtract(6, 'months').format("MM/DD/YYYY");

            var enterpriseInvoice = await this.GetEnterpriseInvoiceData({
                clientId: this.currentclientId,
                startDate: startDate,
                EndDate: endDate,
                // clientId: 2084,
                // startDate: "05-01-2024",
                // EndDate: "05-05-2024"
            });

            this.invoiceData = enterpriseInvoice != undefined ? enterpriseInvoice.object.data : [];
            if (this.user.clientId != 1001) {
                this.invoiceData = this.invoiceData.filter(invoice => invoice.status.toLowerCase() !== 'draft');
            }
            if (enterpriseInvoice != undefined && enterpriseInvoice.status && this.invoiceData.length > 0) {
                this.invoiceShow = true;
            }
        },
        async downloadInvoice(id, invoiceNumber) {
            await this.DownloadInvoice({
                invoiceId: id,
                invoiceNumber: invoiceNumber
            })
        },
        checkDownloadStatus(status) {
            const lowerStatus = this.invoiceStatusLowerCase(status);
            return lowerStatus !== 'draft' && lowerStatus !== 'void';
        },
        async emptyEditModal() {
            this.PreferCompanyName = '';
            this.PreferEmail = '';
            this.PreferGstNo = '';
            this.PreferAttentionName = '';
            this.PreferZip = '';
            this.PreferStreet = '';
            this.PreferCity = '';
            this.PreferState = '';
            this.PreferCountry = '';
            this.ccEmails = [];
            this.uniqueEmails = new Set(),
                this.PreferenceId = '';
            this.placeOfSupply = '';
            await this.GetBillingPreferenceData();
        },
        async openEditBillingInfoModal(data) {
            this.PreferenceId = data.preferenceId;
            this.PreferCompanyName = data.companyName;
            this.PreferEmail = data.email;
            this.PreferGstNo = data.gstNo;
            this.PreferAttentionName = data.attentionName;
            this.PreferZip = data.zip;
            this.PreferStreet = data.street;
            this.PreferCity = data.city;
            this.PreferState = data.state;
            this.PreferCountry = data.country;
            this.ccEmails = data.ccEmails;
            this.placeOfSupply = data.placeOfSupply;

            if (this.user.countryCode == "IN") {
                this.GetStateData = await this.getstatelocation({
                    countryCode: this.user.countryCode
                });
            }
            window.$("#EditBillingInfoModal").modal("show");
        },

        filterSpecialChars(event) {
            const key = event.key;
            const isValid = /^[a-zA-Z0-9 ]$/.test(key) || key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab';
            if (!isValid) {
                event.preventDefault();
            }
        },
        filterAlphabetOnly(event) {
            const key = event.key;
            const isValid = /^[a-zA-Z]$/.test(key) ||
                key === 'Backspace' ||
                key === 'Delete' ||
                key === 'ArrowLeft' ||
                key === 'ArrowRight' ||
                key === 'Tab';
            if (!isValid) {
                event.preventDefault();
            }
        },
        filterCompanyName(event) {
            const key = event.key;
            const isValid = /^[a-zA-Z0-9 &]$/.test(key) ||
                key === 'Backspace' ||
                key === 'Delete' ||
                key === 'ArrowLeft' ||
                key === 'ArrowRight' ||
                key === 'Tab';
            if (!isValid) {
                event.preventDefault();
            }
        },
        filterNumbersOnly(event) {
            const key = event.key;
            const isValid = /^[0-9]$/.test(key) ||
                key === 'Backspace' ||
                key === 'Delete' ||
                key === 'ArrowLeft' ||
                key === 'ArrowRight' ||
                key === 'Tab';
            if (!isValid) {
                event.preventDefault();
            }
        },
        invoiceStatusLowerCase(item) {
            return item.toLowerCase();
        },
        async UpdateBillingPreference() {
            await this.putBillingPreferenceData({
                clientId: this.currentclientId,
                // clientId: 2084,
                attentionName: this.PreferAttentionName,
                clientName: "string",
                companyName: this.PreferCompanyName,
                gstNo: this.PreferGstNo,
                email: this.PreferEmail,
                street: this.PreferStreet,
                city: this.PreferCity,
                state: this.PreferState,
                country: this.PreferCountry,
                zip: this.PreferZip,
                placeOfSupply: this.placeOfSupply,
                toEmails: [
                    this.PreferEmail
                ],
                ccEmails: this.ccEmails,
                preferenceId: this.PreferenceId

            });
            await this.emptyEditModal();
            window.$("#EditBillingInfoModal").modal("hide");
        },
        async ResetForm() {
            await this.emptyEditModal();
        },
        async GetBillingPreferenceData() {
            var preferdata = await this.GetBillingPreference({
                clientId: this.currentclientId
                // clientId: 2084
            });
            this.billingPreferenceData = preferdata.object.data;
        },

        CalculatePercentage(used, total) {
            var percentage = (used / total) * 100;
            return percentage;
        },
        CheckStatus(dueDate) {
            let currentDate = new Date();
            let due_date = new Date(dueDate);
            let diff = due_date.getTime() - currentDate.getTime();
            let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
            this.Remainingdays = Math.abs(days);
            return this.Remainingdays;
        },
        fetchCurrency(data) {
            if (data == 'INR') {
                return '₹';
            } else {
                return '$';
            }
        },
        async CancelSubscription() {
            await this.putSubscriptionCancel({
                subscriptionId: this.billing.object.data.subscription.subScriptionId
            });
            await this.RefreshBillingDetails();
            location.reload();
        },

        async getAllUserDetails() {

            var model = {
                status: 4,
                clientId: this.currentclientId,
                limit: 999,
                page: 1,

            };

            var users = await this.GetAllUsers(model);
            this.allUsers = users.object.data.users;

        },
        async reactiveSubscription() {
            await this.postReactivateSubscription({
                subscriptionId: this.billing.object.data.subscription.subScriptionId
            });
            await this.RefreshBillingDetails();
            location.reload();
        },
        modifyAddons(data) {
            this.addonName = data.name;
            this.addonCode = data.addonCode;
            this.addonType = data.addonType;
            this.addonDescription = data.description;
            this.addonPrice = data.addonPrice;
            this.addonActiveCount = data.consumed;
            this.addonMaxLimit = data.maxLimit - data.consumed;
            this.addonModifyQty = data.consumed;
            window.$('#UpdateAddon').modal('show');
        },
        encode(item) {
            return window.btoa(item);
        },
        incDisableModifyButton() {
            if (
                this.addonModifyQty == this.addonMaxLimit
            ) {
                return true;
            } else {
                return false;
            }
        },
        decDisableModifyButton() {
            if (
                this.addonModifyQty == null ||
                this.addonModifyQty == 0 ||
                this.addonModifyQty == "" ||
                this.addonModifyQty < 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        decreaseAddonQty() {
            if (
                this.addonModifyQty == null ||
                this.addonModifyQty == 0 ||
                this.addonModifyQty == "" ||
                this.addonModifyQty < 0
            ) {
                this.addonModifyQty = 0;
            } else {
                this.addonModifyQty = this.addonModifyQty - 1;
            }
        },
        increaseAddonQty() {
            if (
                this.addonModifyQty == null ||
                this.addonModifyQty == "" ||
                this.addonModifyQty == 0
            ) {
                this.addonModifyQty = 1;
            } else if (this.addonModifyQty <= this.addonMaxLimit) {
                this.addonModifyQty = this.addonMaxLimit;
            }
            else {
                this.addonModifyQty = this.addonModifyQty + 1;
            }
        },

        disableAddonQty() {
            return this.addonModifyQty >= 0 && this.addonModifyQty <= this.addonMaxLimit;
        },
        async modifyAddonQty() {
            if (this.addonModifyQty > this.addonMaxLimit) {
                this.ShowWarningMessage("Max limit reached.");
            }
            else {
                if (this.addonModifyQty >= this.addonActiveCount) {
                    const data = {
                        subscriptionId: this.billing.object.data.subscription.subScriptionId,
                        body: {
                            Addons: [{ "Type": this.addonType, "AddonCode": this.addonCode, "Quantity": this.addonModifyQty }]
                        }
                    };
                    const response = await this.postPurchaseAddon(data);
                    var url = await response.object.data.hostedpage.url;
                    window.open(url, '_self');
                }
                else {
                    let clientAddons = await this.ModifyClientAddons({
                        addonCode: this.addonCode,
                        qty: this.addonModifyQty
                    });
                    if (clientAddons.status) {
                        let addonDetails = await this.GetClientAddons();
                        let addons = addonDetails.object.data.length > 0 ? addonDetails.object.data : [];
                        if (addons.length > 0) {
                            this.addons = addons.filter(p => p.consumed > 0);
                        }
                        window.$('#UpdateAddon').modal('hide');
                    }
                }
            }
        }
    },
};
</script>

<style scoped>
.tag-input {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    width: 100%;

}

.tag {
    display: inline-block;
    background-color: #f0f0f0;
    padding: 2px 5px;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
}

.tag span {
    cursor: pointer;
    margin-left: 5px;
}

.tag-input input {
    border: none;
    outline: none;
    width: 250px;
}

.demo-preview .badge {
    margin-right: 10px;
}

/* .tag-input {
  border: 1px solid #ccc;
  padding: 5px;
  min-height: 30px;
  width: 100%;
} */

.tag {
    display: inline-block;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
}

.close {
    margin-left: 5px;
    cursor: pointer;
}

.badge {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 8px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #2d2d2d;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #f1f4f9;
}

.secondary {
    background-color: rgb(211, 211, 211);
    color: rgb(30, 30, 30);
}

.top-cards {
    border-radius: 15px;
    min-height: 200px;
    height: 100%;
}

.plan-cost-distribution-card {
    border-radius: 15px;
    min-height: 400px;

    /* height: 100%; */
}

.disable-text-selection {
    user-select: none;
}

.custom-progress {
    height: 20px;
    background-color: #E8E4FF;
    border-radius: 5px;
}

.custom-progress .progress-bar {
    background-color: #7B68EE;
}

.billing-info-card {
    border-radius: 15px;
    min-height: 250px;
}
</style>