<template>
  <section class="container-fluid login_page_section terms_popup_section">
    <div class="row align-items-center">
      <div class="col-md-6 tc_left_section">
        <div>
          <h2 class="text-center font-weight-700 mb-3">
            {{ username }}, are you ready?
          </h2>
          <img src="../assets/Images/T&C.png" alt="" class="img-fluid" width="500" />
        </div>
      </div>
      <div class="col-md-6 login_right_section">
        <div class="logo_section">
          <img src="../assets/Images/logo.png" alt="" width="120" />
        </div>
        <div style="clear: both"></div>

        <div class="row">
          <div class="col-12 col-lg-11 term_contant_main_kj">
            <h3 class="font-weight-600 mt-5">PMaps Privacy Policy</h3>
            <div class="term_contant_kj scrrol">
              <p class="font-size-15">
                Welcome to PMaps HR Consultants Pvt. Ltd’s Global Privacy Policy
                (“Privacy Policy“). For the purpose of this Privacy Policy,
                PMaps HR Consultants Pvt. Ltd is the data controller. We are
                committed to protecting your privacy. This Privacy Policy
                explains how we collect, use, disclose and protect your Personal
                Information.
              </p>
              <p class="font-size-15">
                In this Privacy Policy “we“, “us” and “our” refers to PMaps HR
                Consultants Pvt. Ltd.
              </p>
              <p class="font-size-15">
                By “ <strong>Personal Information</strong> ” (in some countries
                referred to as “personal data” or “personally identifiable
                information”) we mean any information relating to an identified
                or identifiable natural person. This is broader than just
                information of a personal or private nature, and also includes
                information such as your name, date of birth, and e-mail
                address.
              </p>
              <strong class="my-3">Quick Reference</strong>

              <ul>
                <li>Scope of our Privacy Policy</li>
                <li>Consent</li>
                <li>How do we obtain your Personal Information</li>
                <li>For which purposes we may use your Personal Information</li>
                <li>To whom we may disclose your Personal Information</li>
                <li>How we protect your Personal Information</li>
                <li>Your choices regarding direct marketing</li>
                <li>
                  How to access, correct or delete your Personal Information
                </li>
                <li>Children</li>
                <li>Transfers to other countries</li>
                <li>Retention of personal data or information</li>
                <li>Existence of automated decision-making</li>
                <li>Changes to this Privacy Policy</li>
                <li>Contact our Data Protection Officer (DPO)</li>
                <li>Name and Address of the data controller</li>
              </ul>

              <h4>1. Scope of our Privacy Policy</h4>

              <p>
                Our Privacy Policy applies to all Personal Information we
                collect, use, share or otherwise process through our activities,
                both online and offline, which display, link or refer to this
                Privacy Policy, including:
              </p>

              <ol>
                <li>our websites</li>
                <li>our official social media pages</li>
                <li>our e-mails</li>
                <li>our online and offline campaigns</li>
                <li>
                  your conversations or correspondence with our representatives.
                </li>
              </ol>

              <p>
                Throughout this document, we will refer to our online activities
                as “<strong>Sites</strong>” and to our offline activities as
                “<strong>Services</strong>”.
              </p>
              <p>
                You should be aware of the fact that our Sites may contain
                hyperlinks to third party websites, apps, etc. which are not
                controlled by us and which are not covered by this Privacy
                Policy. We have no responsibility or liability for the content
                and activities of these third party websites, apps, etc. As
                these third parties may collect your Personal Information, we
                suggest that you consult the privacy policies of these third
                parties to learn more about their use of your Personal
                Information.
              </p>
              <p>
                Our websites make use of cookies. For more information on this
                subject, please also consult our Cookie Policy.
              </p>

              <h4>2. Consent</h4>
              <p>
                Although in many cases we may ask for your explicit consent for
                processing your Personal Information for a specific purpose.
              </p>
              <p>
                Although in many cases we may ask for your explicit consent for
                processing your Personal Information for a specific purpose.
              </p>
              <p>
                If you wish to exercise the right to withdraw the consent, you
                may at any time directly contact our Data Protection Officer
                (details included as part of this policy).
              </p>
              <p>
                If you do not wish to provide your Personal Information to us,
                we may not be able to provide you with the products and services
                you have requested.
              </p>
              <h4>3. How do we obtain your Personal Information?</h4>

              <p>Personal Information obtained from you directly</p>
              <p>
                Firstly, we may obtain your Personal Information from you
                directly, e.g. when you sign up for our newsletters, register on
                our Sites, buy a product from us, fill out a survey, make a
                comment or enquiry, post something on our social media pages,
                etc.
              </p>
              <p>Personal Information collected this way may include:</p>
              <ol>
                <li>name</li>
                <li>address</li>
                <li>e-mail address</li>
                <li>user name</li>
                <li>telephone number</li>
                <li>age</li>
                <li>date of birth</li>
                <li>gender</li>
                <li>product purchase and usage information</li>
                <li>household information</li>
                <li>
                  user-generated content, posts and other content you submit to
                  our Sites
                </li>
                <li>
                  any other Personal Information you provide us with directly.
                </li>
              </ol>
              <p>Sensitive information collected may include:</p>
              <ol>
                <li>health related medical information</li>
                <li>
                  financial information such as bank account number or credit
                  card details
                </li>
                <li>racial and ethnic information</li>
              </ol>
              <p>Personal Information collected automatically</p>
              <p>
                Secondly, some Personal Information may be collected
                automatically when you visit or use our Sites, such as
                information collected by cookies and other technologies on our
                websites. Please also consult our Cookie Policy for more
                information on how we make use of cookies and other automated
                means of data collection.
              </p>
              <p>Personal Information collected this way may include:</p>

              <ol>
                <li>
                  information about the way you use our websites, such as
                  details of the web pages you have viewed, the banners and the
                  hyperlinks you have clicked, etc.
                </li>
                <li>whether you have opened e-mails sent by us to you</li>
                <li>
                  the websites you have visited before arriving at one of our
                  websites
                </li>
                <li>your IP address</li>
                <li>
                  information you choose to share by using social media tools
                  incorporated in our websites
                </li>
                <li>
                  if you have consented to this, your precise location data
                </li>
              </ol>
              <p>Personal Information obtained from other sources</p>
              <p>
                Lastly, we may also collect Personal Information from other
                sources, such organizations that we work with who are authorized
                by you (or otherwise entitled) to share your data with us.
              </p>
              <p>
                Personal Information collected this way may include income
                level, your interests such as hobbies and pets, consumer and
                market research data, purchase behavior, publicly observed data
                or activities such as blogs, videos, internet postings and user
                generated content.
              </p>
              <h4>
                4. For which purposes we may use your Personal Information
              </h4>
              <p>
                We may use your Personal Information for our business purposes
                such as
              </p>
              <ol>
                <li>
                  handling and managing your purchase, for instance to
                  communicate to you with respect to your purchase, to process
                  your payments, to deliver the purchased products and services,
                  and to offer you the necessary customer service;
                </li>
                <li>
                  allowing you to participate in promotions, contests, surveys
                  and polls;
                </li>
                <li>
                  allowing you to share information and ideas on our social
                  media pages, forums, blogs and other communication channels,
                  to participate in social sharing and to send messages to a
                  friend through our Sites or the websites of our trusted
                  business partners;
                </li>
                <li>
                  better understanding your needs in order to provide you with
                  more relevant information, analyzing and evaluating our
                  corporate activities, developing new products and improving
                  our existing products and services, conducting market research
                  and audits, identifying user trends and examining the degree
                  of effectiveness of our marketing campaigns and degree of
                  satisfaction of our customers;
                </li>
                <li>
                  with appropriate permission telling you about our products and
                  services, and sending you promotional materials (such as
                  announcements relating to new products, promotions, savings
                  programs, joint promotions and other programs) relating to our
                  brands and those of our carefully selected trusted business
                  partners;
                </li>
                <li>
                  managing our Sites, and tune the experience and content of our
                  Sites to your previous activities on our Sites, in view of
                  personalizing your experience when visiting our Sites;
                </li>
                <li>responding to your requests or enquiries;</li>
              </ol>
              <p>We do not sell your Personal Information.</p>
              <p>
                We may create anonymous data records from Personal Information
                by excluding information (such as your name) that make the data
                personally identifiable to you. We use this anonymous data to
                analyse our products and services. We reserve the right to use
                such anonymous data for any purpose and disclose anonymous data
                to third parties, including but not limited to our research
                partners, in our sole discretion.
              </p>
              <h4>5. To whom we may disclose your Personal Information</h4>
              <p>Third party service providers</p>
              <p>
                We may use third party service providers to provide services to
                achieve our business purpose, including marketing agencies,
                market research companies, e-commerce fulfilment partners,
                software providers and merchants of PMaps HR Consultants Pvt.
                Ltd products. These third party service providers are required
                not to use your Personal Information other than to provide the
                services requested by us.
              </p>
              <p>Acquisitions</p>
              <p>
                If another company acquires (part of) our company, business, or
                our assets, that company may acquire all or part of the Personal
                Information collected by us and will assume the rights and
                obligations regarding your Personal Information as described in
                this Privacy Policy. In the unlikely event of an insolvency,
                bankruptcy or receivership, your Personal Information may also
                be transferred as a business asset.
              </p>
              <p>Necessary disclosures</p>
              <p>
                We may disclose your Personal Information if we believe in good
                faith that such disclosure is necessary:
              </p>
              <ol>
                <li>in connection with any legal investigation;</li>
                <li>
                  to comply with relevant laws or to respond to court orders,
                  subpoenas or warrants served on us;
                </li>
                <li>to protect or defend our rights;</li>
                <li>
                  to investigate or assist in preventing any violation or
                  potential violation of the law, this Privacy Policy, or any
                  applicable Terms of Use.
                </li>
              </ol>
              <h4>6. How we protect your Personal Information</h4>
              <p>We value the trust you place in us.</p>
              <p>
                We have implemented appropriate technical and organizational
                measures to protect your Personal Information against accidental
                or unlawful destruction or accidental loss, alteration,
                unauthorised disclosure or access and against all other unlawful
                forms of processing.
              </p>
              <p>
                When collecting or transferring sensitive information we use a
                variety of additional security technologies and procedures to
                help protect your Personal Information.
              </p>
              <h4>7. Your choices regarding direct marketing</h4>
              <p>
                We may periodically send you free newsletters, surveys, offers,
                and other promotional materials related to our products and
                services which we believe are useful for you,
              </p>
              <p>
                If you do not wish to receive such communications anymore, you
                will always have the opportunity to “opt out” by following the
                unsubscribe instructions provided in each of our direct
                marketing e-mails or by contacting us directly (please see
                contact information below).
              </p>
              <p>
                Despite your indicated e-mail preferences, we may send you
                notices of any updates or other necessary service messages to
                our Data Protection Officer.
              </p>

              <h4>
                8. How to exercise your rights on your Personal Information
              </h4>
              <p>
                We strive to keep your Personal Information accurate and
                up-to-date.
              </p>
              <p>
                We provide you with reasonable access to your Personal
                Information and the possibility to review this, and request
                correction and deletion where appropriate.
              </p>
              <p>
                In order to protect your privacy, we will take reasonable steps
                to verify your identity before granting access to your Personal
                Information.
              </p>
              <p>
                The Personal Information we hold about you can be consulted and
                modified by following the instructions on the Site concerned or
                by contacting our Data Protection Officer (DPO). We will respond
                to your access request within a reasonable period of time, and
                in accordance with any terms specified by applicable law.
              </p>
              <p>
                We do not sell any personal information to external parties.
              </p>
              <h4>
                9. You have the right to not be discriminated against having
                exercised your rights. You also have the right to contact a
                supervisory body. Children
              </h4>
              <p>
                We request that children below the age of 18 do not provide
                Personal Information through our Sites.
              </p>
              <p>
                If you are the parent or legal guardian of a child under 18 who
                has registered on one of our Sites, or who you believe has
                otherwise provided Personal Information to us, please contact
                DPO to have the information deleted. Minors must obtain express
                consent from parents or legal guardians prior to accessing or
                providing any Personal Data.
              </p>
              <h4>10. Transfers to other countries</h4>
              <p>
                Your Personal Information may be transferred to and processed in
                other countries where laws governing the processing of Personal
                Information may be less stringent than the laws in your country.
              </p>
              <p>
                We have implemented strict procedures and controls in order to
                ensure that your Personal Information is protected adequately
                regardless of the country where it is stored or otherwise
                processed.
              </p>
              <p>
                By using our Sites and Services, you consent to your Personal
                Information being transferred and processed this way.
              </p>
              <h4>11. Retention of personal data or information</h4>
              <p>
                We will retain your personal data or information for as long as
                your account is active. We will retain and use your personal
                data or information as necessary to comply with our legal
                obligations, resolve disputes, and enforce our agreements.
              </p>
              <h4>12. Existence of automated decision-making</h4>
              <p>
                We do use automatic decision-making based on pre-set parameters
                for our test platform however this does not involve profiling.
              </p>
              <h4>13. Changes to this Privacy Policy</h4>
              <p>
                We may occasionally update this Privacy Policy. If we do, we
                will update the date at the top of this Privacy Policy. If we
                make significant changes to our Privacy Policy that materially
                alter our privacy practices, we may also provide you with prior
                notice of the pending update by other means, such as contacting
                you using the e-mail address you last provided us with, or
                posting a notice on our corporate website prior to the changes
                taking effect. Please always verify whether you have consulted
                the latest version of the Privacy Policy. Your continued use of
                our Sites and Services constitutes your agreement to this
                Privacy Policy and any updates.
              </p>
              <!-- <h4>14. Contact our Data Protection Officer (DPO)</h4>
              <p>
                We welcome your comments or questions regarding this Privacy
                Policy or our processing of your Personal Data or Information.
              </p>
              <p>
                You can reach out to us at any time at tkaur@pmaps.in. You can
                also contact our data protection officer at:
              </p>
              <p>Data Protection Officer</p>
              <p>PMaps HR Consultants Pvt. Ltd.</p>
              <p>201, Monica Building,</p>
              <p>Hariniwas Circle,</p>
              <p>Thane, Maharashtra (India)</p>
              <h4>
                15. Name and Address of the data controller / processor (if
                applicable)
              </h4>
              <p>PMaps HR Consultants Pvt. Ltd</p>
              <p>Phone: +91-9766133826</p>
              <p>Email: support@pmaps.in</p>
              <p>Website: https://pmapstest.com/</p> -->
              <form class="mt-5">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="check-in" @change="IsCheckedTandC($event)"
                    :checked="booleanValue" />
                  <label class="form-check-label font-size-16" for="check-in">
                    By proceeding, you confirm that you agree to our Terms of
                    service and Privacy Policy for PMaps
                  </label>
                </div>
                <div class="text-end mb-3">
                  <button type="button" id="submit-btn" v-bind:class="[
                    !IsTermsAndCondiChecked ? 'btn btn-accept' : 'btnactve',
                  ]" v-bind:disabled="!IsTermsAndCondiChecked" @click="submit">
                    Accept
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { SetClarityData } from "../platformFeatures/featureUtils";
export default {
  name: "TermsPop",
  data() {
    return {
      successMsg: "",
      IsTermsAndCondiChecked: false,
      username: "",
    };
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      userId: (state) => state.auth.user.userId,
    }),
  },
  mounted() {
    SetClarityData();
    var luser = Object.freeze(JSON.parse(JSON.stringify(this.user)));
    if (
      luser && //  null and undefined check
      Object.keys(luser).length === 0 &&
      Object.getPrototypeOf(luser) === Object.prototype
    ) {
      luser = null;
    } else {
      luser = Object.freeze(JSON.parse(JSON.stringify(this.user)));
      this.username = luser.firstName + " " + luser.lastName;
    }
    if (!luser) {
      this.$router.push({ name: "Login" });
    } else if (luser && luser.termsAndConditionsAccepted) {
      if (!this.user.passwordSet) {
        this.$router.push({
          name: "SetPassword",
        });
      } else if (this.user.twoFactorRequired) {
        this.$router.push({
          name: "OtpCode",
        });
      } else if (!this.user.twoFactorRequired) {
        this.$router.push({
          name: "DashboardGroup",
        });
      }
    }
  },

  methods: {
    ...mapActions(["tnc", "ShowWarningMessage"]),
    ...mapMutations(["setLoading"]),

    IsCheckedTandC(event) {
      if (event.target.checked) {
        this.IsTermsAndCondiChecked = true;
      } else {
        this.IsTermsAndCondiChecked = false;
      }
    },
    async submit() {
      this.isLoading = true;
      if (this.IsTermsAndCondiChecked) {
        this.tnc(this.userId).then(async () => {
          if (this.user) {
            if (!this.user.passwordSet) {
              this.$router.push({
                name: "SetPassword",
              });
            } else {
              this.$router.push({ name: "Login" });
            }
          } else {
            this.$router.push({ name: "Login" });
          }
        });
      } else {
        return this.ShowWarningMessage("Please Accept Terms of service.");
      }

      this.isLoading = false;
    },
  },
};
</script>
<style lang="css">
@import url("/assets/css/style.css");
@import url("/assets/css/responsive.css");
@import url("/assets/css/custom.js");
</style>

<style>
.terms_popup_section p {
  margin: 15px 0 0 !important;
  font-size: 15px;
}

.terms_popup_section ul li,
.terms_popup_section ol li {
  font-size: 15px;
}

.terms_popup_section ul,
.terms_popup_section ol {
  margin-top: 20px !important;
}

.terms_popup_section h4 {
  margin-top: 30px !important;
  color: #7b68ee;
}
</style>
