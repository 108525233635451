<template>
  <div class="page-content">
    <!-- mobile view Searching -->
    <div class="Searching_input_box_mobile_view position-relative">
      <div class="w-100  mb-3 " v-if="this.show">
        <input type="text" v-model="input" :disabled="searchDisable" @keyup.enter="searchregionname(input)"
          placeholder="Search..." />
        <!--update  @keyup="filteredList"-->
        <!-- <div class="search_detail_list" v-if="this.result.length > 0">
            <div
              v-for="(item, index) in this.result"
              :key="index"
              class="search_detail_list_item"
              @click="searchregionname(item)"
              style="cursor: pointer"
            >
              <span :id="index">
                {{ stringTOHtml(item, index) }}
              </span>
            </div>
          </div> -->
        <!--update-->

      </div>
    </div>
    <div class="mb-2 mt-1">
      <span style="font-size: 20px;">
        Token Management
      </span>
      <span class="float-end">

        <span class="top_heading_CreatePartner_btn d-block">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                <img src="../../../public/dashboard-assets/images/M2/date-icon.png" @click="opencal"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Filter by Date" />
                <span class="cal-alert translate-middle" v-if="this.fromDate != 0">
                  <span class="visually-hidden"></span>
                </span>
                <input type="text" name="daterange" hidden="true" id="calendar_icon" class="fade" />
              </button>
            </div>
          </div>
        </span>
      </span>
    </div>
    <!--  Activated Groups-Toast -->
    <!-- <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2">
        <span class="mb-2 mb-md-0 fw-bold">Token Management</span>

           <span class="top_heading_CreatePartner_btn d-block">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                <img src="../../../public/dashboard-assets/images/M2/date-icon.png" @click="opencal"
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Filter by Date" />
                <span class="cal-alert translate-middle" v-if="this.fromDate != 0">
                  <span class="visually-hidden"></span>
                </span>
                <input type="text" name="daterange" hidden="true" id="calendar_icon" />
                <input type="text" name="consolidated_sheet_daterange" hidden="true"
                  id="consolidated_sheet_calendar_icon" />
              </button>
            </div>
          </div>
        </span>
      </div>

      <div class="d-flex justify-content-between align-items-center">
        <div class="top_heading_CreatePartner_btn position-relative Searching_button_mobileView">
          <div class="Group_top_three_dot">
            <span class="icon Group_top_three_dot_dropdown" :style="{ display: this.show == true ? 'none' : 'block' }"
              @click="openmobilesearch">
              <img src="../../../public/dashboard-assets/images/M2/Icon-Set.png" alt="" class="Searching_search_icon" />
            </span>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xl-8 col-lg-8 partners_top_menu_section_hp">
        <div class="row">
          <div class="col-3">
            <router-link :to="{ name: 'TestManagement' }" class="w-100 btn">
              Overview <span v-if="this.TokelistTotallength > 0">({{ TokelistTotallength }})</span>
            </router-link>
          </div>
          <div class="col-3" v-if="this.showTokenManagement">
            <router-link :to="{ name: 'TokenmanagementViewTest' }" class="w-100 btn">
              View Test
            </router-link>
          </div>
          <div class="col-3" v-if="this.showTokenManagement">
            <router-link :to="{ name: 'TokenmanagementViewRegion' }" class="w-100 btn">
              View Group
            </router-link>
          </div>
          <div class="col-3">
            <router-link :to="{ name: 'TokenLogs' }" class="w-100 btn active btn-outline-primary btn-icon-text">
              View Logs
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="container mt-2" v-if="tokenLogs.length > 0">
      <div class="row" v-for="(item, index) in tokenLogs" :key="item">
        <div class="col-auto text-center flex-column d-none d-sm-flex">
          <div class="row h-50">
            <div class="col border-end">&nbsp;</div>
            <div class="col">&nbsp;</div>
          </div>
          <h5 class="m-2">
            <span class="badge rounded-pill bg-success">&nbsp;</span>
          </h5>
          <div class="row h-50">
            <div class="col border-end">&nbsp;</div>
            <div class="col">&nbsp;</div>
          </div>
        </div>
        <div class="col py-2">
          <div class="card border-success shadow">
            <div class="card-body">
              <div class="float-end text-success">{{ timeZoneConvert(item.entryDate) }}</div>
              <h4 class="card-title text-success">{{ item.entryType }} : {{ item.entryName }}</h4>
              <p class="card-text">{{ item.action }}</p>
              <button class="btn btn-sm btn-outline-secondary" type="button" :data-bs-target="'#t' + index + '_details'"
                data-bs-toggle="collapse">Show Details ▼</button>
              <div class="collapse border" :id="'t' + index + '_details'">
                <div class="p-2 text-monospace">
                  <div>Previous Remaining : {{ item.previousRemaining }}</div>
                  <div>Tokens Added: {{ item.tokenAdded }}</div>
                  <div>New Remaining: {{ item.newRemaining }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" v-else>
      <img src="../../../public/dashboard-assets/images/M2/empty_record.png" style="width: 20%" class="my-5" />
      <h3 class="ms-5 mt-2">No Details Found</h3>
    </div>
    <!--container-->
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import { superadmin } from "../../utils/helper";
import { SetClarityData } from "../../platformFeatures/featureUtils";

export default {
  name: "TestManagement",
  components: {

  },
  data() {
    return {
      tokenList: [],
      reqList: [],
      TokelistTotallength: 0,
      AllocateAmount: null,
      requestAmount: null,
      rejToken: "",
      requestid: "",
      Status: "",
      page: 1,
      limit: 8,
      search: "",
      partners: "",
      Page_count: 1,
      modalData: "",
      parentCompany: "",
      CompanyName: "",
      balanceToken: 0,
      remainingToken: 0,
      viewData: [],
      listData: "",
      groupData: [],
      reqId: "",
      offset: 8,
      redtext: false,
      isActive: true,
      approvedReq: [],
      tokenamt: "",
      result: [],
      input: "",
      show: false,
      currentPage: 1,
      company: [],
      filters: {
        company: "",
      },
      viewclientId: "",
      tokenAllocationObj: {},
      parentTokenAllocationObj: {},
      screenWidth: 0,
      approveData: [],
      firstname: "",
      lastname: "",
      amt: "",
      clientid: "",
      parentCompanyName: "",
      photo: '',
      searchDisable: false,
      tokenLogs: [],
      billing: JSON.parse(localStorage.getItem('billingData')),
      showTokenManagement: true
    };
  },
  async created() {
    window.addEventListener("resize", this.myEventHandler);
    this.screenWidth = window.outerWidth;
    var date = new Date();
    var endDate = moment(String(date)).add(1, 'days').format("YYYY-MM-DD  00:00:00");
    var startDate = moment(endDate, "YYYY-MM-DD").subtract(6, 'months').format("YYYY-MM-DD  00:00:00");
    if (this.user.isEnterprise == false && this.billing.object.data.plan.planName) {
      this.showTokenManagement = false;
    }
    await this.TokenLogDetails(startDate, endDate);
  },
  async mounted() {

    SetClarityData();

    var company = await this.Companyheader({
      clientId: this.clientId,
    });
    this.companyName = company.object.data.companyName;
    var photo = company.object.data.profileImage;
    if (photo != null) {
      var resp = await this.companylogo({
        fileName: photo,
      });
      this.photo = resp.object.data;
    } else {
      this.photo = null;
    }
    if (this.$route.query.offset && this.$route.query.current) {
      this.page = parseInt(window.atob(this.$route.query.offset));
      this.currentPage = parseInt(window.atob(this.$route.query.current));
    }
    this.screenWidth = window.outerWidth;
    await this.getData();
    await this.GetToken();


    window.$('input[name="daterange"]').daterangepicker(
      {
        maxDate: new Date(),
        locale: { cancelLabel: "Clear" },
        opens: "center",
      },
      async (start, end) => {
        if (start && end) {
          var x = start.format("YYYY-MM-DD 00:00:00");
          var y = end.format("YYYY-MM-DD 00:00:00");
          // this.fromDate = this.dateformat(x);
          // this.toDate = this.todateformat(y);
          this.fromDate = x;
          this.toDate = y;
          this.showw = false;
        }
      }
    );
    window
      .$('input[name="daterange"]')
      .on("cancel.daterangepicker", async (ev, picker) => {
        picker.startDate._d = new Date();
        picker.endDate._d = new Date();
        this.fromDate = this.dateformat(
          new Date(new Date().setMonth(new Date().getMonth() - 6))
        ),
          this.toDate = this.todateformat(new Date(new Date())),
          // await this.Graphical(this.fromDate, this.toDate);
          window.$('input[name="daterange"]').val("");
      });
    window
      .$('input[name="daterange"]')
      .on("apply.daterangepicker", async (ev, picker) => {
        var x = moment(String(picker.startDate._d)).format("YYYY-MM-DD 00:00:00");
        var y = moment(String(picker.endDate._d)).format("YYYY-MM-DD 23:59:59");
        var start = moment(String(picker.startDate._d)).format("YYYY-MM-DD 00:00:00")
        var end = moment(String(picker.endDate._d)).format("YYYY-MM-DD 23:59:59")
        if (
          Math.ceil(
            Math.abs(new Date(start) - new Date(end)) /
            (1000 * 60 * 60 * 24)
          ) <=
          6 * 30
        ) {
          this.fromDate = x;
          this.toDate = y;
          this.TokenLogDetails(this.fromDate, this.toDate)
          // if (this.opencals == false) {
          //   await this.Graphical(start, end);
          // }
        } else {
          return this.ShowWarningMessage("Please select range within 6 months");
        }

      });




  },

  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      clientId: (state) => state.auth.user.clientId,
      PartnerId: (state) => state.auth.user.PartnerId,
    }),
  },

  methods: {
    ...mapActions([
      "GetTokenAllocation",
      "requestedTokens",
      "IncrOrDecTokens",
      "rejectReq",
      "GetTAllocation",
      "viewtestList",
      "viewGroupsList",
      "AllocateTokenAmount",
      "companylogo",
      "Companyheader",
      "GetTokenLogs",
      "ShowWarningMessage"
    ]),
    ...mapMutations(["setLoading"]),
    dateConvert: (value) => {
      return moment(String(value)).format("DD MMM YYYY");
    },
    timeZoneConvert(value) {
      const offsetMatch = this.user.timezone.match(/([+-]\d{2}):?(\d{2})?/);
      const hours = parseInt(offsetMatch[1]);
      const minutes = offsetMatch[2] ? parseInt(offsetMatch[2]) : 0;
      const offset = hours * 60 + minutes;

      const startTimeWithGMT = moment.utc(value).utcOffset(offset);
      return startTimeWithGMT.format("DD MMM YYYY HH:mm A");
    },

    superadmin,

    myEventHandler(e) {
      this.screenWidth = window.outerWidth;
      e.preventDefault();
    },

    async TokenLogDetails(to, from) {
      var filter = { fromDate: to, toDate: from };
      var tokenLogsResponse = await this.GetTokenLogs(filter);
      if (tokenLogsResponse.status) {
        this.tokenLogs = tokenLogsResponse.object.data;
      }
    },
    encode(item) {
      return window.btoa(item);
    },
    //time-converter
    timeConverter(val) {
      const today = new Date(val);
      let h = today.getHours();
      let m = today.getMinutes();
      var meridian = h >= 12 ? "PM" : "AM";
      h = h % 12;
      h = h ? h : 12;
      m = m < 10 ? "0" + m : m;
      var strTime = h + ":" + m + " " + meridian;
      return strTime;
    },

    //allocate token
    decDisableAllocatedButton() {
      if (
        this.AllocateAmount == null ||
        this.AllocateAmount == 0 ||
        this.AllocateAmount == "" ||
        this.AllocateAmount < 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    decreaseallocatevalue() {
      if (
        this.AllocateAmount == null ||
        this.AllocateAmount == 0 ||
        this.AllocateAmount == "" ||
        this.AllocateAmount < 0
      ) {
        this.AllocateAmount = null;
      } else {
        this.AllocateAmount = this.AllocateAmount - 1;
      }
    },
    increaseallocateValue() {
      if (
        this.AllocateAmount == null ||
        this.AllocateAmount == "" ||
        this.AllocateAmount == 0
      ) {
        this.AllocateAmount = 1;
      } else {
        this.AllocateAmount = this.AllocateAmount + 1;
      }
    },

    //request token
    decDisableButton() {
      if (
        this.requestAmount == null ||
        this.requestAmount == 0 ||
        this.requestAmount == "" ||
        this.requestAmount < 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    decreaseValue() {
      if (
        this.requestAmount == null ||
        this.requestAmount == 0 ||
        this.requestAmount == "" ||
        this.requestAmount < 0
      ) {
        this.requestAmount = null;
      } else {
        this.requestAmount = this.requestAmount - 1;
      }
    },
    inrDisableButton() {
      return this.requestAmount > 5000;
    },
    increaseValue() {
      if (
        this.requestAmount == null ||
        this.requestAmount == "" ||
        this.requestAmount == 0
      ) {
        this.requestAmount = 1;
      } else {
        if (parseInt(this.requestAmount) <= 5000) {
          this.requestAmount = parseInt(this.requestAmount) + 1;
        }
      }
    },

    // Searching box
    opensearch() {
      document.querySelector(".Searching_input-box").classList.add("open");
    },
    async closesearch() {
      document.querySelector(".Searching_input-box").classList.remove("open");
      this.result = [];
      this.input = "";
      this.filters.company = "";
      await this.getData();
    },
    async closemobilesearch() {
      this.show = false;
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.remove("open");
      this.result = [];
      this.filters.company = "";
      this.input = "";
      await this.getData();
    },
    openmobilesearch() {
      document
        .querySelector(".Searching_input_box_mobile_view")
        .classList.add("open");
      this.result = [];
      this.input = "";
      this.show = true;
    },

    async filteredList() {
      if (this.input != "") {
        this.company = [];
        this.result = [];
        var list = await this.GetTokenAllocation({
          clientId: this.clientId,
          Page: 1,
          Limit: 100,
          Search: this.input,
        });
        var x = list.object.data.items;
        x.forEach((element) => {
          if (this.company.indexOf(element.company) < 0) {
            this.company.push(element.company);
          }
        });
        this.result = this.company.filter((element) =>
          element.toLowerCase().startsWith(this.input.toLowerCase())
        );
        this.result = Object.freeze(JSON.parse(JSON.stringify(this.result)));
      } else {
        this.result = [];
        this.filters.company = "";
        await this.getData();
      }
    },
    stringTOHtml(item, idx) {
      setTimeout(() => {
        if (this.input != "") {
          let re = new RegExp(this.input.toLowerCase(), "g");
          var html = document.getElementById(idx);
          var ex = item
            .toLowerCase()
            .replace(
              re,
              `<span style="color: #7b68ee !important">${this.input}</span>`
            );
          if (html) html.innerHTML = ex;
        }
      }, 100);
    },
    async searchregionname(val) {
      this.input = val.trim();
      this.page = 1;
      this.filters.company = val;
      this.result = [];
      this.currentPage = 1;
      await this.getData();
    },
    //*********search end*********

    isButtonDisable(clientId) {
      if (clientId == 1001 && this.requestAmount > 0) {
        return true;
      } else {
        if (this.requestAmount > 0 && this.requestAmount < 5001) {
          return true;
        }
        else if (this.requestAmount > 5000) {
          return false;
        }
      }
    },
    allocatedButtonDisable() {
      return this.AllocateAmount > 0;
    },

    allocatedecreasevalue() {
      if (
        this.AllocateAmount == null ||
        this.AllocateAmount == 0 ||
        this.AllocateAmount == "" ||
        this.AllocateAmount < 0
      ) {
        this.AllocateAmount = null;
      } else {
        this.AllocateAmount = this.AllocateAmount - 1;
      }
    },

    async clickCallback(pageNum) {
      this.page = pageNum;
      await this.getData();
    },

    async rejectBtn(firstName, lastName, amt, reqid, clientId) {
      (this.firstname = firstName),
        (this.lastname = lastName),
        (this.amt = amt),
        (this.reqId = reqid),
        (this.clientid = clientId);
    },

    async getData() {
      this.searchDisable = true;

      var list = await this.GetTokenAllocation({
        clientId: this.clientId,
        Page: this.page,
        Limit: this.limit,
        Search: this.filters.company,
      });
      this.searchDisable = false;
      var x = [];
      if (list != undefined) {
        this.tokenList = list.object.data.items;
        this.TokelistTotallength = list.object.data.totalItems;

        this.tokenList.forEach(async (element) => {
          x.push(element.clientId);
          var request = await this.requestedTokens({
            clientId: element.clientId,
          });
          if (request.status == false) {
            element.requestCount = 0;
          } else {
            element.requestCount = await request.object.data.length;
          }
        });
        this.Page_count = list.object.data.totalPages;
        this.partnerLogoList();
      }
      if (this.input.trim() != "") {
        list.Search = this.input;
      }
    },

    async partnerLogoList() {
      this.tokenList.forEach(async (elm) => {
        var company = await this.Companyheader({
          clientId: elm.clientId,
        });
        var photo = company.object?.data?.profileImage;
        if (photo != null) {
          var resp = await this.companylogo({
            fileName: photo,
          });
          elm.photo = resp.object.data;
        } else {
          elm.photo = null;
        }
      });
    },

    async incdrcTokens(modal, id) {
      await this.IncrOrDecTokens({
        clientId: id,
        amount: this.requestAmount,
      });
      this.requestAmount = 0;
      if (modal == "RequestToken_IncreaseTokenModal") {
        document.getElementById("closerequest").click();
        window.$("#RequestToken_IncreaseTokenModal").modal("hide");
      } else {
        document.getElementById("closerequest1").click();
        window.$("#RequestTokenModal").modal("hide");
      }
      this.getData();
    },
    async approverequest(reqId, status, index, tokenamount, id) {
      this.rejectrequest = await this.rejectReq({
        clientId: id,
        requestId: reqId,
        status: status,
        tokenAmount: tokenamount,
        company: this.modalData.company,
      });
      this.isActive = false;
      if (this.rejectrequest.status) {
        this.approvedReq.push(reqId);

        setTimeout(async () => {
          var request = await this.requestedTokens({
            clientId: id,
          });
          this.reqList = request.object.data;
          if (this.reqList.length == 0) {
            // document.getElementById('aprove').click();
            document.getElementById("closerequest").click();
          } else {
            this.reqList.forEach((element) => {
              if (element.tokenAmount > this.parentCompany.remainingToken) {
                this.redtext = true;
              } else {
                this.redtext = false;
              }
            });
          }
        }, 3000);
      } else {
        // this.parentCompany.remainingToken =
        //   this.parentCompany.remainingToken - tokenamount;
      }

      this.remainingToken = this.reqList[index].tokenAmount;
      this.GetToken();
      this.getData();
    },

    async rejectToken(reqId, status, tokenamount) {
      this.rejToken = await this.rejectReq({
        clientId: this.clientid,
        requestId: reqId,
        status: status,
        tokenAmount: tokenamount,
        company: this.modalData.company,
      });
      document.getElementById("remove").click();
      document.getElementById("closerequest").click();
      window.$("#RejectTokenModal").modal("hide");
      this.getData();
    },
    async postAmountAllocation(tokenAllocationObj) {
      await this.AllocateTokenAmount({
        clientId: tokenAllocationObj.clientId,
        amount: this.AllocateAmount,
        companyName: this.CompanyName,
      });
      this.AllocateAmount = null;
      document.getElementById("close").click()
      window.$("#AllocateTokens").modal("hide");
      this.getData();
      this.GetToken();

      // modify this code if it needs to be reused for parent child relationship in token management
      // if(tokenResponse.status !== 202) {
      //   this.getData();
      //   this.GetToken();
      // } else {
      //   window.$("#AllocateParentTokens").modal("show");
      //   this.clientId = tokenAllocationObj.parentClientId;
      //   var parentTokenResponse = await this.GetParentToken(tokenAllocationObj.parentClientId);
      //   this.parentTokenAllocationObj = parentTokenResponse.object.data;
      //   this.balanceToken = this.parentTokenAllocationObj.remainingToken;
      // }
    },

    /* modify this code if it needs to be reused for parent child relationship in token management
    async allocateAmountToParentAndChild(parentTestAllocationObj, testAllocationObj) {
      await this.AllocateTokenAmount({
          clientId: parentTestAllocationObj.clientId,
          amount: this.AllocateAmount,
          companyName: parentTestAllocationObj.company,
        });
        
      await this.AllocateTokenAmount({
          clientId: testAllocationObj.clientId,
          amount: this.AllocateAmount,
          companyName: testAllocationObj.company,
        });
      
        this.AllocateAmount = null;
        document.getElementById("close").click();
        window.$("#AllocateParentTokens").modal("hide");
    },
    */

    async GetToken() {
      var tokens = await this.GetTAllocation({
        clientId: this.clientId,
      });
      if (tokens != undefined) {
        this.parentCompany = tokens.object.data;

        this.parentCompanyName = this.parentCompany.company;
      }
    },

    async GetParentToken(parentClientId) {
      return await this.GetTAllocation({
        clientId: parentClientId,
      });
    },

    decreaseAllocateAmountToken() {
      if (
        this.AllocateAmount == null ||
        this.AllocateAmount == 0 ||
        this.AllocateAmount == "" ||
        this.AllocateAmount < 0
      ) {
        this.true;
      } else {
        return false;
      }
    },
    AllocateAmountDecToken() {
      if (
        this.AllocateAmount == null ||
        this.AllocateAmount == 0 ||
        this.AllocateAmount == "" ||
        this.AllocateAmount < 0
      ) {
        this.AllocateAmount = null;
      } else {
        this.AllocateAmount = this.AllocateAmount - 1;
      }
    },

    async getCompanyName(tokenAllocationObj) {
      this.CompanyName = tokenAllocationObj.company;
      this.balanceToken = tokenAllocationObj.remainingToken;
      this.viewclientId = tokenAllocationObj.clientId;
      this.tokenAllocationObj = tokenAllocationObj;
    },

    async ViewReqList(companyName, balanceToken, id) {
      this.modalData = {
        company: companyName,
        remainingToken: balanceToken,
        clientId: id,
      };
      var request = await this.requestedTokens({
        clientId: id,
      });
      if (request != undefined) {
        this.reqList = request.object.data;
        this.checkBalance();
        this.reqList.forEach((element) => {
          if (element.tokenAmount > this.parentCompany.remainingToken) {
            this.redtext = true;
          } else {
            this.redtext = false;
          }
        });
      }
      // this.getData();
    },
    opencal() {
      document.getElementById("calendar_icon").click();
    },

    checkBalance(amt) {
      Array.prototype.sum = function (prop) {
        var total = 0;
        for (var i = 0, len = this.length; i < len; i++) {
          total += this[i][prop];
        }
        return total;
      };
      var total = this.reqList.sum("tokenAmount");
      if (total > amt) return true;
      else return false;
    },

    openTokenModal(type) {
      if (type == 1) {
        this.modalData = this.parentCompany;
      }
      this.GetToken();
    },

    clearbtn() {
      this.AllocateAmount = null;
      this.requestAmount = null;
    },
    closeModal() {
      document.getElementById('closerequest').click();
    }
  },
};
</script>
<style scoped>
/* Base styles for all screens */

/* 
  Target extra large screens (desktops)
  Use max-width for a more inclusive breakpoint 
*/
@media screen and (max-width: 1920px) {

  /* Styles for extra large screens */
  .float-end {
    float: right !important;
  }
}

/* 
  Target large screens (desktops)
*/
@media screen and (max-width: 1440px) {

  /* Styles for large screens */
  .float-end {
    float: right !important;
  }
}

/* 
  Target medium screens (tablets)
*/
@media screen and (max-width: 1024px) {

  /* Styles for medium screens */
  .float-end {
    float: right !important;
  }
}

/* 
  Target small screens (phones)
  Use min-width for a more precise breakpoint for tablets
*/
@media screen and (min-width: 768px) and (max-width: 1024px) {

  /* Styles for small screens (portrait tablets) */
  .float-end {
    float: none !important;
    margin-bottom: 5%;
  }
}

/* 
  Target extra small screens (phones)
*/
@media screen and (max-width: 768px) {

  /* Styles for extra small screens (phones) */
  .float-end {
    float: none !important;
    margin-bottom: 5%;
  }
}
</style>