<template>
  <div class="donut-chart-block donut-chrt-kj block position-relative"
    style="width: 140px; margin: 15px auto 0; position: relative !important" id="chart">
    <div class="chrt-kj" style="
        position: relative;
        height: 30px !important;
        width: 30px !important;
      ">
      <canvas :id="this.main" style="
          display: block;
          box-sizing: border-box;
          height: 30px !important;
          width: 30px !important;
          z-index: 5;
          position: absolute !important;
          top: 0;
          left: 0;
        "></canvas>
    </div>
    <div class="donut-chart-border-inside" style="
        width: 75px;
        height: 75px;
        border: 3px solid;
        border-radius: 50%;
        position: absolute;
        z-index: 0;
        top: 20%;
        display: flex;
        left: 18%;
        align-items: center;
        justify-content: center;
      " v-if="this.main != 'proctorSection' && this.main != 'proctorSectionpdf'" :style="{
        transform: `rotate(${this.rotateAngle}deg)`,
        borderColor: this.resultColor,
      }">
      <i class="fa-solid fa-sort-up donut-chart-svg" style="font-size: 20px; position: absolute; left: 1px; top: 4px"
        :style="{ color: this.resultColor }"></i>
      <!-- :class="{ 'transform-328deg': this.iconcolor }" -->
    </div>
    <p class="donut-chart-text" style="
        position: absolute;
        font-size: 13px;
        inset: 28px;
        width: 70px;
        z-index: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      " :style="{ color: this.resultColor }">
      {{ this.marksScored }}/{{ this.score?.outOfMarks }}
      <span style="font-weight: normal">
        {{ this.score?.result }}
      </span>
    </p>
  </div>

</template>

<script>
import { Chart, registerables } from "chart.js";
import { SetClarityData } from "../platformFeatures/featureUtils";
Chart.register(...registerables);
// import jsPDF from "jspdf";
// import { html2pdf } from "html2pdf.js";
export default {
  name: "CustomChart",
  props: ["score", "main", "iconcolor", "pdf-chart", "isPdf"],

  data() {
    return {

      chartId: this.main ? this.main : "myChart",
      resultColor: "",
      section: 360 / this.score?.ranges?.length,
      colors: ["#FD71AE", "#FFC801", "#49CCFA "],
      rotateAngle: "",
      marksScored: 0,
      imgData: ''
    };
  },
  async mounted() {

    SetClarityData();

    this.findScore(this.score?.marksScored);
    this.chartData();
    this.marksScored = this.score?.marksScored;
    if (this.marksScored === 0.0 || this.marksScored === 10.0) {
      this.marksScored = +this.marksScored;
    }

  },

  methods: {
    findScore(score) {

      this.score?.ranges.forEach((x, i) => {
        if (score >= x.minVal && score <= x.maxVal) {
          this.resultColor = x.colorCode;
          // console.log(i* this.section, (this.section/(x.maxVal- x.minVal)),Math.ceil(score), Math.ceil(score)- x.minVal);
          this.rotateAngle = (i * this.section + 53) + ((this.section / (x.maxVal - x.minVal)) * (score - x.minVal));

          //  this.rotateAngle =(i * this.section + (i + 1) * this.section)/2 + 60 ;

        }
      });
    },

    chartData() {
      var custom = [];
      var customLabel = [];
      var customColor = [];
      this.score?.ranges.forEach((elm) => {
        custom.push(this.section);
        customLabel.push(elm.rangeName);
        customColor.push(elm.colorCode);
      });
      var data = {
        labels: customLabel,
        datasets: [
          {
            data: custom,
            backgroundColor: customColor,
            weight: 1,
          },
        ],
      };

      var options = {
        maintainAspectRatio: false,
        cutout: 49,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            yAlign: "bottom",
            zIndex: 9999,
            position: "nearest",
            callbacks: {
              label: (tooltipItem) => {
                var min = JSON.parse(
                  JSON.stringify(
                    this.score.ranges.filter(
                      (x) => x.rangeName == tooltipItem.label
                    )
                  )
                )[0].minVal;
                var max = JSON.parse(
                  JSON.stringify(
                    this.score.ranges.filter(
                      (x) => x.rangeName == tooltipItem.label
                    )
                  )
                )[0].maxVal;
                return tooltipItem.label + "(" + min + "-" + max + ")";
              },
            },
          },
        },
      };
      var ctx = document.getElementById(this.main).getContext("2d");
      ctx.canvas.parentNode.style.height = "125px";
      ctx.canvas.parentNode.style.width = "125px";
      new Chart(ctx, {
        type: "doughnut",
        data: data,
        options: options,
      });
    },
  },
};
</script>
<style lang="css">
.circle_arrow {
  border-radius: 50%;
  z-index: 9;
  position: relative;
  transform: rotate(315deg);
}

.circle_arrow img {
  width: 90px;
  margin-top: -6px;
  fill: #df0000;
}

.circle_arrow img path {
  fill: #df0000;
}

.custom-tooltip-content {
  display: flex;
  align-items: center;
}

.donut-chrt-kj {
  width: 140px;
  margin: 15px auto 0;
}

.donut-chrt-kj .chrt-kj {
  position: relative;
}

.donut-chrt-kj .chrt-kj canvas {
  z-index: 5;
  position: absolute !important;
  top: 0;
  left: 0;
}

.donut-chart-block .donut-chart-border-inside {
  width: 75px;
  height: 75px;
  border: 3px solid;
  border-radius: 50%;
  position: absolute;
  z-index: 0;
  top: 20%;
  display: flex;
  left: 18%;
  align-items: center;
  justify-content: center;
}

.transform-328deg {
  transform: rotate(330deg) !important;
}

.donut-chart-block .donut-chart-border-inside .donut-chart-svg {
  transform: rotate(308deg);

  font-size: 20px;
  position: absolute;
  left: 1px;
  top: 4px;
}

.donut-chart-block .donut-chart-text span {
  font-size: 9px;
}

.donut-chart-block .donut-chart-text {
  position: absolute;
  font-size: 13px;
  inset: 28px;
  width: 70px;
  z-index: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .donut-chart-block .donut-chart-border-inside {
    left: 25px;
    top: 25px;
  }

  /* .donut-chart-block .donut-chart-text {
 
  width: 30%;} */
}
</style>
