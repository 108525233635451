<template>
  <!-- <section >
      <div class="container d-flex align-items-center justify-content-center">
        <div>
        <h1>Please wait for a while we are setting up your Account</h1>
            </div>
    </div>
    </section> -->
  <div class="container d-flex justify-content-center align-items-center" style="min-height: 100vh;">
    <span class="custom-center">
      <h4>Please wait for a while we are setting up your Account</h4>
    </span>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { SetClarityData } from "../platformFeatures/featureUtils";
export default {
  name: "VerifyPaymentStatusOld",

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  async mounted() {
    SetClarityData();
    this.intervalId = setInterval(async () => {
      await this.RefreshBillingDetails();

      const updatedBillingInfo = JSON.parse(localStorage.getItem("billingData"));
      const updatedPlan = updatedBillingInfo.object.data.plan.planCode;

      if (localStorage.getItem("plan") !== updatedPlan) {
        clearInterval(this.intervalId);
        localStorage.setItem("plan", updatedPlan);

        this.$router.push({
          name: 'DashboardGroup',
        });

      }
    }, 2500);

  },
  methods: {
    ...mapMutations(["setLoading"]),
    ...mapActions(["RefreshBillingDetails"]),

  },
};
</script>
<style scoped lang="css">
@import url("/assets/css/style.css");
@import url("/assets/css/responsive.css");

/* body{
    height: 100vh;
  }  */
.custom-center {
  margin-top: -15%;
}

@media (max-width:600px) {
  .custom-center {
    margin-top: -50%;
  }
}
</style>