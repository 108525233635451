<template>
  <!--Sidebar Part -->
  <nav class="sidebar">
    <div class="sidebar-header">
      <router-link :to="{ name: 'DashboardGroup' }" class="sidebar-brand">
        <!-- <img src="/assets/Images/logo.png" /> -->
        <img src="/assets/Images/logo.png" alt="" style="width: 100px;">
      </router-link>
      <div :class="[
        'sidebar-toggler mobile_sidebar_toggler',
        isSidebarOpen ? 'active' : 'not-active',
      ]" @click="$emit('toggleSidebar')">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav p-0" id="nav_accordion">
        <!-- <ul class="nav sub-menu p-0"  id="sidebar-dashboard">
          <li
            class="nav-item"
            v-if="getUserPermissionData({
              parent: 'Dashboard Management',
              child: 'Dashboard',
              permission: 'Can_Access_Dashboard',
            })"
          >
            <router-link :to="{ name: 'DashboardGroup' }" class="nav-link">
              <div class="border-left-active"></div>
              <img
                src="../../public/dashboard-assets/images/M2/dashboardIcon.svg"
                alt=""
              />
              <span class="link-title w-100" @click="hideSidebar">Dashboard</span>
            </router-link>    
          </li>
        </ul> -->
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="collapse" href="#Dashboard" role="button" aria-expanded="false"
            aria-controls="Test">
            <img src="../../public/dashboard-assets/images/M2/sidebar/dashboardIcon.svg" alt="" style="width:25px" />

            <!-- <i class="far fa-file-alt"></i> -->
            <span class="link-title w-100" style="color: #2d2d2d;">Dashboard</span>
            <i class="link-arrow" data-feather="chevron-down"></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-chevron-down link-arrow">
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </a>
          <div class="collapse" data-bs-parent="#nav_accordion" id="Dashboard">
            <ul class="nav sub-menu p-0" id="sidebar-dashboard-management">
              <li class="nav-item">
                <router-link :to="{ name: 'DashboardGroup' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Overview</span>
                </router-link>
              </li>
              <li class="nav-item"
                v-if="(this.clientRole.roleName == 'Owner' || this.clientRole.roleName == 'Admin' || this.clientRole.roleName == 'PMaps SuperAdmin')">
                <router-link :to="{ name: 'DescriptiveOverview' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Descriptive</span>
                </router-link>
              </li>
              <!--  v-if="superadmin()" -->
              <!-- <li class="nav-item">
                <router-link :to="{ name: 'ArchiveTests' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Predictive</span>
                </router-link>
              </li> -->
            </ul>
          </div>
        </li>

        <!-- <li class="nav-item"> -->
        <!-- <a
            class="nav-link"
            data-bs-toggle="collapse"
            href="#Dashboard"
            role="button"
            aria-expanded="false"
            aria-controls="Dashboard"
            @click="toggledashboard"
          >
            <i class="fas fa-tachometer-alt"></i>
            <img
              src="../../public/dashboard-assets/images/M2/dashboardIcon.svg"
              alt=""
            />
            <span class="link-title w-100">Dashboard</span>
            <i class="link-arrow" data-feather="chevron-down"></i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down link-arrow"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </a> -->
        <!-- <div
            class=""
            data-bs-parent="#nav_accordion"
            id="Dashboard"
            :class="{ 'collapse show': this.show1, 'collapsing': !this.show1 }"
          >
            <ul class="nav sub-menu p-0">
              <li
                class="nav-item"
                v-if="
                  getUserPermissionData({
                    parent: 'Dashboard Management',
                    child: 'Dashboard',
                    permission: 'Can_Access_Dashboard',
                  })
                "
              >
                <router-link :to="{ name: 'DashboardGroup' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Overview</span>
                </router-link>
                
              </li>
              <li class="nav-item">
              <router-link :to="{ name: 'DashboardDescriptiveAnalystics' }" class="nav-link">
              <div class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Descriptive Analytic</span>
                </div>
              </router-link>
              </li>
              <li class="nav-item">
                <a href="#" class="nav-link disabled">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Predictive Analytic</span>
                </a>
              </li>
            </ul>
          </div> -->
        <!-- </li> -->



        <ul class="nav sub-menu p-0" id="sidebar-library">
          <li class="nav-item">
            <router-link :to="{ name: 'TestLibrary' }" class="nav-link">
              <div class="border-left-active"></div>
              <!-- <i class="fa-solid fa-book fa-xl" style="color: #676767;"></i> -->
              <img src="../../public/dashboard-assets/images/M2/sidebar/libraryIconNew.svg" alt="" style="width:25px" />
              <span class="link-title w-100">
                Library
              </span>
            </router-link>
          </li>
        </ul>

        <li class="nav-item sidebar-test-management">
          <a class="nav-link" data-bs-toggle="collapse" href="#Test" role="button" aria-expanded="false"
            aria-controls="Test">
            <img src="../../public/dashboard-assets/images/M2/sidebar/testIcon.svg" alt="" style="width:25px" />
            <!-- <i class="far fa-file-alt"></i> -->
            <span class="link-title w-100">Test</span>
            <i class="link-arrow" data-feather="chevron-down"></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-chevron-down link-arrow">
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </a>
          <div class="collapse" data-bs-parent="#nav_accordion" id="Test">
            <ul class="nav sub-menu p-0" id="sidebar-test-management">
              <li class="nav-item" v-if="
                getUserPermissionData({
                  parent: 'Test Management',
                  child: 'Test Account',
                  permission: 'Can_Access_Test',
                })
              ">
                <router-link :to="{ name: 'ActiveTests' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Active Tests</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="!superadmin()">
                <router-link :to="{ name: 'ArchiveTests' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Archive Tests</span>
                </router-link>
              </li>
              <!-- <li class="nav-item">
                <a href="#" class="nav-link disabled">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Archive tests</span>
                </a>
              </li> -->
              <!-- Added by pmaps team  -->
              <li class="nav-item">
                <!-- v-if="superadmin() /*&& getUserPermissionData({ parent: 'Token Management', child: 'Tokens', permission: 'Can_Access_Token_Management' })*/"> -->
                <router-link :to="{ name: 'TestManagement' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Token Management</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="superadmin()">
                <router-link :to="{ name: 'CandidateDetails' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Candidate Details</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="superadmin()">
                <router-link :to="{ name: 'TestCreatorOverview' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Test Creator Overview</span>
                </router-link>
              </li>
              <!-- Added by pmaps team end  -->


            </ul>
          </div>
        </li>


        <li class="nav-item sidebar-team-management-item" v-if="this.permission.roleId != '9 '">
          <a class="nav-link" data-bs-toggle="collapse" href="#Management" role="button" aria-expanded="false"
            aria-controls="Management">
            <!-- <i class="fas fa-users"></i> -->
            <!-- <img
              src="../../public/dashboard-assets/images/M2/managementicon.png"
              alt=""
            /> -->
            <img src="../../public/dashboard-assets/images/M2/sidebar/managementIcon.svg" alt="" style="width:25px" />
            <span class="link-title w-100">Management</span>
            <i class="link-arrow" data-feather="chevron-down"></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-chevron-down link-arrow">
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </a>
          <div class="collapse" data-bs-parent="#nav_accordion" id="Management">
            <ul class="nav sub-menu p-0" id="sidebar-team-management">
              <!--update-->
              <li class="nav-item" v-if="
                //(this.enablePartner || superadmin()) &&
                (this.isParent || superadmin()) &&
                getUserPermissionData({
                  parent: 'Partner Management',
                  child: 'Partner Account',
                  permission: 'Can_Access_Partner',
                })
              ">
                <!--update-->
                <router-link :to="{ name: 'ManagementPartners' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Partners</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="
                getUserPermissionData({
                  parent: 'Groups Management',
                  child: 'Groups Account',
                  permission: 'Can_Access_Region',
                }) && this.PlanMapping">
                <router-link :to="{ name: 'ManagementTeams' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Groups</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="
                getUserPermissionData({
                  parent: 'User Management',
                  child: 'User Account',
                  permission: 'Can_Access_User',
                })
              ">
                <router-link :to="{ name: 'ManagementUsers' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Users</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="
                getUserPermissionData({
                  parent: 'Role Management',
                  child: 'Role Account',
                  permission: 'Can_Access_Role',
                }) && this.PlanMapping">
                <router-link :to="{ name: 'ManagementRoles' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Roles</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!-- <ul class="nav sub-menu p-0" >
          <li
            class="nav-item" >
            <router-link :to="{ name: 'Billing' }" class="nav-link">
              <div class="border-left-active"></div>
              <i class="fas fa-credit-card fa-xl" style="color: #676767;"></i>             
               <span class="link-title w-100" @click="hideSidebar">Billing</span>
            </router-link>    
          </li>
        </ul> -->
        <!-- <li class="nav-item" v-if="this.isEnterprise==false" >
          <a
            class="nav-link"
            data-bs-toggle="collapse"
            href="#Billing"
            role="button"
            aria-expanded="false"
            aria-controls="Management"
          >
          <i class="fas fa-users"></i>
            <i class="fa-solid fa-wallet fa-lg" style="color: #676767;"></i>

            <span class="link-title w-100">Billing</span>
            <i class="link-arrow" data-feather="chevron-down"></i>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-chevron-down link-arrow"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </a>
          <div class="collapse" data-bs-parent="#nav_accordion" id="Billing">
            <ul class="nav sub-menu p-0">
              <li
                class="nav-item"
              
              >
                <router-link
                  :to="{ name: 'BillingOverview' }"
                  class="nav-link"
                >
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar"
                    >Overview</span
                  >
                </router-link>
              </li>
              <li
                class="nav-item"
              
              >
                <router-link
                  :to="{ name: 'BillingPlan' }"
                  class="nav-link"
                >
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar"
                    >Plans</span
                  >
                </router-link>
              </li>
              <li
                class="nav-item"
             
              >
                <router-link :to="{ name: 'BillingInvoice' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar"
                    >Invoice</span
                  >
                </router-link>
              </li>
            
            </ul>
          </div>
        </li> -->

        <li class="nav-item sidebar-settings">
          <a class="nav-link" data-bs-toggle="collapse" href="#Integrations" role="button" aria-expanded="false"
            aria-controls="Integrations">
            <!-- <i class="fa-solid fa-gears fa-lg" style="color: #676767;"></i> -->
            <img src="../../public/dashboard-assets/images/M2/sidebar/settings-gear-svgrepo-com.svg" alt=""
              style="width:25px" />
            <span class="link-title w-100">Settings</span>
            <i class="link-arrow" data-feather="chevron-down"></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-chevron-down link-arrow">
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </a>
          <div class="collapse" data-bs-parent="#nav_accordion" id="Integrations">
            <ul class="nav sub-menu p-0 sidebar-settings">
              <li class="nav-item">
                <router-link :to="{ name: 'Integration' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Career</span>
                </router-link>
              </li>
              <!-- v-if="this.user.isEnterprise==false" -->
              <li class="nav-item" v-if="this.clientRole.roleName == 'Owner' || this.clientRole.roleName == 'Finance'">
                <router-link :to="{ name: 'Details' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Billing</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="this.user.isEnterprise == false">
                <router-link :to="{ name: 'BillingPlans' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Plans</span>
                </router-link>
              </li>
              <li class="nav-item" v-if="this.clientRole.roleName == 'PMaps SuperAdmin'">
                <router-link :to="{ name: 'InvoicePreferenceSetting' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Invoice Preference</span>
                </router-link>
              </li>

              <li class="nav-item" v-if="this.clientRole.roleName == 'PMaps SuperAdmin'">
                <router-link :to="{ name: 'AccountSettings' }" class="nav-link">
                  <div class="border-left-active"></div>
                  <span class="link-title w-100" @click="hideSidebar">Account Settings</span>
                </router-link>
              </li>

            </ul>
          </div>
        </li>

        <li class="nav-item sidebar-settings" v-if="
          getUserPermissionData({
            parent: 'Analytics Platform',
            child: 'Analytics Platform',
            permission: 'Can_Access_Analytics_Platform',
          })
           && superadmin()">
          <a class="nav-link" data-bs-toggle="collapse" href="#Analytic" role="button" aria-expanded="false"
            aria-controls="">
            <!-- <i class="fa-solid fa-gears fa-lg" style="color: #676767;"></i> -->
            <img src="../../public/dashboard-assets/images/M2/sidebar/settings-gear-svgrepo-com.svg" alt=""
              style="width:25px" />
            <span class="link-title w-100">Analytics</span>
            <i class="link-arrow" data-feather="chevron-down"></i>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
              class="feather feather-chevron-down link-arrow">
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </a>
          <div class="collapse" data-bs-parent="#nav_accordion" id="Analytic">
            <ul class="nav sub-menu p-0 sidebar-settings">
              <li class="nav-item">
                <router-link :to="{ name: 'devProcessing' }" class="nav-link">
                  <span class="link-title w-100" @click="hideSidebar">Data Processing</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'devOverview' }" class="nav-link">
                  <span class="link-title w-100" @click="hideSidebar">Overview</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{ name: 'devDescriptive' }" class="nav-link">
                  <!-- <div class="border-left-active"></div> -->
                  <span class="link-title w-100" @click="hideSidebar">Descriptive</span>
                </router-link>
              </li>

              <li class="nav-item">
                <router-link :to="{
                  name: 'devPerformanceModeling',
                }" class="nav-link">
                  <!-- <div class="border-left-active"></div> -->
                  <span class="link-title w-100" @click="hideSidebar">Performance Modeling</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>

        <!-- <ul class="nav sub-menu p-0">
          <li class="nav-item">
            <router-link :to="{ name: 'Details' }" class="nav-link">
              <div class="border-left-active"></div>
              <i class="fa-solid fa-wallet fa-xl" style="color: #676767;"></i>
                <span class="link-title w-100">
                  Billing
                </span>   
            </router-link>     
          </li>
        </ul> -->
      </ul>

      <div class="sidebar-footer">
        Id : {{ this.user.userId }}{{ this.user.clientId }}{{ this.scaleRatio.toPrecision(3) }}

        <!-- <div class="w-100" data-bs-toggle="modal" data-bs-target="#SidebarPartner">
          <div class="form-select">

            <div class="d-flex">
              <div class="PartnerProfile-area">
                <div class="PartnerProfile" style="background-color: #e30b18">
                  V
                </div>
              </div>
              <div class="px-2">
                <h6>Vodafone</h6>
                <small class="text-muted">Enterprise plan</small>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </nav>
  <!-- Sidebar Part -->
</template>

<script>
import { getUserPermissionData } from "../utils/helper";
import { superadmin } from "../utils/helper";
import { mapActions, mapState, mapMutations } from "vuex";
// import { decryptData } from '@/platformFeatures/security';
// import { validatePlanFeatures } from '../platformFeatures/featureUtils';
// import { getNewUser } from '../utils/auth';

export default {
  name: "Sidebar",
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isParent: false,
      //enablePartner: false,
      show1: false,
      isEnterprise: '',
      scaleRatio: 0,
      clientRole: '',
      billing: JSON.parse(localStorage.getItem('billingData')),
      PlanMapping: false
      // showLibraryButton:true,
      // showActiveTestButton:true,
    };
  },
  async created() {
    this.scaleRatio = window.devicePixelRatio;
    var parent = localStorage.getItem("Userpermission");
    this.clientRole = JSON.parse(parent);
    if (this.billing != null) {
      if (this.billing.object.data.plan.planName == 'Scale' || this.billing.object.data.plan.planName == 'Grow') {
        this.PlanMapping = true;
      }
    }
    else if (this.user.isEnterprise == true) {
      this.PlanMapping = true;
    }

    // getUserPermissionData({
    //   parent: 'Analytics Platform',
    //   child: 'Analytics Platform',
    //   permission: 'Can_Access_Analytics_Platform',
    // })
    // console.log()

    // if(this.user.isEnterprise==false) {
    //   this.showLibraryButton=  validatePlanFeatures(this.user.extId,48,'Test Library Access');
    //   this.showActiveTestButton=  validatePlanFeatures(this.user.extId,49,'Active Test Access');
    // }
    // const secretKey = this.user.extId;
    //   const encryptedData = localStorage.getItem('featuresData');
    //   if (encryptedData) {
    //     const decryptedData = decryptData(encryptedData, secretKey);
    //     if (Array.isArray(decryptedData) && decryptedData.length > 0) {
    //       this.PlanFeatures = decryptedData;

    //       if (!this.PlanFeatures.some(item => item.id === 47 && item.name === 'Basic Dashboard Access')) {
    //        this.showDashboardButton = false;
    //       }
    //       if (!this.PlanFeatures.some(item => item.id === 48 && item.name === 'Test Library Access')) {
    //        this.showLibraryButton = false;
    //       }
    //       if (!this.PlanFeatures.some(item => item.id === 49 && item.name === 'Active Test Access')) {
    //        this.showActiveTestButton = false;
    //       }
    //     }
    //   }
  },
  async mounted() {
    this.isEnterprise = this.user.isEnterprise;
    //this.enablePartner = this.user.enablePartner;

    setTimeout(async () => {
      var company = await this.Companyheader({ clientId: this.user.clientId });
      this.isParent = company.object.data.isParent;
    }, 100);
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      userId: (state) => state.auth.user.userId,
      clientId: (state) => state.auth.user.clientId,
      permission: (state) => state.auth.permission,
      pass: (state) => state.auth.parent,
    }),
  },
  methods: {
    ...mapActions(["Companyheader", "GetloggedinPermissions"]),
    ...mapMutations(["setparent"]),
    getUserPermissionData,
    superadmin,
    hideSidebar() {
      this.$emit("hideSidebar");
    },
    toggledashboard() {
      this.show1 = !this.show1;
    },
  },
};
</script>

<style></style>
