<template>
    <div class="page-content">
        <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
            <div class="my-2">
                <h3 class="mb-2 mb-md-0">
                    <div class="Group_top_three_dot">
                        <div class="dropdown">
                            <router-link :to="{
                                name: 'TestCreatorOverview',
                            }" class="p-0" type="button">
                                <img src="../../../public/dashboard-assets/images/M2/BackButton.png" />
                            </router-link>
                            <span class="mx-2">Test Creator</span>
                        </div>

                    </div>
                </h3>
            </div>
        </div>

        <!-- Stepper Starts -->
        <div class="my-4" style="width: 100%;">
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between">
                <div :class="stepperBasicTestDetailsStatus ? 'step completed ' : 'step'" @click="BackTrackStep(1)">
                    <div class="step-icon-wrap">
                        <div class="step-icon">1</div>
                    </div>
                    <h4 class="step-title">Test Details </h4>
                </div>
                <div :class="stepperAddSectionStatus ? 'step completed ' : 'step'" @click="BackTrackStep(2)">
                    <div class="step-icon-wrap">
                        <div class="step-icon">2</div>
                    </div>
                    <h4 class="step-title">Add Sections</h4>
                </div>
                <div :class="stepperValidateSectionStatus ? 'step completed ' : 'step'" @click="BackTrackStep(3)">
                    <div class="step-icon-wrap">
                        <div class="step-icon">3</div>
                    </div>
                    <h4 class="step-title">Review</h4>
                </div>
                <div :class="stepperTestSettingsStatus ? 'step completed ' : 'step'" @click="BackTrackStep(4)">
                    <div class="step-icon-wrap">
                        <div class="step-icon">4</div>
                    </div>
                    <h4 class="step-title">Test Settings</h4>
                </div>
            </div>
        </div>
        <!-- Stepper Ends -->


        <!-- Basic Test Details Starts -->
        <div class="card p-4 mt-4" style="border-radius: 24px;" v-if="basicTestDetailsShow">
            <h4 class="mt-2 mb-4">Test Details</h4>
            <div class="row">
                <div class="col-6">
                    <div class="form-group my-3" style="width: 100%; ">
                        <label class="input-hero">
                            <input type="text" class="input_area" v-model="testName" @change="testNameStatus()">
                            <span class="input-name input-group-kj">Test Name
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group my-3" style="width: 100%; ">
                                <label class="input-hero">
                                    <input type="text" class="input_area">
                                    <span class="input-name input-group-kj">Test Name
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group my-3" style="width: 100%; ">
                                <label class="input-hero">
                                    <input type="text" class="input_area">
                                    <span class="input-name input-group-kj">Job Role
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group my-3" style="width: 100%;">
                                <label class="input-hero">
                                    <input type="text" class="input_area">
                                    <span class="input-name input-group-kj">Language
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group my-3" style="width: 100%;">
                                <label class="input-hero">
                                    <textarea type="text" class="input_area pt-2"
                                        style="min-height: 185px; max-height: 185px;"></textarea>
                                    <span class="input-name input-group-kj">Job Description
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div> -->


            <div style="margin-left: auto;" class="mt-2">
                <button class="btn btn-primary" @click="basicTestDetailsComplete()">Next Step</button>
            </div>
        </div>
        <!-- Basic Test Details Ends -->


        <!-- Add Sections Starts  -->
        <div class="card p-4 mt-4" style="border-radius: 24px;" v-if="addSectionShow">
            <h4 class="mt-2 mb-4">Add Sections
                <div class="float-end">
                    <span style="color: #4b2eee; font-size: medium; cursor:pointer" @click="insertSection()">
                        Insert New Sections



                        <!-- <router-link :to="{
                            name: 'TestSectionCreator',
                            query: {
                                page: this.encode('test'),
                            },
                        }" class="p-0" type="button">
                        </router-link> -->
                    </span>
                </div>
            </h4>

            <!-- <span style="margin-left: auto;">
                hi
            </span> -->
            <div style="display: flex; align-items: center;">
                <span class="m-2"
                    style="border-radius: 7px; box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25); display: flex; width: 100%; max-width: 300px;">
                    <span style="padding-top: 16px; padding-bottom: 16px; padding-left: 12px; padding-right: 12px;">
                        <i class="fa-solid fa-magnifying-glass" style="color: #B4B4B4;"></i>
                    </span>
                    <input type="text" v-model="searchInput" @input="SectionList('search')" placeholder="Search"
                        style="width: 100%; height: 100%; min-height:48px; border: none; align-self:center;">
                </span>
                <span style="margin-left: auto; z-index: 6">
                    <div class="top_heading_CreatePartner_btn top_heading_CreatePartner_btn-kj d-block mx-2"
                        style="z-index: 4;">
                        <div class="Group_top_three_dot" id="tour-filter-web-icon">
                            <div class="dropdown">
                                <button class="btn p-0 Group_top_three_dot_dropdown position-relative" type="button"
                                    id="dropdownMenuButton1" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                                    aria-expanded="false" aria-haspopup="true" style="line-height: 0.3">
                                    <img src="../../../public/dashboard-assets/images/M2/Filter_icon.png" />

                                    <!-- <span class="Noti-alert-kj translate-middle" v-if="this.applyfilter > 0">
                                        {{ this.applyfilter }} <span class="visually-hidden"></span>
                                    </span> -->
                                </button>

                                <ul class="dropdown-menu dropdown-menu-kj dropdown-menu-kj-w"
                                    aria-labelledby="dropdownMenuButton1">
                                    <div class="modal-header">
                                        <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                                            Filter
                                        </h4>
                                        <button @click="filterclose" type="button" class="btn-close"></button>
                                    </div>

                                    <!-- <ul class="nav nav-pills mb-3 nav-fill" id="pills-tab" role="tablist">

                                        <li class="nav-item" role="presentation">
                                            <button class="nav-link text-muted text-center active"
                                                id="pills-profile-tab" data-bs-toggle="pill" @click="
                                                    this.filterTab = 2;
                                                this.searchfilter = '';
                                                this.Filterresult();
                                                " data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                                aria-selected="false">
                                                Types
                                            </button>
                                        </li>
                                    </ul> -->
                                    <div class="tab-content" id="pills-tabContent">

                                        <div class="tab-pane fade active show p-0" id="pills-profile" role="tabpanel"
                                            aria-labelledby="pills-profile-tab">
                                            <div class="ul-li-checkbox-kj">
                                                <ul>
                                                    <li v-for="type in sectionType" :key="type">
                                                        <input type="checkbox" :value="type.value" name=""
                                                            @change="addFilter(type.value, $event)"
                                                            :checked="this.applyfilter.includes(type.value)" />
                                                        <span>{{ type.label }} </span>
                                                    </li>
                                                </ul>
                                                <div class="text-end">
                                                    <button type="button" class="btn-Apply-kj-color"
                                                        v-if="this.applyfilter.length > 0" @click="clearFilters()">
                                                        Clear Filter
                                                    </button>

                                                    <button type="button" class="modail_submit_button text-end"
                                                        :class="{ active_form_button: this.applyfilter.length > 0 }"
                                                        :disabled="applyfilter.length == 0" @click="applyFilters()">
                                                        Apply
                                                    </button>
                                                </div>
                                                <!-- <div class="text-end">
                          <button type="button" class="btn-Apply-kj-color">
                            Clear Filter
                          </button>
                          <button type="button" class="btn-primary btn-Apply-kj">
                            Apply
                          </button>
                        </div> -->
                                            </div>
                                        </div>

                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </span>
            </div>


            <div class="p-2 mt-3 mx-3"
                style="box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25); border-radius: 20px; position: sticky; top: 70px; background-color: white; z-index: 5; "
                v-if="draggableList.length > 0">
                <div class="p-2" style="font-weight: 600;">
                    Sections Added,
                </div>
                <ul class="pt-1" style="display: flex; flex-wrap: wrap; list-style-type: none; gap: 10px; ">
                    <li v-for="(item, index) in draggableList" :key="item.id">
                        <div style="padding: 10px; border-radius: 20px; background-color: #e4dfff;">
                            <span class="mx-3">{{ item.name }}</span>
                            <span @click="RemoveItem(index)" style="cursor: pointer;">
                                <i class="fa-solid fa-xmark" style="color: gray;"></i>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>


            <div class="row mt-3">
                <div class="col-12 mt-3 ">
                    <div class="card py-3 px-4"
                        style="background-color: #F9FAFB; border-radius: 24px; box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;">
                        <div class="row">
                            <div class="col-12 col-md-4 col-sm-6 col-lg-4 col-xl-4"
                                v-for="(item, index) in this.allSectionsList" :key="item">
                                <div class="section-item p-3">
                                    <div class="mt-2 mb-4  section-type">{{ SectionTypeCheck(item.competencyType) }}
                                    </div>
                                    <div class="section-name mt-1">{{ item.sectionName }} {{ item.sectionMasterId }}
                                    </div>
                                    <div class="d-flex mt-3">
                                        <div class="section-tabs">
                                            <i class="fa-regular fa-circle-question fa-lg"
                                                style="color: #B4B4B4;"></i><span class="mx-1">{{ item.noOfQuestion }}
                                                Questions</span>
                                        </div>
                                        <div class="section-tabs mx-4">
                                            <i class="fa-regular fa-clock fa-lg" style="color: #B4B4B4;"></i><span
                                                class="mx-1">
                                                {{ ConvertMinutes(item.sectionTime) }}</span>
                                        </div>
                                    </div>
                                    <div class="section-description mt-4">Evaluate core customer service skills with our
                                        Customer Service Assessment Test.</div>
                                    <div class="section-button-body mx-2 mb-2 mt-4">
                                        <div class="test-primary-button"
                                            @click="AddItem(index, item, item.sectionName)">Add
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my-4">
                            <paginate v-model="currentPage" :page-count="Page_count" :margin-pages="1" :page-range="1"
                                :click-handler="clickCallback" :prev-text="'< Prev'" :next-text="'Next >'"
                                :container-class="'pagination'" :page-class="'page-item'">
                            </paginate>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-md-4 col-sm-12 col-lg-4 col-xl-4 my-3">
                    <div class="card p-3" style="background-color: #F9FAFB; border-radius: 24px; box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;
                         width: 100%;  min-height: 300px;  
                        ">
                        <div class="my-2" style="color: #333333;" v-if="draggableList.length > 0">
                            You have added {{ draggableList.length }} sections in your test
                        </div>
                        <div>
                            <ul ref="sortableList">
                                <li v-for="( item, index) in draggableList" :key="item" class="drag-item">

                                    <div class="row">
                                        <div class="col-xl-1" style="align-content:center">
                                            <i class="fa-solid fa-grip-vertical mx-2" style="color: gray"></i>
                                        </div>
                                        <div class="col-xl-10">
                                            <div style="color: grey; font-size: small;">Behavioural</div>
                                            <div class="mt-1">{{ item.name
                                                }}</div>
                                            <div class="d-flex mt-3">
                                                <div class="mx-1"></div>
                                                <div class="section-tabs">
                                                    5 Questions
                                                </div>
                                                <div class="section-tabs mx-2">
                                                    5 Mins
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-1" @click="RemoveItem(index)" style="align-content:center">
                                            <i class="fa-solid fa-xmark " style="color: gray; cursor: pointer"></i>
                                        </div>


                                    </div>

                                </li>
                            </ul>
                        </div>
                        <div style="text-align: center;" v-if="draggableList.length == 0">
                            <img src="../../../public/dashboard-assets/images/M2/Partner_blank.png"
                                style="max-width: 300px; width:100%" />
                            <div class="mt-4 mb-2">Please Add Sections</div>
                        </div>
                    </div>
                </div> -->

            </div>

            <div style="margin-left: auto;" class="mt-3">
                <button class="btn btn-primary" @click="addSectionComplete()">Next Step</button>
            </div>
        </div>
        <!-- Add Sections Ends  -->



        <!-- Choose Questions Starts  -->
        <div class="card p-4 mt-4" style="border-radius: 24px;" v-if="validateSectionShow">
            <h4 class="mt-2 mb-4">
                <!-- Choose Questions (No of Questions) -->
                {{ testName }} Assessment
                <!-- <div class="float-end">
                    <span style="color: #4b2eee; font-size: medium;">
                        Insert New Sections
                    </span>
                </div> -->
            </h4>
            <!-- <div>
                <h2>Draggable Blocks</h2>
                <draggable v-model="items" class="draggable-container" tag="div" :animation="200">
                    <div v-for="item in selectedSectionList" :key="item" class="draggable-block">
                        {{ item.sectionName }}
                    </div>
                </draggable>
            </div> -->
            <!-- {{ selectedList }} -->
            <div class="table-responsive m-1">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Section Name</th>
                            <th>Time</th>
                            <th>Type</th>
                            <!-- <th>Difficulty</th> -->
                            <th>Number of Questions</th>
                            <th>Preview</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody ref="sortableTable">
                        <tr v-for="(item, index) in selectedList" :key="item.id">
                            <td class="drag-handle">&#x2630;</td>
                            <td>{{ item.sectionName }}</td>
                            <td>{{ ConvertMinutes(item.sectionTime) }}</td>
                            <td> <span class="section-type">{{ SectionTypeCheck(item.competencyType) }}</span></td>
                            <!-- <td>{{ item.difficulty }}</td> -->
                            <td>{{ item.noOfQuestion }}</td>
                            <td>
                                <i class="fa-regular fa-eye"></i>
                            </td>
                            <td>
                                <span @click="EditSection(item)" class="p-2 remove-button">
                                    <i class="fa-regular fa-pen-to-square"></i>
                                </span>
                                <!-- <span @click="GetOffcanvasEditAddQuestions(item.sectionMasterId)"
                                    class="p-2 remove-button">
                                    <i class="fa-solid fa-plus" data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasAddQuestions"
                                        aria-controls="offcanvasAddQuestions"></i>
                                </span> -->

                                <button class="btn btn-primary remove-button" @click="RemoveItem(index)">
                                    Remove
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div>

            <!-- {{ selectedList }} -->

            <!-- <div class="card p-2 mt-3"
                style="background-color: #F9FAFB; border-radius: 24px; box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;">
                <h5 class="mt-4 mb-4 mx-4" style="font-weight: 600;">Attention Of Details
                    <div class="float-end">
                        <span style="color: #4b2eee; font-size: medium;" class="mx-1 ">
                            <i class="fa-solid fa-trash mx-1"></i> Remove Section
                        </span>
                    </div>
                </h5>
                <div class="card p-2 mx-4 mb-4 mt-2" style="border-radius: 24px;">
                    <div class="table-responsive m-2 ">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Time</th>
                                    <th>Type</th>
                                    <th>Difficulty</th>
                                    <th>Preview</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Aptitude</td>
                                    <td>3 min</td>
                                    <td>Option Single</td>
                                    <td>Average</td>
                                    <td><i class="fa-regular fa-eye"></i></td>
                                    <td>
                                        <span class="btn btn-primary add-button">Add</span>
                                        <span class="btn btn-primary remove-button">Remove</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Aptitude</td>
                                    <td>3 min</td>
                                    <td>Option Single</td>
                                    <td>Average</td>
                                    <td><i class="fa-regular fa-eye"></i></td>
                                    <td>
                                        <span class="btn btn-primary add-button">Add</span>
                                        <span class="btn btn-primary remove-button">Remove</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Aptitude</td>
                                    <td>3 min</td>
                                    <td>Option Single</td>
                                    <td>Average</td>
                                    <td><i class="fa-regular fa-eye"></i></td>
                                    <td>
                                        <span class="btn btn-primary add-button">Add</span>
                                        <span class="btn btn-primary remove-button">Remove</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div> -->

            <div style="margin-left: auto;" class="mt-3">
                <button class="btn btn-primary" @click="validateSectionComplete()">Next Step</button>
            </div>
        </div>
        <!--  Choose Questions Ends-->

        <!-- Test Setting Starts -->
        <div class="card p-4 mt-4" style="border-radius: 24px;" v-if="testSettingsShow">
            <h4 class="mt-2 mb-4">Settings And Launch</h4>
            <div class="row mt-3">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="CreatePartnerModal-img-area">
                                <div class="CreatePartnerModal-img mb-2" style="background: purple;">
                                    <img alt="" class="CreatePartnerModal-pic" />
                                    <div class="CreatePartnerModal-img-edit">
                                        <input type="file" name="" id="file-input_icon1" class="file-upload"
                                            accept="image/*" />
                                        <label style="cursor: pointer" class="upload-button" for="file-input_icon1">
                                            <i class="fa-solid fa-pen"></i></label>
                                    </div>
                                </div>
                                <p>Company Logo</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="me-4">
                                <div class="d-flex justify-content-between align-items-center mb-4">
                                    <span>
                                        <img src="../../../public/dashboard-assets/images/M2/date-time-icon.png"
                                            class="icon-img-kj-active" alt="" />Test Expiration Setting
                                    </span>
                                    <span class="cursor-pointer">
                                        <a class="cursor-pointer" @click="this.showAutoExpiryModal()"> Add </a>
                                    </span>
                                </div>
                            </div>
                            <div class="me-4">
                                <div>Enable Anti cheating measures</div>
                                <button :class="{ 'toggle-button': true, 'active': false }" class="mt-2">
                                </button>
                            </div>
                            <div class="me-4">
                                <div>Generate Publicly Accessible Link </div>
                                <button :class="{ 'toggle-button': true, 'active': true }" class="mt-2">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group my-3" style="width: 100%;">
                                <label class="input-hero">
                                    <textarea type="text" class="input_area pt-2"
                                        style="min-height: 110px; max-height: 110px;"></textarea>
                                    <span class="input-name input-group-kj">Instructions (optional)
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div class="col-12 mt-6">
                            <div class="form-group my-3" style="width: 100%;">
                                <label class="input-hero">
                                    <input type="text" class="input_area">
                                    <span class="input-name input-group-kj">Instructions Time ( optional )
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <!-- <div class="mt-2">
                <div class="row mt-3">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group my-3" style="width: 100%; ">
                                    <label class="input-hero">
                                        <input type="text" class="input_area">
                                        <span class="input-name input-group-kj">Header Background Color
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group my-3" style="width: 100%;">
                                    <label class="input-hero">
                                        <input type="text" class="input_area">
                                        <span class="input-name input-group-kj">Header Foreground Color
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> -->
            <div style="margin-left: auto;" class="mt-6">
                <button class="btn btn-primary" @click="UpdateTestDetails()">Launch</button>
            </div>
        </div>
        <!-- Test Setting Ends -->

        <div class=" modal fade" id="SetAutoExpiry" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable model-sm ">
                <div class="modal-content border-radius-kj-20 ">
                    <div class="modal-header token-model-header-color">
                        <h5 class="modal-title text-centerm-auto">
                            <span class="font-size-20-kj"> Auto Expire Test</span>
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="form-group my-4 ">
                            <div class="form-group mb-4">
                                <label class="input-hero">
                                    <input type="number" class="form-control" v-model="this.autoExpiryDay" :min="1"
                                        :max="30" @input="blockDecimal" :step="1" />
                                    <span class="input-name">
                                        Days after which the attempt should auto expire
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="text-end test_instance_button mt-4">
                            <button class="secondary-button1 mx-3" type="button" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" class="btn btn-primary btn-download" @click="SetAutoExpirySettings">
                                Set Expiry
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- OffCanvas Details Edit Section Starts -->
        <!-- <div class="offcanvas offcanvas-end role_item_view_detail_sidebar" data-bs-scroll="false"
            data-bs-backdrop="static" tabindex="-1" id="offcanvasEditSection"
            aria-labelledby="offcanvasEditSectionLabel">
            <div>
                <h5 style="color: white; font-size:larger; width: 90% !important;">
                </h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
                    @click="clearEditOffCanvas"></button>
            </div>
            <div class="offcanvas-body">
                <h4 class="mt-2">
                    Edit Section
                </h4>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="form-group mb-3 mt-4" style="width: 100%;">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="editSectionName" required>
                                <span class="input-name input-group-kj">Section Name
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12">

                        <div class="form-group mb-3 mt-4" style="width: 100%;">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="editSectionType" required>
                                <span class="input-name input-group-kj">Section Type
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="form-group mb-3 mt-4" style="width: 100%;">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="editSectionPassingScore" required>
                                <span class="input-name input-group-kj">Passing Score
                                </span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4">

                        <div class="form-group mb-3 mt-4" style="width: 100%;">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="editSectionNumQuestions" required>
                                <span class="input-name input-group-kj">No of Questions
                                </span>
                            </label>
                        </div>

                    </div>
                    <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                        <div class="form-group mb-3 mt-4" style="width: 100%;">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="editSectionMarks" required>
                                <span class="input-name input-group-kj">Marks
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-12 ">
                        <div class="form-group mb-3 mt-4" style="width: 100%;">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="editCompetencyType" required>
                                <span class="input-name input-group-kj">Competency Type
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="form-group mb-3 mt-4" style="width: 100%;">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="editCompetencyId" required>
                                <span class="input-name input-group-kj">Competency Id
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="form-group mb-3 mt-4" style="width: 100%;">
                            <label class="input-hero">
                                <input type="text" class="input_area" v-model="editRangeLevel" required>
                                <span class="input-name input-group-kj">Range Level
                                </span>
                            </label>
                        </div>
                    </div>

                </div>
            </div>
        </div> -->
        <!-- OffCanvas Details Edit Section Ends -->


        <!-- OffCanvas Details Questions Starts -->
        <div class="offcanvas offcanvas-end role_item_view_detail_sidebar" data-bs-scroll="false"
            data-bs-backdrop="static" tabindex="-1" id="offcanvasAddQuestions"
            aria-labelledby="offcanvasAddQuestionsLabel">
            <div>
                <h5 style="color: white; font-size:larger; width: 90% !important;">
                </h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"
                    @click="clearEditOffCanvas"></button>
            </div>
            <div class="offcanvas-body">
                <h4 class="mt-2">
                    Add Questions
                </h4>
                <div class="row mt-3">
                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <Multiselect v-model="selectedQuestionCategories" placeholder="Add Category"
                                    :close-on-select="true" :options="formattedQuestionCategoryList" :searchable="true"
                                    :group-select="false" @change="QuestionBankList()">
                                </Multiselect>
                                <span class="input-name">
                                    Question Categories
                                </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <Multiselect v-model="selectedQuestionBank" placeholder="Add From Bank"
                                    :close-on-select="true" :options="formattedQuestionBankList" :searchable="true"
                                    :group-select="false" @change="QuestionTypeList()">
                                </Multiselect>
                                <span class="input-name">
                                    Question Bank
                                </span>
                            </label>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <Multiselect v-model="selectedQuestionType" placeholder="Add Type"
                                    :close-on-select="true" :options="formattedQuestionTypeList" :searchable="true"
                                    :group-select="false" @change="QuestionDifficultyList()">
                                </Multiselect>
                                <span class="input-name">
                                    Question Type
                                </span>
                            </label>
                        </div>
                    </div>


                    <div class="col-12">
                        <div class="form-group my-3">
                            <label class="input-hero">
                                <Multiselect v-model="selectedDifficultyType" placeholder="Add Difficulty"
                                    :close-on-select="true" :options="formattedQuestionDifficultyList"
                                    :searchable="true" :group-select="false">
                                </Multiselect>
                                <span class="input-name">
                                    Question Difficulty
                                </span>
                            </label>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!-- OffCanvas Details Questions Ends -->


    </div>
</template>
<script>
// import Sortable from 'sortablejs';

import { mapState, mapActions } from "vuex";
import moment from "moment";
import Paginate from "vuejs-paginate-next";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { SetClarityData } from "../../platformFeatures/featureUtils";

export default {
    components: {
        paginate: Paginate,
        Multiselect
    },
    data() {
        return {
            draggableList: [],
            sortableList: null,
            allSectionsList: [],
            search: "1,2,3",
            offset: 0,
            limit: 40,
            Page_count: 1,
            CognitiveTabStatus: false,
            BehaviouralTabStatus: false,
            SkillTabStatus: false,
            stepperBasicTestDetailsStatus: false,
            stepperAddSectionStatus: false,
            stepperValidateSectionStatus: false,
            stepperTestSettingsStatus: false,
            basicTestDetailsShow: true,
            addSectionShow: false,
            validateSectionShow: false,
            testSettingsShow: false,
            searchInput: null,
            sectionType: [
                { value: 1, label: "Cognitive" },
                { value: 2, label: "Behavioural" },
                { value: 3, label: "Skills" }
            ],
            applyfilter: [],
            selectedSectionList: [
                { sectionName: 'Aptitude 1', time: '3:00', type: 'Option Single', difficulty: 'Medium', noOfQuestions: 10, preview: 'link' },
                { sectionName: 'Aptitude 2', time: '4:00', type: 'Option Single', difficulty: 'Medium', noOfQuestions: 10, preview: 'link' },
                { sectionName: 'Aptitude 3', time: '5:00', type: 'Option Single', difficulty: 'Medium', noOfQuestions: 10, preview: 'link' }
            ],
            selectedList: [],
            testName: "",
            createDetails: [],
            profilePhoto: null,
            testIsPublic: 0,
            testNameChangeStatus: false,

            // Edit Section Offcanvas Start
            editSectionName: '',
            editSectionType: null,
            editSectionPassingScore: null,
            editSectionNumQuestions: null,
            editSectionMarks: null,
            editCompetencyType: null,
            editCompetencyId: null,
            editRangeLevel: null,
            // Edit Section Offcanvas End


            // Add Question Offcanvas Start
            selectedSectionId: null,
            questionCategoryList: [],
            selectedQuestionCategories: null,
            questionBankList: [],
            selectedQuestionBank: null,
            selectedQuestionType: null,
            questionTypeList: [],
            selectedDifficultyType: null,
            questionDifficultyType: [],
            sectionTypeList: [],
            // Add Question Offcanvas End
        }
    },
    async created() {
        if (localStorage.getItem('CreatorMode') != null) {
            var localData = JSON.parse(localStorage.getItem('CreatorMode'));


            if (localData.basicTestDetailsStatus) {
                this.stepperBasicTestDetailsStatus = localData.basicTestDetailsStatus;

                var testData = await this.GetTestCreationTestFetchByTestId({
                    testId: localData.testId
                });
                console.log("TestData: ", testData);

                this.createDetails = testData.object.data[0];
                console.log(this.createDetails.testId);

                this.testName = testData.object.data[0].testName;
            }

            if (localData.addSectionStatus) {
                this.stepperAddSectionStatus = localData.addSectionStatus;

                var sectionData = await this.GetTestCreationSectionFetchByTestId({
                    testId: localData.testId
                });
                console.log("Section: ", sectionData.object.data);
                if (sectionData.status) {
                    // this.selectedList.push(sectionData.object.data);

                    sectionData.object.data.forEach((ele) => {
                        this.selectedList.push(ele);

                        this.draggableList.push({ id: ele.secId, name: ele.sectionName });
                    })
                }
            }

            if (localData.addValidateSectionStatus) {
                this.stepperValidateSectionStatus = localData.addValidateSectionStatus;
            }

            if (this.stepperBasicTestDetailsStatus && !this.stepperAddSectionStatus && !this.stepperValidateSectionStatus) {
                this.basicTestDetailsShow = false;
                this.addSectionShow = true;
                this.validateSectionShow = false;
                this.testSettingsShow = false;
            }
            else if (this.stepperBasicTestDetailsStatus && this.stepperAddSectionStatus && !this.stepperValidateSectionStatus) {
                this.basicTestDetailsShow = false;
                this.addSectionShow = false;
                this.validateSectionShow = true;
                this.testSettingsShow = false;
            }
            else if (this.stepperBasicTestDetailsStatus && this.stepperAddSectionStatus && this.stepperValidateSectionStatus) {
                this.basicTestDetailsShow = false;
                this.addSectionShow = false;
                this.validateSectionShow = false;
                this.testSettingsShow = true;
            }






        }
    },
    async mounted() {

        SetClarityData();

        var data = this.Companyheader({
            clientId: this.user.clientId
        });

        if (data.status) {
            this.profilePhoto = data.object.data.profileImage;
        }
        await this.SectionList();


        // Sortable.create(this.$refs.sortableList, {
        //     animation: 150,
        //     onEnd: this.onDragEnd,
        // });

        // Sortable.create(this.$refs.sortableTable, {
        //     handle: ".drag-handle",
        //     animation: 200,
        //     onEnd: (evt) => {
        //         const movedItem = this.selectedSectionList.splice(evt.oldIndex, 1)[0];
        //         this.selectedSectionList.splice(evt.newIndex, 0, movedItem);
        //     },
        // });

        // const el = this.$refs.sortableTable;
        // if (el) {
        //     Sortable.create(el, {
        //         animation: 150,
        //         onEnd: (evt) => {
        //             const oldIndex = evt.oldIndex;
        //             const newIndex = evt.newIndex;
        //             const movedItem = this.selectedSectionList.splice(oldIndex, 1)[0];
        //             this.selectedSectionList.splice(newIndex, 0, movedItem);
        //         },
        //     });
        // }
        this.initializeSortable();

    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    // unmounted() {
    //     localStorage.removeItem("CreatorMode");
    // },
    methods: {
        ...mapActions([
            "GetTestCreationSectionFetch",
            "postTestCreationTestCreate",
            "PutTestCreationTestUpdate",
            "GetTestCreationQuestionBankFetchCategory",
            "GetTestCreationQuestionCategoryFetch",
            "GetTestCreationQuestionTypeFetchCategoryBank",
            "GetTestCreationQuestionDifficultyFetchCategoryBankType",
            "GetTestCreationSectionTypes",
            "ShowWarningMessage",
            "PostTestCreationSectionQuestionMap",
            "GetTestCreationSectionQuestionsMap",
            "PostTestCreationSectionAdd",
            "Companyheader",
            "GetTestCreationSectionFetchByTestId",
            "GetTestCreationTestFetchByTestId"
        ]),
        initializeSortable() {
            // Sortable.create(this.$refs.sortableContainer, {
            //     animation: 150,
            //     handle: ".sortable-block",
            //     onEnd: (event) => {
            //         this.onDragEnd(event);
            //     },
            // });

        },
        // onDragEnd(event) {
        //     const { oldIndex, newIndex } = event;
        //     const movedItem = this.items.splice(oldIndex, 1)[0];
        //     this.items.splice(newIndex, 0, movedItem);
        // },
        basicTestDetailsComplete() {
            if (this.testName != "") {
                this.createTest();

                if (this.stepperBasicTestDetailsStatus) {
                    this.basicTestDetailsShow = false;
                    this.addSectionShow = true;
                    window.scrollTo(0, 0);
                }
            }
            else {
                return this.ShowWarningMessage(`Please Add Test Name Before Proceeding`);
            }
        },

        testNameStatus() {
            this.testNameChangeStatus = true;
        },

        BackTrackStep(id) {
            if (id == 1) {
                if (this.stepperBasicTestDetailsStatus) {
                    this.basicTestDetailsShow = true;
                    this.addSectionShow = false;
                    this.validateSectionShow = false;
                    this.testSettingsShow = false;
                }
            }
            else if (id == 2) {
                if (this.stepperBasicTestDetailsStatus && this.stepperAddSectionStatus) {
                    this.basicTestDetailsShow = false;
                    this.addSectionShow = true;
                    this.validateSectionShow = false;
                    this.testSettingsShow = false;
                }

            }
            else if (id == 3) {
                if (this.stepperBasicTestDetailsStatus && this.stepperAddSectionStatus && this.stepperValidateSectionStatus) {
                    this.basicTestDetailsShow = false;
                    this.addSectionShow = false;
                    this.validateSectionShow = true;
                    this.testSettingsShow = false;
                }

            }
            else if (id == 4) {
                if (this.stepperBasicTestDetailsStatus && this.stepperAddSectionStatus && this.stepperValidateSectionStatus && this.stepperTestSettingsStatus) {
                    this.basicTestDetailsShow = false;
                    this.addSectionShow = false;
                    this.validateSectionShow = false;
                    this.testSettingsShow = true;
                }

            }
        },
        EditSection(item) {
            this.SetTestCreatorDataInLocalStorage();
            this.$router.push({
                name: 'TestSectionCreator',
                query: {
                    id: this.encode(item.sectionMasterId),
                    status: this.encode(true),
                    page: this.encode('test'),
                },
            })
        },
        async GetOffcanvasEditAddQuestions(id) {
            await this.GetTestCreationSectionQuestionsMap({
                sectionMasterId: id
            });
        },
        async SectionAdd() {
            var data = this.selectedList.map(item => item.sectionMasterId);
            if (data.length > 0) {
                var addData = await this.PostTestCreationSectionAdd({
                    testId: this.createDetails.testId,
                    SectionMasterIds: data,
                });
            }
            if (addData.status) {
                this.stepperAddSectionStatus = true;
            }


        },

        clearEditOffCanvas() {
            this.editSectionName = '';
            this.editSectionType = null;
            this.editSectionPassingScore = null;
            this.editSectionNumQuestions = null;
            this.editSectionMarks = null;
            this.editCompetencyType = null;
            this.editCompetencyId = null;
            this.editRangeLevel = null;
        },

        SetTestCreatorDataInLocalStorage() {
            var model;
            if (this.stepperBasicTestDetailsStatus) {
                model = {
                    basicTestDetailsStatus: this.stepperBasicTestDetailsStatus
                };
                model.testId = this.createDetails.testId;

            }
            if (this.stepperAddSectionStatus) {
                model.addSectionStatus = this.stepperAddSectionStatus;
            }

            if (this.stepperValidateSectionStatus) {
                model.addValidateSectionStatus = this.stepperValidateSectionStatus;
            }

            localStorage.setItem('CreatorMode', JSON.stringify(model));
        },
        insertSection() {

            this.SetTestCreatorDataInLocalStorage();
            this.$router.push({
                name: 'TestSectionCreator',
                query: {
                    page: this.encode('test'),
                },
            });
        },
        async createTest() {
            if (this.testNameChangeStatus) {
                var data = await this.postTestCreationTestCreate({
                    testName: this.testName,
                    clientId: this.user.clientId,
                    rightLogo: this.profilePhoto
                });
                if (data.status) {
                    this.createDetails = data.object.data;
                    this.stepperBasicTestDetailsStatus = true;
                    this.basicTestDetailsShow = false;
                    this.addSectionShow = true;
                    this.testNameChangeStatus = false;

                    console.log(this.createDetails);
                }
            }

        },
        async UpdateTestDetails() {
            var data = await this.PutTestCreationTestUpdate({

                testId: this.createDetails.testId,
                clientId: this.user.clientId,
                // testName: "string",
                // description: "string",
                // instruction: "string",
                // tesTime: "",
                // isPublic: true,
                // leftLogo: "string",
                // rightLogo: "string",
                // languageId: 0,
                // testInstructionsHasTime: true,
                // instructionTime: 0,
                // showInstructionTime: true,
                // headerBackgroundColor: "string",
                // headerForegroundColor: "string",
                status: 1,

            });
            console.log(data);
        },
        async addSectionComplete() {
            if (!this.stepperAddSectionStatus) {
                await this.SectionAdd();
            }
            if (this.stepperAddSectionStatus) {
                this.addSectionShow = false;
                this.validateSectionShow = true;
            }

            window.scrollTo(0, 0);
        },

        validateSectionComplete() {
            this.stepperValidateSectionStatus = true;
            this.validateSectionShow = false;
            this.testSettingsShow = true;

            window.scrollTo(0, 0);
        },

        onDragEnd(event) {
            const movedItem = this.draggableList.splice(event.oldIndex, 1)[0];
            this.draggableList.splice(event.newIndex, 0, movedItem);
        },

        RemoveItem(index) {
            this.draggableList.splice(index, 1);
            this.selectedList.splice(index, 1);
        },

        AddItem(sectionId, item, sectionName) {
            const exists = this.draggableList.some(item => item.id === sectionId);
            if (exists) {
                return this.ShowWarningMessage(`Section ${sectionName} already selected for the test`);
            } else {
                this.selectedList.push(item);
                this.draggableList.push({ id: sectionId, name: sectionName });
            }
        },

        sectionListModel(filter, input) {
            var model = {
                offset: this.offset,
                limit: this.limit,
                status: 1,
            };

            if (filter != null) {
                model.search = this.search;
            }

            if (input != null) {
                model.searchName = this.searchInput;
                if (!this.searchInput || this.searchInput.length === 0) {
                    delete model.searchName;
                }
            }
            return model;
        },

        async SectionList() {

            var model = this.sectionListModel(this.search, this.searchInput);
            var data = await this.GetTestCreationSectionFetch(model);
            if (data.status) {
                this.allSectionsList = data.object.data.allSections;
            } else {
                this.allSectionsList = [];
            }
            this.Page_count = this.allSectionsList ? Math.ceil(data.object.data.filterCount / this.limit) : 0;
        },
        ConvertMinutes(time) {
            // const duration = moment.duration(time);
            // return duration.asMinutes();
            // const duration = moment.duration(time);
            // const hours = Math.round(duration.asHours());
            // const minutes = Math.round(duration.minutes());
            // const seconds = Math.round(duration.seconds());

            // let result = '';
            // if (hours > 0) {
            //     result += `${hours} hr `;
            // }
            // if (minutes > 0) {
            //     result += `${minutes} min `;
            // }
            // if (seconds > 0) {
            //     result += `${seconds} sec`;
            // }
            // return result.trim();


            const timeParts = moment(time, "HH:mm:ss");

            if (timeParts.isValid()) {
                const hours = timeParts.hours();
                const minutes = timeParts.minutes();
                const seconds = timeParts.seconds();

                let timeStrings = [];
                if (hours > 0) timeStrings.push(`${hours} hr`);
                if (minutes > 0) timeStrings.push(`${minutes} min`);
                if (seconds > 0) timeStrings.push(`${seconds} sec`);

                return timeStrings.join(" ");
            }
        },
        SectionTypeCheck(data) {
            if (data == 1) {
                return "Cognitive";
            }
            else if (data == 2) {
                return "Behavioural";
            }
            else if (data == 3) {
                return "Skill";
            }
        },
        clickCallback: function (pageNum) {
            this.offset = (pageNum - 1) * this.limit + 1;
            this.SectionList();
        },
        resetTabs() {
            this.CognitiveTabStatus = false;
            this.BehaviouralTabStatus = false;
            this.SkillTabStatus = false;

        },
        // customTab(num) {

        //     if (num == 1) {
        //         if (this.CognitiveTabStatus) {
        //             this.CognitiveTabStatus = false;
        //             this.search = '1,2,3';
        //         }
        //         else {
        //             this.CognitiveTabStatus = true;
        //             this.offset = 0;
        //             this.search = '1';
        //         }

        //     }

        //     if (num == 2) {
        //         if (this.BehaviouralTabStatus) {
        //             this.BehaviouralTabStatus = false;
        //             this.search = '1,2,3';
        //         }
        //         else {
        //             this.BehaviouralTabStatus = true;
        //             this.offset = 0;
        //             this.search = '2';
        //         }

        //     }

        //     if (num == 3) {
        //         if (this.SkillTabStatus) {
        //             this.SkillTabStatus = false;
        //             this.search = '1,2,3';

        //         }
        //         else {
        //             this.SkillTabStatus = true;
        //             this.offset = 0;
        //             this.search = '3';
        //         }

        //     }
        //     this.SectionList();
        // },


        addFilter(value, e) {
            if (e.target.checked) {
                this.applyfilter.push(value);
            }
            else {
                this.applyfilter.splice(
                    Object.freeze(
                        JSON.parse(JSON.stringify(this.applyfilter))
                    ).indexOf(value),
                    1
                );
            }
        },
        showAutoExpiryModal() {
            window.$("#SetAutoExpiry").modal("show");
        },
        applyFilters() {
            // localStorage.setItem("TestCreatorFilters", JSON.stringify(this.filters));
            this.search = this.applyfilter.toString();
            document.getElementById("dropdownMenuButton1").click();
            this.SectionList();
        },
        clearFilters() {
            this.applyfilter = [];
            this.search = "1,2,3";
            this.SectionList();
        },
        filterclose() {
            document.getElementById("dropdownMenuButton1").click();
        },
        encode(item) {
            return window.btoa(item);
        },


    },
}
</script>

<style scoped>
/* ul {
    list-style: none;
    margin-left: -30px;
} */

.drag-item {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px #7d7d7d40;
    padding: 8px;
    margin: 8px 0;
    margin-left: -30px;
    cursor: move;
    color: #474747;
    user-select: none;
    height: 100%;
    max-height: max-content;
}

.add-button {
    font-weight: 600;
    border: 2px solid #4b2eee;
    /* margin: 5%; */
    border-radius: 15px;
}

.remove-button {
    background-color: #F1F0FF;
    /* margin-left: 5%; */
    color: #4b2eee;
    font-weight: 600;
    margin: 5%;
    border-radius: 15px;
    cursor: pointer;
}

.remove-button:hover {
    background-color: #ffffff;
    color: #4b2eee;
}

.table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
}

.section-item {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 4px 0px #7d7d7d40;
    padding: 8px;
    margin: 12px 0;
    color: #474747;
    user-select: none;
}

.section-name {
    font-size: large;
    font-weight: 600;
}

.section-description {
    font-size: 14px;
    color: #606975;
}

.section-tabs {
    font-size: 14px;
    /* font-weight: 600; */
    /* color: #606975; */
    /* background-color: #f3f3f3; */
    /* border-radius: 5px; */
    /* padding: 5px;
    margin: 1px; */
    color: black;
}

.section-type {
    font-size: 14px;
    font-weight: 600;
    /* color: #606975; */
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 7px;
    color: grey;
    width: 100%;
    max-width: 100px;
    text-align: center;
}

.section-button-body {
    text-align: right;
    color: #ffffff;
    font-size: small;
}

.section-button {
    cursor: pointer;
    padding: 7px 20px;
    /* border: 1px solid #4b2eee; */
    background-color: #ffffff;
    border: 1px solid #4b2eee;
    color: #4b2eee;
    font-weight: 600;
    border-radius: 20px;
}

.steps .step {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
}

.steps .step .step-icon-wrap {
    display: block;
    position: relative;
    width: 100%;
    height: 55px;
    text-align: center;
}

.steps .step .step-icon-wrap-extreme-end {
    display: block;
    position: relative;
    height: 55px;
    text-align: center
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -9px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}

.steps .step .step-icon-wrap-extreme-end::before,
.steps .step .step-icon-wrap-extreme-end::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    margin-top: -9px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1
}

.steps .step .step-icon-wrap::before {
    left: 0
}

.steps .step .step-icon-wrap::after {
    right: 0
}

.steps .step .step-icon {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    border: 1px solid #e1e7ec;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: #374250;
    font-size: 20px;
    line-height: 40px;
    z-index: 5;
    cursor: pointer;
}

.steps .step .step-title {
    /* margin-top: 16px; */
    margin-bottom: 0;
    color: #606975;
    font-size: 14px;
    font-weight: 500
}

.steps .step:first-child .step-icon-wrap::before {
    display: none
}

.steps .step:last-child .step-icon-wrap::after {
    display: none
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
    background-color: #4b2eee
}

.steps .step.completed .step-icon {
    border-color: #4b2eee;
    background-color: #4b2eee;
    color: #fff
}

@media (max-width: 576px) {

    .flex-sm-nowrap .step .step-icon-wrap::before,
    .flex-sm-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 768px) {

    .flex-md-nowrap .step .step-icon-wrap::before,
    .flex-md-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 991px) {

    .flex-lg-nowrap .step .step-icon-wrap::before,
    .flex-lg-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

@media (max-width: 1200px) {

    .flex-xl-nowrap .step .step-icon-wrap::before,
    .flex-xl-nowrap .step .step-icon-wrap::after {
        display: none
    }
}

.bg-faded,
.bg-secondary {
    background-color: #f5f5f5 !important;
}

.test-primary-button {
    background-color: #4b2eee;
    /* font-size: small; */
    border-radius: 4px;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    border: none;
    width: 100%;
    max-width: 100px;
    margin-left: auto;
    text-align: center;
    cursor: pointer;
}

.test-secondary-button {
    background-color: #FFFFFF;
    /* font-size: small; */
    font-weight: 600;
    border-radius: 4px;
    color: #4b2eee;
    border: 1px solid;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.custom-label {
    display: flex;
    padding: 16px 10px;
    /* border: 1px solid #aaaaaa;
    border-radius: 25px; */
    border-radius: 7px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    font-size: 15px;
    color: #333333;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    align-items: center;
    justify-content: center;
}


.custom-label:hover {
    transform: translateY(-5px);
    border: 2px solid #4b2eee;
    background-color: #4b2eee;
    color: #ffffff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.custom-label.custom-label-active {
    border: 2px solid #4b2eee;
    background-color: #4b2eee;
    color: #ffffff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

input:focus {
    outline: none;
}



.draggable-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.draggable-block {
    padding: 20px;
    background: #f0f0f0;
    border: 1px solid #ccc;
    text-align: center;
    cursor: grab;
}

.toggle-button {
    width: 52px;
    min-width: 52px;
    height: 28px;
    background-color: #ccc;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s;
}

.toggle-button::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggle-button.active {
    background-color: #7b68ee;
}

.toggle-button.active::before {
    transform: translateX(100%);
}

.toggle-button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

.toggle-button.mandatorybutton:disabled {
    background-color: #c9c0ff;
    cursor: not-allowed;
}
</style>