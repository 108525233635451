<template>
    <div class="page-content">
        <h3>
            <router-link :to="{ name: 'ManagementUsers' }" class="p-0" type="button"
                v-if="routePage == 'management-users'">
                <img src="../../../public/dashboard-assets/images/M2/BackButton.png" />
            </router-link>

            <router-link :to="{ name: 'TestManagement' }" class="p-0" type="button"
                v-if="routePage == 'test-management'">
                <img src="../../../public/dashboard-assets/images/M2/BackButton.png" />
            </router-link>

            <router-link :to="{ name: 'Details' }" class="p-0" type="button" v-if="routePage == 'billing-details'">
                <img src="../../../public/dashboard-assets/images/M2/BackButton.png" />
            </router-link>


            Purchase Addon
        </h3>
        <div class="mt-4  table-responsive" style="min-height: 250px !important;">
            <table class="table text-center  bg-white">
                <thead style="background-color: #F1F0FF !important;">
                    <tr>
                        <th class="w-50">Item</th>
                        <th style="width: 20%;">Unit Price</th>
                        <th style="width: 10%;">No of Units</th>
                        <th style="width: 20%;">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in this.addons" :key="index">
                        <td>
                            {{ data.name }}
                            <h6 style="color: #888888;" class="mt-2">
                                {{ data.description }}
                            </h6>
                        </td>
                        <td>{{ CurrencyCode }} {{ data.addonPrice }}</td>
                        <td class="d-flex align-items-center">
                            <select class="form-select" :disabled="setdisabled(data.addonType)"
                                v-model="selectedValues[index].Quantity"
                                @change="updateTotal(index, data.addonPrice, selectedValues[index].Quantity)">
                                <option v-for="number in [...Array((data.maxLimit - data.consumed) + 1).keys()]" :key="number"
                                    :value="number">{{ number }}
                                </option>
                            </select>
                            <img v-if="setdisabled(data.addonType)"
                                src="../../../public/dashboard-assets/images/M7/Actions.png"
                                style="height: 20px;width: 20px;" class="cursor-pointer ms-2" alt=""
                                data-bs-toggle="tooltip" data-bs-placement="top"
                                title="You have reached the max limit allowed of this addon" />
                        </td>
                        <td>{{ CurrencyCode }} {{ data.addonPrice * selectedValues[index].Quantity }}</td>
                    </tr>

                    <tr style="background-color: #F1F0FF !important;">
                        <td colspan="3">
                            <span class="float-end">
                                Amount to be paid
                            </span>
                        </td>
                        <td>{{ CurrencyCode }} {{ this.totalAmount }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5">
            <span class="float-end">
                <button class="btn btn-primary" @click="PurchaseAddon()">Purchase</button>
            </span>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
// import { calculateRemainingDays } from '../../platformFeatures/dateUtils';
// import { getEncryptedData } from '../../platformFeatures/featureUtils';
import { getUserLimitData } from '../../platformFeatures/featureUtils';
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import { SetClarityData } from "../../platformFeatures/featureUtils";

export default {
    name: "AddonPurchase",
    components() {

    },
    data() {
        return {
            addons: [],
            billing: JSON.parse(localStorage.getItem('billingData')),
            CurrencyCode: '',
            selectedValues: {},
            selectedFinalAmount: {},
            totalAmount: 0,
            finalAmount: 0,
            routePage: '',
            AddonUserLimit: 0,
            AddonUserCount: 0
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        })
    },
    async created() {
        if (this.$route.query.page != undefined) {
            this.routePage = window.atob(this.$route.query.page);
        }

        await this.RefreshBillingDetails();

        this.addons = this.billing.object.data.plan.addons;
        this.addons.forEach((addon, index) => {
            this.selectedValues[index] = { Type: addon.addonType, AddonCode: addon.addonCode, Quantity: 0 }; // Initialize with 0 or a default value
            this.selectedFinalAmount[index] = 0; // Initialize with 0 or a default value
        });
        this.CurrencyCode = this.fetchCurrency(this.user.countryCode);

        this.InitializeUserCount();

    },
    async mounted() {
        SetClarityData();

        Array.from(
            document.querySelectorAll('img[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));

    },
    methods: {
        ...mapMutations(["setLoading"]),
        ...mapActions([
            'GetBillingPagePlanDetails',
            'GetBillingPageCardDetails',
            'GetEnterpriseInvoiceData',
            'GetInvoiceData',
            'GetBillingPreference',
            'GetAllUsers',
            'putBillingPreferenceData',
            'DownloadInvoice',
            'ShowWarningMessage',
            'getstatelocation',
            'postPurchaseAddon',
            'RefreshBillingDetails'
        ]),

        updateTotal(index, amount, qty) {
            this.selectedFinalAmount[index] = amount * qty;
            this.totalAmount = Object.values(this.selectedFinalAmount).reduce((total, value) => total + value, 0);
        },

        async PurchaseAddon() {
            var addons = Object.values(this.selectedValues).filter(item => item.Quantity !== 0);

            const data = {
                subscriptionId: this.billing.object.data.subscription.subScriptionId,
                body: {
                    Addons: addons
                }
            };
            const response = await this.postPurchaseAddon(data);
            var url = await response.object.data.hostedpage.url;
            window.open(url, '_self');
        },

        fetchCurrency(data) {
            if (data == 'IN') {
                return '₹';
            } else {
                return '$';
            }
        },

        InitializeUserCount() {
            this.AddonUserCount = getUserLimitData(this.user.extId, 'User Dashboards & Reports', 'AddonUserCount');
            this.AddonUserLimit = getUserLimitData(this.user.extId, 'User Dashboards & Reports', 'PlanUserLimit');
        },

        setdisabled(type) {
            if (type == "Member" && this.AddonUserCount >= this.AddonUserLimit)
                return true;
            else
                return false;
        }
    },
};
</script>

<style scoped>
.tag-input {
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    width: 100%;

}

.tag {
    display: inline-block;
    background-color: #f0f0f0;
    padding: 2px 5px;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
}

.tag span {
    cursor: pointer;
    margin-left: 5px;
}

.tag-input input {
    border: none;
    outline: none;
    width: 250px;
}

.demo-preview .badge {
    margin-right: 10px;
}

/* .tag-input {
  border: 1px solid #ccc;
  padding: 5px;
  min-height: 30px;
  width: 100%;
} */

.tag {
    display: inline-block;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
}

.close {
    margin-left: 5px;
    cursor: pointer;
}

.badge {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 8px;
    border: 1px solid transparent;
    min-width: 10px;
    line-height: 1;
    color: #2d2d2d;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #f1f4f9;
}

.secondary {
    background-color: rgb(211, 211, 211);
    color: rgb(30, 30, 30);
}

.top-cards {
    border-radius: 15px;
    min-height: 200px;
    height: 100%;
}

.plan-cost-distribution-card {
    border-radius: 15px;
    min-height: 400px;

    /* height: 100%; */
}

.disable-text-selection {
    user-select: none;
}

.custom-progress {
    height: 20px;
    background-color: #E8E4FF;
    border-radius: 5px;
}

.custom-progress .progress-bar {
    background-color: #7B68EE;
}

.billing-info-card {
    border-radius: 15px;
    min-height: 250px;
}
</style>