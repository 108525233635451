<template>
    <div ref="infoIcon">
        <slot></slot>
    </div>
</template>

<script>
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { useRouter } from "vue-router";

export default {
    name: "Tooltip",
    props: {
        tooltipText: {
            type: String,
            default: "This is a tooltip."
        },
        showButton: {
            type: Boolean,
            default: false
        },
        buttonText: {
            type: String,
            default: "More Info"
        },
        position:{
            type: String,
            default: "right"
        },
        link: {
            type: String,
            default: "#"
        },
    },
    setup() {
        const router = useRouter();
        return { router };
    },
    mounted() {
        tippy(this.$refs.infoIcon, {
            content: this.getTooltipContent(),
            allowHTML: true,
            placement: this.position,
            theme: "light",
            animation: "fade",
            interactive: true,
            onShow: (instance) => {
                const button = instance.popper.querySelector('button');
                if (button) {
                    button.addEventListener('click', () => this.goToLink());
                }
            }
        });
    },
    methods: {
        getTooltipContent() {
            return `
                <div>
                    <p class="mb-3">${this.tooltipText}</p>
                    ${this.showButton ? `<button style="padding: 5px 10px; border: none; background-color: #4b2eee; color: white; border-radius: 4px; cursor: pointer;">${this.buttonText}</button>` : ""}
                </div>
            `;
        },
        goToLink() {
            this.router.push(this.link);
        }
    }
};
</script>

<style>
.tippy-box[data-theme~="light"] {
    background-color: #fff;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0px 2px 6px rgba(254, 254, 254, 0.15);
}

.tippy-arrow {
    display: none;
    color: #fff;
    box-shadow: -6px 0px 0px rgba(0, 0, 0, 0.1);
}
</style>
