<template>
    <div class="page-content">
        <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
            <div class="my-2">
                <h3 class="mb-2 mb-md-0">Tests</h3>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <button class="secondary-button1 mx-3" type="button" v-if="superadmin()">
                    <router-link :to="{ name: 'TestSectionCreator' }">
                        Insert New Section
                    </router-link>
                </button>
                <div>
                    <router-link class="btn btn-primary" :to="{ name: 'TestCreator' }">
                        Create Test
                    </router-link>
                </div>

            </div>
        </div>
        <div class="my-4">
            <TestHeader activeTab='test-creator' />
        </div>

        <!-- <div class="my-5" v-if="superadmin()">
            <span class="custom-tabs " :class="showSectionsCard ? 'custom-tabs-active' : ' '" @click="switchTabs(0)">
                Sections
            </span>
            <span class="custom-tabs " :class="showTestCard ? 'custom-tabs-active' : ' '" @click="switchTabs(1)">
                Tests
            </span>

        </div> -->


        <div class="card p-4" style="border-radius: 24px;" v-if="this.showSectionsCard && superadmin()">
            <h4 class="mb-4 mt-2">Custom Sections

                <span class="float-end">
                    <button @click="toggleCollapse('section')"
                        :class="{ 'toggle-button': true, 'active': showCompletedSections }" class="ms-2"></button>


                </span>
            </h4>

            <div class="table-responsive" style="width: 100%; max-height: 300px;">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Time</th>
                            <th>Passing Score</th>
                            <th>Report Type</th>
                            <th>Status</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in SectionsList" :key="item"
                            :class="{ 'hover-row': isHovered === index }" @mouseover="isHovered = index"
                            @mouseleave="isHovered = null" @click="handleRowClick(item, 'Section', item.status)">
                            <td>{{ item.sectionName }}</td>
                            <td>{{ ConvertMinutes(item.sectionTime) }} </td>
                            <td>{{ formatNumber(item.passingScore) }}</td>
                            <td>
                                <span v-if="item.competencyType > 0"> New Format </span>
                                <span v-else> Old Format </span>
                            </td>
                            <td>
                                <span class="View-Candidates-clock" v-if="!item.status">Pending <i
                                        class="fa-solid fa-clock-rotate-left"></i></span>
                                <span class="View-Candidates-pass" v-else style="color:green">Completed <i
                                        class="fa-solid fa-check me-2"></i></span>
                            </td>
                            <td>
                                <button class="btn btn-primary">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- <div class="card p-4" style="border-radius: 24px;" v-if="this.showTestCard">
            <h4 class="mb-4 mt-2">Custom Tests

                <span class="float-end">
                    <button @click="toggleCollapse('test')"
                        :class="{ 'toggle-button': true, 'active': showCompletedTests }" class="ms-2"></button>
                </span>
            </h4>

            <div class="table-responsive" style="width: 100%; max-height: 300px;">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in TestList" :key="item" :class="{ 'hover-row': isHovered === index }"
                            @mouseover="isHovered = index" @mouseleave="isHovered = null"
                            @click="handleRowClick(item, 'Test')">
                            <td>{{ item.testName }}</td>
                            <td>{{ ConvertMinutes(item.tesTime) }} </td>
                            <td>
                                <span class="View-Candidates-clock" v-if="!item.status">Pending <i
                                        class="fa-solid fa-clock-rotate-left"></i></span>
                                <span class="View-Candidates-pass" v-else style="color:green">Completed <i
                                        class="fa-solid fa-check me-2"></i></span>
                            </td>
                            <td>
                                <button class="btn btn-primary">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->

    </div>
</template>
<script>
import TestHeader from '../TestHeader.vue';
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { superadmin } from '../../utils/helper';
import { SetClarityData } from "../../platformFeatures/featureUtils"
export default {
    components: {
        TestHeader,
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),
    },
    data() {
        return {
            SectionsList: [],
            TestList: [],
            isHovered: '',
            showTestCard: false,
            showSectionsCard: true,
            showCompletedSections: false,
            showCompletedTests: false



        }

    },

    async mounted() {
        SetClarityData();
        localStorage.removeItem("CreatorMode");
        localStorage.removeItem("CreatorId");
        if (!superadmin()) {
            this.showTestCard = true;
            this.showSectionsCard = false;
        }
        await this.sectionData(0);
        // await this.testData(0);
    },

    methods: {
        ...mapActions([
            "GetTestCreationTestFetch",
            "GetTestCreationSectionFetch"
        ]),
        superadmin,
        formatNumber(value) {
            return Number(value).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 });
        },
        async toggleCollapse(item) {
            // showCompletedSections
            if (item == 'section') {
                if (!this.showCompletedSections) {
                    await this.sectionData(1);
                    this.showCompletedSections = true;
                } else {
                    await this.sectionData(0);
                    this.showCompletedSections = false;
                }
            }
            else {
                if (!this.showCompletedTests) {
                    await this.testData(1);
                    this.showCompletedTests = true;
                } else {
                    await this.testData(0);
                    this.showCompletedTests = false;
                }
            }

        },
        ConvertMinutes(time) {
            // const duration = moment.duration(time);
            // return duration.asMinutes();

            // const duration = moment.duration(time);
            // const hours = Math.round(duration.asHours());
            // const minutes = Math.round(duration.minutes());
            // const seconds = Math.round(duration.seconds());

            // let result = '';
            // if (hours > 0) {
            //     result += `${hours} hr `;
            // }
            // if (minutes > 0) {
            //     result += `${minutes} min `;
            // }
            // if (seconds > 0) {
            //     result += `${seconds} sec`;
            // }
            // return result.trim();

            const timeParts = moment(time, "HH:mm:ss");

            if (timeParts.isValid()) {
                const hours = timeParts.hours();
                const minutes = timeParts.minutes();
                const seconds = timeParts.seconds();

                let timeStrings = [];
                if (hours > 0) timeStrings.push(`${hours} hr`);
                if (minutes > 0) timeStrings.push(`${minutes} min`);
                if (seconds > 0) timeStrings.push(`${seconds} sec`);

                return timeStrings.join(" ");
            }
        },

        switchTabs(item) {
            if (item == 0) {
                this.showSectionsCard = true;
                this.showTestCard = false;
            }
            else if (item == 1) {
                this.showSectionsCard = false;
                this.showTestCard = true;
            }
        },
        handleRowClick(item, type, status) {
            if (type == 'Section') {
                this.$router.push({
                    name: 'TestSectionCreator',
                    query: {
                        id: this.encode(item.sectionMasterId),
                        status: this.encode(status),
                        page: this.encode('overview'),
                        // data: this.encode(JSON.stringify(item))
                    },
                });
            }
            else {
                this.$router.push({
                    name: 'TestCreator',
                    // query: {
                    //     id: this.encode(item.sectionMasterId),
                    //     page: this.encode('overview'),
                    //     data: this.encode(JSON.stringify(item))
                    // },
                });
            }
        },
        encode(item) {
            return window.btoa(item);
        },

        async sectionData(item) {
            var data = await this.GetTestCreationSectionFetch(
                {
                    status: item,
                    offset: 0,
                    limit: 100,
                    search: "0,1,2,3"
                }
            );

            this.SectionsList = data.object.data.allSections;
        },

        async testData(item) {
            var data = await this.GetTestCreationTestFetch({
                testId: 0,
                status: item,
                clientId: this.user.clientId
            });

            this.TestList = data.object.data;
        }
    }

}
</script>
<style>
.secondary-button1 {
    border: 2px solid #7c68ee !important;
    background: #fff !important;
    color: #7c68ee !important;
    font-weight: 600;
    border-radius: 3px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
}

.table-responsive thead th {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}

.table-title {
    font-size: 18px;
    font-weight: bold;
}

.hover-row {
    background-color: #f0f0f0;
    cursor: pointer;
}

.custom-tabs {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    color: #333333;
}

.custom-tabs-active {
    border-bottom: 3px solid #FF9225;
}

.toggle-button {
    width: 52px;
    min-width: 52px;
    height: 28px;
    background-color: #ccc;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s;
}

.toggle-button::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggle-button.active {
    background-color: #7b68ee;
}

.toggle-button.active::before {
    transform: translateX(100%);
}
</style>