<template>
  <div v-if="showAddon || showFinalMessage">
    <div class="billingDays" style="margin-top: 60px !important; min-width: 90%;">
      <div class="billingContent">
        <span
          style="margin-left: 20px !important; margin-top: 5px !important; font-weight: 600; color: #454545; font-size: 14px;">
          <i class="fa-solid fa-triangle-exclamation fa-lg" style="color: #FFAD28;"></i>
          <span class="mx-3 header-text" v-if="this.showAddon">
            You've reached the limit of team members for your plan. You can purchase the team member addon for adding
            new member.
          </span>
          <span class="header-text" v-else-if="this.showFinalMessage">
            In case you wish to add more recruiter then do upgrade the plan
          </span>
        </span>
        <button v-if="this.showFinalMessage"
          style="background-color: #FFAD28 !important; border-color:#FFAD28 !important; color:rgb(54, 54, 54) ; font-size: small; border-radius: 3px; height: 50%; ">
          <router-link :to="{ name: 'BillingPlans' }" style="color: black;">
            Upgrade Now
          </router-link>
        </button>
        <button v-else-if="this.showAddon"
          style="background-color: #FFAD28 !important; border-color:#FFAD28 !important; color:rgb(54, 54, 54) ; font-size: small; border-radius: 3px; height: 50%; ">
          <router-link :to="{ name: 'AddonPurchase', query:{ page: encode('management-users')} }" style="color: black;">
            Purchase Addon
          </router-link>
        </button>
      </div>
      <!-- <div @click="closeBillingDays()" style="margin-right: 10%;" v-if="this.Remainingdays>0">
        <i class="fa-solid fa-xmark ms-auto" style="color: #454545; cursor: pointer;"></i>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { getUserLimitData } from '../platformFeatures/featureUtils';
//import eventBus from "../store/eventBus";

export default {
  name: "UserLimitAlert",
  components: {
  },
  data() {
    return {
      userLimitMessage: false,
      showBillingDays: false,
      Remainingdays: 0,
      billing: JSON.parse(localStorage.getItem('billingData')),
      receivedData: true,
      showFinalMessage: false,
      showAddon: false,
      PlanUserLimit: 0,
      PlanUserCount: 0,
      AddonUserCount: 0,
      AddonUserLimit: 0
    };
  },
  created() {
    // eventBus.on('show-button', this.showButton);
    // eventBus.on('hide-button', this.hideButton);
    },
  mounted() {
//     if (window.location.href.includes("billing-plan")) {
//     this.showButtonStatus = false;
// }
    if(this.user.isEnterprise == false) {
      this.PlanUserLimit = getUserLimitData(this.user.extId,'User Dashboards & Reports','AddonUserLimit');
      this.PlanUserCount = this.billing.object.data.subscription.userCount;
      this.AddonUserCount = getUserLimitData(this.user.extId,'User Dashboards & Reports','AddonUserCount');
      this.AddonUserLimit = getUserLimitData(this.user.extId,'User Dashboards & Reports','PlanUserLimit');

      let totalAllowedCount = this.PlanUserLimit + this.AddonUserLimit;
      
      if(this.PlanUserLimit == this.PlanUserCount && this.AddonUserCount == 0 ){
        this.showAddon = true;
        this.showFinalMessage = false;
        document.documentElement.style.setProperty('--page-content-margin-top-open', '90px'); 

      }
      else if(totalAllowedCount == this.PlanUserCount){
        this.showAddon = false;
        this.showFinalMessage = true;
        document.documentElement.style.setProperty('--page-content-margin-top-open', '90px'); 

      }
      

      // if(this.Remainingdays<=7){
      //   this.showBillingDays = true;
      // }
      // else if(this.Remainingdays >=0){
      //   this.showBillingDays = false;
      //   document.documentElement.style.setProperty('--page-content-margin-top-open', 'var(--page-content-margin-top-closed)'); 
      // }
    } else {
      document.documentElement.style.setProperty('--page-content-margin-top-open', '90px'); 
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapMutations(["setLoading"]),
  
   closeBillingDays() {
      this.showBillingDays = false;
      document.documentElement.style.setProperty('--page-content-margin-top-open', 'var(--page-content-margin-top-closed)');
    },
    // hideButton(){
    //   this.showButtonStatus = false;
    // },
    // showButton(){
    //   this.showButtonStatus = true;
    // },  
    encode(item) {
      return window.btoa(item);
    },  
  },

  // beforeUnmount() {
  //   eventBus.off('show-button', this.showButton);
  //   eventBus.off('hide-button', this.hideButton);
  // },
};
</script>
<style scoped>
.billingDays {
  background: #feebb4;
  border-bottom: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 0;
  position: fixed;
  right: 0;
  left: 240px;
  z-index: 978;
  box-shadow: 3px 0 10px 0 rgba(183, 192, 206, 0.2);
  transition: width 0.1s ease, left 0.1s ease;
  height: 40px ;
  padding-top: 5px;
  padding-bottom: 5px;
  /* border: 1px solid #FFAD28 bottom; */
  
}

.billingContent{
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 991px) {
  .billingDays {
    left: 0; 
    width: 100%; 

    /* height: 40px !important;  */

  }
 
  @media (max-width: 400px) {
    .header-text{
      font-size: small;
    }
    .billingContent{
  padding-top: 5px;
  padding-bottom: 5px;
}

.billingDays{
  margin-top: 15px;
  /* margin-bottom: 5px; */
}
  }
  .billingDays .upgrade {
    width: 120px !important; 
    border-radius: 25px !important;
  }

 

  .billingDays i.fa-xmark {
  color: #454545;
  margin-right: 10px;
  cursor: pointer;
}


}
</style>