<template>
  <div class="page-content">
    <!-- header -->
    <div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
      <div class="my-2 d-flex align-items-center">
        <div class="top_heading_CreatePartner_btn d-block me-2">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <router-link :to="{
                name: 'ActiveTestViewCandidatesNew', params: {
                  clientId: encode(this.clientId),
                  isParent: encode(isParent), //update
                },
                query: {
                  search: encode(this.routesearch),
                  offset: encode(this.routeoffset),
                  current: encode(this.routecurrent),
                },
              }" class="p-0 Group_top_three_dot_dropdown-active btn-back-arrow-kj" type="button"
                style="line-height: 2.6">
                <i class="fa-solid fa-chevron-left"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div>
          <h4 class="mb-2 fw-bolder">Report</h4>
          <h6 class="text-muted">

            {{ this.testName }} <i class="fa-solid fa-angle-right mx-2"></i> {{ this.name }}
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="top_heading_CreatePartner_btn d-block" data-bs-toggle="modal" data-bs-target="#ConsolidatedSheet">
          <div class="Group_top_three_dot">
            <div class="dropdown">
              <button class="btn p-0 Group_top_three_dot_dropdown" type="button" @click="savePdf()">
                <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
              </button>
              <!-- <router-link :to="{
                name: 'ResponsesPDF',
                query: {
                  candidateId: encode(this.candidateId),
                  testId: encode(this.testId),
                  name: encode(this.name),
                },
              }">
                <button class="btn p-0 Group_top_three_dot_dropdown" type="button">
                  <img src="../../public/dashboard-assets/images/M2/download-icon.png" />
                </button>
              </router-link> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row Report_card_section">
      <div class="col-md-12">
        <div class="card active-card">
          <!--Header Component-->
          <div class="card mb-3">

            <ActiveTestHeaderVue :candidateId="this.candidateId" :active="this.activated" :testId="this.testId"
              :isParent="this.isParent" :clientId="this.clientId" :id="this.routesearch" :current="this.routecurrent"
              :offset="this.routeoffset" />
          </div>
          <!--Header Component-->
          <div class="card">


            <div class="Report_Responses_second_nav">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation" v-for="tab in headerdata" :key="tab">
                  <button class="nav-link" id="Typing-tab" data-bs-toggle="tab" v-bind:class="this.activeTab.secId == tab.secId ? 'active' : ''
                    " @click="Tabfunction(tab)" :data-bs-target="'#section_' + tab.secId" type="button" role="tab"
                    aria-controls="Typing" aria-selected="true">
                    {{ tab.sectionName }}
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" :id="'#section_' + this.activeTab.secId" role="tabpanel"
                  aria-labelledby="Typing-tab">
                  <div class="Report_basic_info_area my-4">
                    <div class="Report_basic_info_heading d-flex justify-content-between">
                      <h4 class="text-capitalize">
                        <img src="../../public/dashboard-assets/images/M7/Report_Responses_typing_icon.png" width="30"
                          class="me-1" alt="" />
                        {{ this.CandidateAns?.sectionName }}
                        <img src="../../public/dashboard-assets/images/M7/Actions.png" width="18" class="ms-2 mt-2"
                          alt="" />
                        <samp class="mx-2">|</samp>
                        <span class="font-size-14 text-muted">{{ this.activeTab?.noOfQuestion }} Questions</span>
                      </h4>
                      <div>
                        <i class="fa-regular fa-clock"></i>
                        {{ this.timeToMin(this.activeTab?.sectionTime) }} Min
                      </div>
                    </div>
                  </div>

                  <div class="accordion" id="accordionExample">

                    <QuestionType :qType="item.questionType" :question="item" :index="index" :mainques="CandidateAns"
                      v-for="(item, index) in CandidateAns?.questions" :key="index">
                    </QuestionType>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="Report_basic_info_area my-4">
            <div class="Report_basic_info_heading">
              <h4>DISCLAIMER</h4>
            </div>
            <div class="mt-3">
              <!-- <p> -->
              <!-- PMaps is involved in psychometric assessments and is capable of,
                  on a best effort basis designing a test as per its client’s
                  requirements and making a suggestion or giving an opinion on the
                  suitability of a candidate for a particular job role based on the
                  evaluation and interpretation of the candidate’s test results. The
                  same applies to the report on the candidate’s psychometric
                  profile, the report is an opinion on the candidate’s personality.
                  PMaps makes no warranty or representation with respect to the
                  accuracy of its opinion, suggestion or the profile report. Client
                  will use PMaps opinion and suggestion as an input in its
                  recruitment process, but PMaps will not be held liable for any
                  decision that client takes based on the suggestions, opinions or
                  the profile report given by PMaps. Client indemnifies PMaps from
                  any liability that can arise on account of utilizing PMaps
                  services and holds PMaps harmless without limitation, against any
                  losses, claims, costs, damages or liabilities suffered by PMaps
                  arising out of or in connection with providing its services to
                  client. This indemnification extends to PMaps, its affiliates,
                  respective directors, officers and employees. -->
              <!-- {{ ResponesbasicInfo.disclaimer }}
                </p> -->
              <span v-html="ResponesbasicInfo.disclaimer"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-if="showpdf">
    <ResponsesPDF :testId="this.testId" :candidateId="this.candidateId" :name="this.name" :pdfData="this.overviewPdf" />
    <!-- v-show="false" ref="DownloadComp" -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ActiveTestHeaderVue from "./ActiveTestHeader.vue";
import ResponsesPDF from "./ResponsesPDF.vue";
import QuestionType from "./question-type-components/Question-Type.vue";
import jsPDF from "jspdf";
import store from "../store";
import Base64Images from "../utils/base64Image.js";
import { SetClarityData } from "../platformFeatures/featureUtils.js";
export default {
  name: "ActiveTestReportResponses",
  components: {
    ActiveTestHeaderVue,
    QuestionType,
    ResponsesPDF,
  },

  data() {
    return {
      activated: "Response",
      isShowing: false,
      CandidateAns: "",
      responsesData: "",
      candidateId: window.atob(this.$route.params.candidateId),//10104,
      activeTab: "",
      headerdata: [],
      testId: window.atob(this.$route.params.testId), //3532  3534, //
      clientId: window.atob(this.$route.query.clientId),
      isParent: window.atob(this.$route.query.isParent),
      routesearch: window.atob(this.$route.query.id),
      routeoffset: window.atob(this.$route.query.offset),
      routecurrent: window.atob(this.$route.query.current),
      name: '',
      isShow: false,
      showpdf: false,
      ResponesbasicInfo: '',
      testName: "",
    };
  },

  async mounted() {

    SetClarityData();

    await this.HeadData();
    await this.Candidate();
    this.GetBasicInfo();
    this.name = window.atob(this.$route.query.name)
  },

  methods: {
    ...mapActions(["GetCandidateAns", "responseHeader", "getBasicInfo"]),

    async HeadData() {
      var headerData = await this.responseHeader(this.testId);
      if (headerData?.status) {
        this.headerdata = headerData?.object?.data;
        this.activeTab = this.headerdata[0];
      } else {
        this.headerdata = [];
      }
    },
    encode(item) {
      return window.btoa(item);
    },

    async Tabfunction(tab) {
      this.activeTab = tab;
      this.CandidateAns = this.responsesData?.find(
        (y) => y.sectionId == tab.secId
      );
      this.CandidateAns.questions.forEach((elm, index) => {
        elm.show = false;
        if (index == 0) elm.show = true;
      });
    },


    async Candidate() {
      var CandidateAnswer = await this.GetCandidateAns(this.candidateId);
      if (CandidateAnswer?.status) {
        this.responsesData = CandidateAnswer?.object?.data?.sections;
        this.CandidateAns = this.responsesData?.find(
          (y) => y.sectionId == this.activeTab.secId
        );
        this.CandidateAns?.questions.forEach((elm, index) => {
          elm.show = false;
          if (index == 0) elm.show = true;
        });
      } else {
        this.responsesData = [];
      }
    },


    timeToMin(time) {
      if (time) {
        var a = time.split(":");
        var min = +a[0] * 60 + +a[1];
        if (min >= 0 && min <= 9) min = "0" + min;
        return min;
      }
    },
    async GetBasicInfo() {
      var BasicInfo = await this.getBasicInfo(this.candidateId);
      if (BasicInfo?.status) {
        this.ResponesbasicInfo = BasicInfo.object.data;
        this.testName = this.ResponesbasicInfo.testName
        this.clientCompany = this.ResponesbasicInfo.clientCompanyLogo;
        this.Companylogo = this.ResponesbasicInfo.companyLogo;
        this.namePdf = this.ResponesbasicInfo.name;
        this.testNamePDF = this.ResponesbasicInfo.testName;
      } else {
        this.ResponesbasicInfo = null;
      }
    },
    async savePdf() {
      store.state.loader.isLoading = true;
      this.showpdf = true;
      // const doc = new jsPDF("p", "mm", "a4" ); // Create a new instance of jsPDF with A4 dimensions
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
        // autoPaging: "text"
      }); // Create a new instance of jsPDF with A4 dimensions
      const namePdf = this.namePdf;
      const testName = this.testNamePDF;
      const Companylogo = this.Companylogo;
      const clientCompany = this.clientCompany;
      setTimeout(() => {
        const element = document.getElementById("response");
        const container = document.createElement("div");
        // Append the container to the element
        element.appendChild(container);

        doc.html(element, {
          margin: [25, 0, 25, 0],
          x: 0,
          y: 0,
          autoPaging: "text",
          callback: function (pdf) {
            // footer start
            const footerContainer = document.createElement("div");
            // Append the footer container to the element
            element.appendChild(footerContainer);

            const pageCount = doc.internal.getNumberOfPages(); // Get the total number of pages in the PDF document


            //Ankit code
            //  const headerMarginTop = 10; // Set the top margin for the header
            //const footerMarginBottom = 10;
            for (let i = 1; i <= pageCount; i++) {


              doc.setPage(i);
              // header start 
              if (i === 1) {
                var imageUrl = Base64Images.Background;
                // Add the background image
                doc.addImage(imageUrl, 'PNG', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());
                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute(
                  "src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image
                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                // console.log(img1)
                // logo-2
                const img2 = document.createElement("img");
                img2.setAttribute("src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(
                  img2.src, "JPEG", imageX2, imageY2, image2Width, image2Height
                );
              } else {

                // header 2
                doc.setFillColor(232, 247, 250); // Set the fill color to red (change the RGB values as per your desired color)
                doc.rect(0, 0, doc.internal.pageSize.getWidth(), 20, "F"); // Draw a filled rectangle as the header background

                // logo 1
                const img1 = document.createElement("img");
                img1.setAttribute(
                  "src", Companylogo);

                const imageWidth = 40; // Width of each image
                const imageHeight = 10; // Height of each image

                const imageX1 = 10; // X position of the first image
                const imageY1 = 5; // Y position of the first image
                doc.addImage(
                  img1.src,
                  "JPEG",
                  imageX1,
                  imageY1,
                  imageWidth,
                  imageHeight
                );
                doc.setFontSize(10);

                // Set the header text margin


                // Specify the content of the header text
                const headerText = `${testName}`;

                // Calculate the x-position to center the header text
                const textWidth =
                  (doc.getStringUnitWidth(headerText) *
                    doc.internal.getFontSize()) /
                  doc.internal.scaleFactor;
                const x = (doc.internal.pageSize.getWidth() - textWidth) / 2;

                // Add the header text at the centered position
                doc.text(headerText, x, 10);

                // logo-2

                const img2 = document.createElement("img");
                img2.setAttribute(
                  "src", clientCompany);
                const image2Width = 40; // Width of each image
                const image2Height = 10; // Height of each image
                const imageX2 = doc.internal.pageSize.width - image2Width - 10; // X position of the image on the right
                const imageY2 = 5; // Y position of the image
                doc.addImage(img2.src, "JPEG", imageX2, imageY2, image2Width, image2Height);
              }
              // footer start
              doc.setFontSize(15);
              doc.setTextColor(41, 46, 52, 1);
              const img3 = document.createElement("img");
              img3.setAttribute(
                "src",
                Base64Images.Footer
              );

              const image3Width = doc.internal.pageSize.width; // Width of the image (equal to the page width)
              const image3Height = 20; // Height of the image
              const imageX3 = 0; // X position of the image (start from the left)
              const imageY3 = doc.internal.pageSize.height - image3Height;
              doc.addImage(img3, "JPEG", imageX3, imageY3, image3Width, image3Height);

              const footerText = "";
              const pageNumber = "Skills " + " | " + i;
              const textWidth = doc.getTextWidth(footerText); // Calculate the width of the footer text
              const xPos = doc.internal.pageSize.width - textWidth - 40; // 10 is the margin from the right side
              // Add footer text aligned to the right
              doc.text(footerText, xPos, doc.internal.pageSize.height - 10);
              // Add page number aligned to the right
              doc.text(pageNumber, xPos, doc.internal.pageSize.height - 5);
            }
            // end footer
            const pdfDataUri = pdf.output("datauristring");
            const link = document.createElement("a");
            link.href = pdfDataUri;
            link.download = `Skills_${namePdf}.pdf`;

            store.state.loader.isLoading = false;
            link.click();
          },
        });
        setTimeout(() => {
          this.showpdf = false;
        }, 1 * 1000);
      }, 1 * 60 * 1000);

    },


  },
};
</script>
<style scoped>
.table-border-M7 .bg-secondary {
  background: rgba(102, 102, 102, 0.3) !important;
  color: #000 !important;
}

.table-border-M7 .bg-secondary tr th {
  font-weight: 500;
  color: #000 !important;
}

.table-border-M7 .table tbody,
td,
tfoot,
th,
thead,
tr {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.border-radius-10 .active-report-repsponses .table-border-M7-kj td,
tr {
  border: 1px solid #e9ecef !important;
}
</style>