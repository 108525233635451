<template>
  <div class="Overview_dashboard_map_section row">
    <div class="col-xl-8 col-lg-7 col-md-6 col-12">
      <div class="card p-4">
        <div class="card-body p-0">
          <h3 class="mb-3 mb-md-0 text-start">Test Allocation</h3>
          <p v-if="this.firstpiechart.length > 20">
            (Upto 20 {{ mode }}s can be selected at a time)
          </p>
          <div class="multi-outer-div">
            <Multiselect v-model="this.label" @close="selecttest" @deselect="splicetest" @select="checktest"
              :placeholder="this.placeholder" mode="tags" :close-on-select="false" :options="this.opt"
              :searchable="true" :group-select="true" ref="multiselect" class="deshboard_piechart_multiselect"
              v-if="this.firstpiechart.length > 20" />
            <div @click="clearTest" v-if="this.label.length > 0" class="multi-inner-div">
              <i class="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div class="m-auto Dashboard-pb-6" v-if="this.testData.labels.length > 0">
            <div style="position: relative;" class="m-auto mt-5 Test_Allocation_pieChat_text "
              :class="{ 'canva-desgin-kj': this.testData.labels.length >= 10, 'canva-desgin-kj-MV': this.testData.labels.length < 10 }">
              <DoughnutChart :chartData="this.testData" :options="this.options" :height="550" />

              <div class="Test_Allocation_pieChat_center_contect">
                <div class="Test_Allocation_pieChat_Toptext">
                  <b>{{ this.firstcount }}</b>
                </div>
                <div class="Test_Allocation_pieChat_bottomtext">
                  Total distributed
                  <br />
                  test
                </div>
              </div>
            </div>
          </div>
          <div class="m-auto Dashboard-pb-6" v-else>No Record Found</div>
          <div class="Overview_dashborad_map_img Overview_dashborad_map_Activity_img mt-5"></div>
        </div>
      </div>
    </div>

    <div class="col-xl-4 col-lg-5 col-md-6 col-12">
      <div class="card p-0">
        <div class="card-body p-4">
          <h3 class="mb-2 mb-md-0">Test Scheduled</h3>
          <div class="d-flex justify-content-between align-items-center" v-if="this.Datatest.labels.length > 0">
            <div style="position: relative" class="m-auto mt-5 canva-desgin-kj-2">
              <DoughnutChart :chartData="this.Datatest" :options="this.options2" :width="250" />
              <div style="position: absolute; top: 42%; left: 50% ;  transform: translate(-50%, -50%);">
                <div>
                  <b>{{ this.secondcount }}</b>
                </div>
                <div>
                  Tests
                </div>
              </div>

            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center" v-else>
            No Record Found
          </div>
          <div class="Overview_dashborad_map_img Overview_dashborad_map_Scheduled_img"></div>
        </div>
        <img class="Test_Scheduled_bottom" src="../../public/dashboard-assets/images/M2/Test_Scheduled_bottom.png"
          alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
import { mapState, mapActions } from "vuex";
import { SetClarityData } from "../platformFeatures/featureUtils";
export default {
  name: "pieChart",
  props: ["mode", "from", "to", "color"],
  components: {
    DoughnutChart,
    Multiselect,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  mounted() {

    SetClarityData();

    setTimeout(() => {
      this.piechart();
      this.placeholder = "Choose " + this.mode;
    }, 100);
  },
  updated() {
    setTimeout(() => {
      this.companyColor = this.$store.state.auth.color;
    }, 200);
    this.fromDate = this.from;
  },
  data() {
    return {
      companyColor: [],
      placeholder: "",
      fromDate: "",
      label: [],
      opt: [],
      firstpiechart: [],
      firstcount: 0,
      secondcount: 0,
      bgColor: [
        "#7b68ee",
        "#6f5ed6",
        "#6253be",
        "#5649a7",
        "#b0a4f5",
        "#bdb4f7",
        "#cac3f8",
        "#d7d2fa",
        "#e5e1fc",
        "#f2f0fd",
        "#49ccf9",
        "#42b8e0",
        "#3aa3c7",
        "#338fae",
        "#92e0fb",
        "#a4e6fc",
        "#b6ebfd",
        "#c8f0fd",
        "#dbf5fe",
        "#edfafe",
        "#fd71af",
        "#e4669e",
        "#ca5a8c",
        "#b14f7a",
        "#feaacf",
        "#feb8d7",
        "#fec6df",
        "#fed4e7",
        "#ffe3ef",
        "#fff1f7",
        "#ffc800",
        "#e6b400",
        "#cca000",
        "#b38c00",
        "#ffde66",
        "#ffe480",
        "#ffe999",
        "#ffefb3",
        "#fff4cc",
        "#fffae6",
        "#645cbb",
        "#5a53a8",
        "#504a96",
        "#464083",
        "#a29dd6",
        "#b2aedd",
        "#c1bee4",
        "#d1ceeb",
        "#e0def1",
        "#f0eff8",
        "#ebc7e6",
        "#d4b3cf",
        "#bc9fb8",
        "#a58ba1",
        "#f3ddf0",
        "#f5e3f3",
        "#f7e9f5",
        "#f9eef8",
        "#fbf4fa",
        "#fdf9fd",
        "#3f0071",
        "#521a7f",
        "#65338d",
        "#794d9c",
        "#8c66aa",
        "#9f80b8",
        "#b299c6",
        "#c5b3d4",
        "#d9cce3",
        "#ece6f1",
        "#ff597b",
        "#e6506f",
        "#cc4762",
        "#b33e56",
        "#ff9bb0",
        "#ffacbd",
        "#ffbdca",
        "#ffcdd7",
        "#ffdee5",
        "#ffeef2",
        "#0081b4",
        "#0074a2",
        "#6790",
        "#005a7e",
        "#66b3d2",
        "#80c0da",
        "#99cde1",
        "#b3d9e9",
        "#cce6f0",
        "#e6f2f8",
        "#ff8b13",
        "#e67d11",
        "#cc6f0f",
        "#b3610d",
        "#ffb971",
        "#ffc589",
        "#ffd1a1",
        "#ffdcb8",
        "#ffe8d0",
        "#fff3e7",
        "#eb455f",
        "#ed586f",
        "#ef6a7f",
        "#f17d8f",
        "#f38f9f",
        "#f5a2af",
        "#f7b5bf",
        "#f9c7cf",
        "#fbdadf",
        "#fdecef",
      ],
      testData: {
        datasets: [
          {
            data: [],
            // backgroundColor: [
            //   "#7C68EE",
            //   "#A293F4",
            //   "#FD71AF",
            //   "#FF90C0",
            //   "#00AFED",
            //   "#7EDBFC",
            // ],
            backgroundColor: [],
          },
        ],
        labels: [],
        tooltip: [],
      },
      Datatest: {
        datasets: [
          {
            data: [],
            backgroundColor: ["#F8B200", "#5E45EB", "#FF549E"],
          },
        ],
        labels: [],
      },
      options: {
        plugins: {
          legend: {
            display: true,

            position: "bottom",
            labels: {
              padding: 20,
            },
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: (tooltipItems) => {
                return " " + tooltipItems.label;
              },
              beforeFooter: (tooltipItems) => {
                return "remaning: " + tooltipItems[0].raw;
              },
              footer: (tooltipItems) => {
                if (this.firstpiechart) {
                  var x = this.firstpiechart.filter(
                    (x) => x.name == tooltipItems[0].label
                  );
                  return "used: " + x[0].data.used;
                }
              },
            },
          },
        },
      },
      options2: {
        plugins: {
          legend: {
            display: true,

            position: "bottom",
            labels: {
              padding: 20,
            },
          },
        },
      },
      data: [],
      showData: [],
      dataShow: [],
    };
  },
  watch: {
    fromDate() {
      this.piechart();
    },
  },
  methods: {
    ...mapActions([
      "Dashallocation",
      "Dashscheduled",
      "piechartPartner",
      "ShowWarningMessage",
      "regionfilter",
    ]),
    checktest() {
      if (this.label.length > 20) {
        this.ShowWarningMessage("Upto 20 partners can be selected at a time");
        this.label.pop();
        this.$refs.multiselect.close();
      }
    },
    clearTest() {
      this.label = [];
      if (this.label.length == 0) {
        this.piechart();
      }
    },
    splicetest(e) {
      this.testData.labels = [];
      this.testData.datasets[0].data = [];
      this.firstcount = 0;
      this.label.forEach((elm) => {
        var x = this.dataShow.filter((x) => x == elm);
        if (x.length == 0) {
          this.dataShow.push(elm);
        }
      });

      this.dataShow.splice(this.dataShow.indexOf(e), 1);
      this.dataShow.forEach((element) => {
        this.data.forEach((elm) => {
          if (elm.company == element) {
            this.testData.labels.push(elm.company);
            this.testData.datasets[0].data.push(elm.remaining);
            this.firstcount = this.firstcount + elm.remaining;
          }
        });
      });
      //test-code
      /*this.testData.datasets[0].backgroundColor = [];
      this.testData.labels.forEach((elm, idx) => {
        var x = this.companyColor.findIndex((m) => m.label == elm);
        if (x >= 0) {
          this.testData.datasets[0].backgroundColor[idx] =
            this.companyColor[x].color;
        } else {
          this.bgColor.forEach((elem) => {
            var m = this.companyColor.findIndex((n) => n.color == elem);
            if (m == -1) {
              var n = this.testData.datasets[0].backgroundColor.findIndex(
                (z) => z == elem
              );
              if (n == -1) {
                if (this.testData.datasets[0].backgroundColor[idx] == null) 
                {
                  this.testData.datasets[0].backgroundColor[idx] = elem;
                }
              }
            }
          });
        }
      });*/
      if (this.dataShow.length == 0) {
        this.piechart();
      }
    },
    selecttest() {
      this.testData.datasets[0].data.colorcode = [];
      this.showData = [];
      this.testData.labels = [];
      this.testData.datasets[0].data = [];
      this.firstcount = 0;
      this.dataShow = this.label;
      if (this.label.length != 0 && this.label.length < 20) {
        if (this.label.length > 3) {
          this.showData = this.label;
          this.showData.forEach((element) => {
            // this.data.forEach((elm) => {
            //   if (elm.company == element) {
            //     this.testData.labels.push(elm.company);
            //     this.testData.datasets[0].data.push(elm.remaining);
            //     this.firstcount = this.firstcount + elm.remaining;
            //   }
            // });
            this.firstpiechart.forEach((elm) => {
              if (elm.name == element) {
                this.testData.labels.push(elm.name);
                this.testData.datasets[0].data.push(elm.data.remaining);
                this.testData.datasets[0].backgroundColor.push(elm.colorCode);
                this.firstcount = this.firstcount + elm.data.remaining;
              }
            });
          });
        } else {
          this.showData = this.label;
          this.showData.forEach((element) => {
            // this.data.forEach((elm) => {
            //   if (elm.company == element) {
            //     this.testData.labels.push(elm.company);
            //     this.testData.datasets[0].data.push(elm.remaining);
            //     this.firstcount = this.firstcount + elm.remaining;
            //   }
            // });
            this.firstpiechart.forEach((elm) => {
              if (elm.name == element) {
                this.testData.labels.push(elm.name);
                this.testData.datasets[0].data.push(elm.data.remaining);
                this.firstcount = this.firstcount + elm.data.remaining;
              }
            });
          });
        }
      } else {
        if (this.label.length == 0) {
          this.piechart();
        } else {
          if (this.label.length <= 20) {
            this.showData = this.label;
            this.showData.forEach((element) => {
              // this.data.forEach((elm) => {
              //   if (elm.company == element) {
              //     this.testData.labels.push(elm.company);
              //     this.testData.datasets[0].data.push(elm.remaining);
              //     this.firstcount = this.firstcount + elm.remaining;
              //   }
              // });
              this.firstpiechart.forEach((elm) => {
                if (elm.name == element) {
                  this.testData.labels.push(elm.name);
                  this.testData.datasets[0].data.push(elm.data.remaining);
                  this.firstcount = this.firstcount + elm.data.remaining;
                }
              });
            });
          }
        }
      }
      //test-code
      /*this.testData.datasets[0].backgroundColor=[]
      this.testData.labels.forEach((elm, idx) => {
        var x = this.companyColor.findIndex((m) => m.label == elm);
        if (x >= 0) {
          this.testData.datasets[0].backgroundColor[idx] =
            this.companyColor[x].color;
        } else {
          this.bgColor.forEach((elem) => {
            var m = this.companyColor.findIndex((n) => n.color == elem);
            if (m == -1) {
              var n = this.testData.datasets[0].backgroundColor.findIndex(
                (z) => z == elem
              );
              if (n == -1) {
                if (this.testData.datasets[0].backgroundColor[idx] == null) 
                {
                  this.testData.datasets[0].backgroundColor[idx] = elem;
                }
              }
            }
          });
        }
      });*/
    },
    async piechart() {
      this.testData.datasets[0].backgroundColor = [];
      this.testData.labels = [];
      this.testData.datasets[0].data = [];
      this.firstcount = 0;

      var result;
      if (this.mode == "Partner") {
        result = await this.piechartPartner({
          clientId: this.user.clientId,
        });
        this.data = [];
        this.opt = [];
        result.object.data.partnerData.forEach((element) => {
          this.data.push({
            company: element.company,
            remaining: element.remainingTokens,
          });
        });
        this.data.forEach((element) => {
          this.opt.push(element.company);
        });
      } else {
        result = await this.regionfilter({
          clientId: this.user.clientId,
          offset: 0,
          limit: -1,
        });
        this.data = [];
        this.opt = [];
        result.object.data.regions.forEach((element) => {
          this.data.push({
            company: element.regionName,
            remaining: element.userCount,
          });
        });
        this.data.forEach((element) => {
          this.opt.push(element.company);
        });
      }
      var response = await this.Dashallocation({
        clientId: this.user.clientId,
        mode: this.mode,
        From: this.from,
        To: this.to,
      });

      this.firstpiechart = response.object.data.details;
      this.firstpiechart.forEach((element) => {

        if (element.data.remaining != 0) {
          var x = this.testData.labels.filter((x) => x == element.name);
          if (x.length == 0 && this.testData.labels.length < 20) {
            this.testData.labels.push(element.name);
            this.testData.datasets[0].data.push(element.data.remaining);
            this.testData.datasets[0].backgroundColor.push(element.colorCode)
            this.firstcount = this.firstcount + element.data.remaining;
          }
        }
      });
      //test-code
      /*this.testData.labels.forEach((elm, idx) => {
        var x = this.companyColor.findIndex((m) => m.label == elm);
        if (x >= 0) {
          this.testData.datasets[0].backgroundColor[idx] =
            this.companyColor[x].color;
        } else {
          this.bgColor.forEach((elem) => {
            var m = this.companyColor.findIndex((n) => n.color == elem);
            if (m == -1) {
              var n = this.testData.datasets[0].backgroundColor.findIndex(
                (z) => z == elem
              );
              if (n == -1) {
                if (this.testData.datasets[0].backgroundColor[idx] == null) 
                {
                  this.testData.datasets[0].backgroundColor[idx] = elem;
                }
              }
            }
          });
        }
      });*/
      var res = await this.Dashscheduled({
        clientId: this.user.clientId,
        mode: this.mode,
        From: this.from,
        To: this.to,
      });
      this.Datatest.datasets[0].data = [];
      this.secondcount = 0;
      this.Datatest.labels = [];
      this.Datatest.datasets[0].backgroundColor = [];
      var secondpiechart = res.object.data.details;
      secondpiechart.forEach((element) => {
        this.Datatest.labels.push(element.name);
        this.Datatest.datasets[0].data.push(element.assessmentCount);
      });
      this.Datatest.labels.forEach((elm) => {
        if (elm == "InProgress") {
          this.Datatest.datasets[0].backgroundColor.push("#F8B200");
        }
        if (elm == "Pass") {
          this.Datatest.datasets[0].backgroundColor.push("#5E45EB");
        }
        if (elm == "Fail") {
          this.Datatest.datasets[0].backgroundColor.push("#FF549E");
        }
      });
      this.secondcount = res.object.data.totalCount;
    },
  },
};
</script>
<style>
.multi-outer-div {
  position: relative;
}

.multi-inner-div {
  position: absolute;
  right: 30px;
  top: 10px;
  color: #999999;
}


.canva-desgin-kj-2 canvas {
  height: 250px !important;
  width: 250px !important;
}
</style>
