<template>
  <div class="page-content">
    <!-- {{ this.fullUrl }} -->
    <iframe :src="fullUrl" title="Analytics" style="width: 100%; height: 100%; border: none"></iframe>
  </div>
</template>

<script>
import { getUserPermissionData } from "../utils/helper";

export default {
  methods: {
    getUserPermissionData,
  },
  data() {
    return {
      routePage: "processing",
    };
  },
  watch: {
    "$route.name"() {
      if (this.$route.path == '/analytics/processing') {
        this.routePage = 'processing';
      }
      else if (this.$route.path == '/analytics/overview') {
        this.routePage = 'overview';
      }
      else if (this.$route.path == '/analytics/descriptive') {
        this.routePage = 'descriptive';
      }
      else if (this.$route.path == '/analytics/performance-modeling') {
        this.routePage = 'performance-modeling';
      }
    }
  },
  mounted() {
    this.routePage = 'processing';
  },
  computed: {
    fullUrl() {
      const baseUrl = window.app_base_url;
      const url = (baseUrl == 'https://poc-app.pmapstest.in' ? `https://dev-data-automation.pmapstest.in/${this.routePage}` : `https://data-automation.pmapstest.com/${this.routePage}`);
      return url;
    },
  },
};
</script>

<!-- <style scoped>
  .page-content {
    width: 100%;
    height: 100vh; /* Full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevent scrolling if iframe content overflows */
  }
  </style> -->