<template>
  <div class="modal fade" id="SidebarPartner" tabindex="-1" aria-labelledby="SidebarPartnerLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" @click="closemodal()"></button>
        </div>
        <div class="modal-body">
          <form class="my-2" method="">
            <div class="form-group my-4 mt-2 m-auto" v-for="Activelist in List" :key="Activelist">
              <div class="d-flex align-items-center justify-content-between px-2" :class="Activelist.client.clientId == this.user.clientId
                  ? 'login-activity'
                  : ''
                ">
                <div class="d-flex">
                  <div class="PartnerProfile-area">
                    <div class="PartnerProfile" style="background-color: #000" v-if="Activelist.client.logo">
                      <img :src="Activelist.client.logo" class="image-profile-photo" />
                    </div>
                    <div class="PartnerProfile" style="background-color: #000" v-else>
                      {{ Activelist.client.company?.charAt(0) }}
                    </div>
                  </div>
                  <div class="px-3" @click="Switch(Activelist)" style="cursor: pointer">
                    <h6>{{ Activelist.client.company }}</h6>
                    <small class="text-muted">Enterprise plan</small>
                  </div>
                </div>
                <i class="fa-solid fa-circle-check text-success" v-if="Activelist.primaryAccount"></i>
                <button :disabled="!Activelist.client.isActive" type="button" class="btn btn-primary"
                  @click="Setaccount(Activelist)" v-if="!Activelist.primaryAccount">
                  Set Primary
                </button>
              </div>
            </div>
            <!-- <div class="form-group">
                            <button class="btn btn-primary px-5 m-auto w-100 disabled">
                                Select
                            </button>
                        </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { SetClarityData } from "../platformFeatures/featureUtils";

export default {
  name: "UserAccountPopup",
  data() {
    return {
      List: [],
      show: false,
    };
  },
  computed: {
    ...mapState({
      authenticated: (state) => state.auth.authenticate,
      user: (state) => state.auth.user,
      userId: (state) => state.auth.user.userId,
    }),
  },
  async mounted() {
    SetClarityData();
    this.UserAccountList();
    window.$("#SidebarPartner").modal("show");
  },
  methods: {
    ...mapActions(["UserAccounts", "SwitchAccount", "SetPrimaryAccount", "companylogo"]),
    ...mapMutations(["setLoading"]),

    async UserAccountList() {
      var List = await this.UserAccounts();
      this.List = List.object.data.account;
      this.List.forEach(async (elm) => {
        if (elm.client.logo != null) {
          var resp = await this.companylogo({
            fileName: elm.client.logo,
          });
          elm.client.logo = resp.object.data;
        }
      });
    },

    closemodal() {
      this.$emit("clicked", false);
      window.$("#SidebarPartner").modal("hide");
    },

    async Switch(account) {
      await this.SwitchAccount({
        userId: this.user.userId,
        accountId: account.accountId,
        clientId: account.client.clientId,
      });
      this.$emit("getCompany", account.client);
      this.$emit("clicked", false);
      window.$("#SidebarPartner").modal("hide");
    },
    async Setaccount(account) {
      var set = await this.SetPrimaryAccount({
        UserId: account.accountId,
        ClientId: account.client.clientId,
      });
      if (set.status) {
        this.UserAccountList();
      }
      this.$emit("clicked", false);
      window.$("#SidebarPartner").modal("hide");
    },
  },
};
</script>
<style>
.login-activity {
  background: #9d93dc30;
  padding: 10px;
  border-radius: 5px;
}

.image-profile-photo {
  width: 40px;
  border-radius: 50%;
  height: 40px;
  margin-left: -4px;
  margin-top: -5px;
}
</style>
