<template>
    <div class="modal fade CreatePartnerModal_hp" id="Addmember" tabindex="-1" data-bs-backdrop="static"
        aria-labelledby="CreateGroupModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title text-centerm-auto" id="CreateGroupModalLabel">
                        Add Members in {{ this.gname }}
                    </h4>
                    <button type="button" id="x" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"
                        @click="ResetForm"></button>
                </div>
                <div class="modal-body">
                    <div class="row" id="CreateForm">
                        <form class="search-form">
                            <div class="form-group has-search close-icon-M-kj">
                                <span class="fa fa-search form-control-feedback"></span>
                                <input type="text" placeholder="Search" name="" v-model="search" @keyup="searchuser"
                                    @click="crossshow" class="form-control form-control-input-foues ">
                                <!-- <input type="text" class="form-control" placeholder="Search"> -->
                                <span class="close-icon-M-T-kj" v-if="this.cross" @click="crosssearch"><i
                                        class="fa-solid fa-xmark"></i></span>
                            </div>
                        </form>

                        <!-- <div class="search-bar">
                            <form class="search-form">
                                <input type="text" placeholder="Search" name="" v-model="search"
                                    @keyup="searchuser" class="form-control bg-white">
                                    <button class="btn"><i class="fas fa-search"></i></button>
                                </form>
                        </div> -->

                        <div class="list-new-add">
                            <div v-if="this.add.length > 0">
                                <div class="row align-items-center mb-2" v-for="item in add" :key="item">
                                    <div class="col-8">
                                        <div class="PartnerTeamMembersProfile-area-kj">
                                            <div class="PartnerTeamMembersProfile-kj name-logo-1">
                                                {{ item.name.charAt(0) }}
                                            </div>
                                        </div>
                                        <div>
                                            <span class="font-size-12-MV-kj">{{ item.name }}</span>
                                        </div>
                                        <div>
                                            <a href="mailto:samlockyisthebest122@gmail.com"
                                                mailto:class="text-dark"><span
                                                    class="font-size-10-MV-kj text-color-kj">{{ item.email }}</span>
                                            </a>
                                        </div>
                                    </div>

                                    <div class="col-4 p-0 text-end">
                                        <button type="button" class="btn-primary btn-Apply-kj mx-3"
                                            v-if="this.added == true && this.addedid == item.id"><i
                                                class="fa-solid fa-check"></i></button>
                                        <button type="button" class="btn-primary btn-Apply-kj mx-3"
                                            @click="adduser(item)" v-else>Add</button>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center" v-else>
                                <img src="../../public/dashboard-assets/images/M2/Search-No.png" alt="">
                                <h6 class="mt-2">No user found</h6>
                            </div>
                            <label class="Added-text-kj" for="">Added({{ this.remove.length }})</label>

                            <div class="row align-items-center mb-2" v-for="item in this.remove" :key="item">
                                <div class="col-8">
                                    <div class="PartnerTeamMembersProfile-area-kj">
                                        <div class="PartnerTeamMembersProfile-kj name-logo-2">
                                            {{ item.name.charAt(0) }}
                                        </div>
                                    </div>
                                    <!-- </div>
                            <div class="col-8"> -->
                                    <div>
                                        <span class="font-size-12-MV-kj">{{ item.name }}</span>
                                    </div>
                                    <div>
                                        <a href="mailto:easterd146@gmail.com" mailto:class="text-dark"><span
                                                class="font-size-10-MV-kj text-color-kj">{{ item.email }}</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-4 p-0 text-end">
                                    <a class="mx-2 text-color-kj" @click="removeaddeduser(item)"><i
                                            class="fa-solid fa-xmark"></i> Remove</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="modal-footer justify-content-start">

                <button type="button" class="btn-Apply-kj-color" @click="this.addnew=true"> <i class="fa-solid fa-circle-plus"></i> Add New
                    Users</button>
            </div> -->
            </div>
        </div>
    </div>
    <Addnewuser v-if="this.addnew == true" :gid="this.gid" :gname="this.gname" @clicked="showpop" />
</template>

<script>
import {
    mapState,
    mapActions,
    mapMutations
} from "vuex";
import Addnewuser from "./Addnewuser.vue";
import { SetClarityData } from "../platformFeatures/featureUtils";
export default {
    name: 'Addnewgroupmember',
    props: ["gid", "gname"],
    data() {
        return {
            userlist: [],
            addeduser: [],
            added: false,
            addedid: '',
            members: [],
            remove: [],
            add: [],
            search: '',
            cross: false,
            addnew: false
        }
    },
    components: {
        Addnewuser
    },
    async mounted() {

        SetClarityData();

        this.members = await this.Getregionmembers({
            regionId: this.gid
        })
        var userlist = await this.Getuserlist({
            clientId: this.$route.name === 'ManagementPartnersGroupDetail' ? window.atob(this.$route.params.id) : this.user.clientId,
            limit: 100
        })

        window.$("#Addmember").modal('show');
        this.userlist = userlist.object.data.users;
        var member = this.members.object.data;
        this.userlist.forEach(element => {
            if (member.find(x => x.userId == element.id) != null) {
                this.remove.push(element)
            } else {
                this.add.push(element)
            }
        });
    },
    computed: {
        ...mapState({
            authenticated: (state) => state.auth.authenticate,
            user: (state) => state.auth.user,
            clientId: (state) => state.auth.user.clientId,
        }),
    },
    methods: {
        ...mapActions([
            "Getuserlist",
            "adduseringroup",
            "removeuser",
            "Getregionmembers",
            "Getusersearchlist"
        ]),
        ...mapMutations(["setLoading"]),
        async adduser(data) {
            // this.add.splice(this.userlist.findIndex(x => x.id == data.id), 1);
            this.remove.push({
                displayName: data.displayName,
                id: data.id,
                name: data.name,
                email: data.email,
                profilePic: data.profilePic,
                roleId: data.roleId,
                status: data.status
            })
            this.added = true;
            this.addedid = data.id
            await this.adduseringroup({
                regionId: this.gid,
                userId: data.id,
            })
            setTimeout(this.add.splice(this.add.findIndex(x => x.id == data.id), 1), 1000)

        },
        async removeaddeduser(data) {

            this.add.push({
                displayName: data.displayName,
                id: data.id,
                name: data.name,
                email: data.email,
                profilePic: data.profilePic,
                roleId: data.roleId,
                status: data.status
            })
            this.added = false;
            this.addedid = data.id
            await this.removeuser({
                userId: data.id,
                regionId: this.gid
            })
            this.remove.splice(this.remove.findIndex(x => x.id == data.id), 1);
        },
        ResetForm() {
            this.$emit('clicked', 'false')

        },
        async crosssearch() {
            this.search = '';
            this.cross = false;
            this.add = [],
                this.remove = [],
                this.members = await this.Getregionmembers({
                    regionId: this.gid
                })
            var member = this.members.object.data;
            this.userlist.forEach(element => {
                if (member.find(x => x.userId == element.id) != null) {
                    this.remove.push(element)
                } else {
                    this.add.push(element)
                }
            });
        },
        crossshow() {
            this.cross = true
        },
        searchuser() {

            if (this.search != '') {
                this.add = this.add.filter((element) =>
                    element.name.toLowerCase().includes(this.search.toLowerCase()));
            } else {
                this.add = [],
                    this.remove = []
                var member = this.members.object.data;
                this.userlist.forEach(element => {
                    if (member.find(x => x.userId == element.id) != null) {
                        this.remove.push(element)
                    } else {
                        this.add.push(element)
                    }
                });
            }
        },
        showpop(val) {
            this.addnew = val;
            document.getElementById('x').click();
            // this.$emit('clicked', 'false')
        },
    },

}
</script>

<style>
.Added-text-kj {
    color: #7B818D;
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 10px;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 1.5rem;
    display: flex;
    height: 1.5rem;
    padding: 6px;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.close-icon-M-kj {
    position: relative;
}

.has-search .close-icon-M-T-kj {
    position: absolute;
    right: 25px;
    top: 7px;
    color: #666666;
}

.form-control-input-foues:focus {
    border-color: #7C68EE !important;
}
</style>
